import { createTRPCNuxtClient, httpBatchLink } from 'trpc-nuxt/client'
import superjson from 'superjson'
import { appRouter } from '@myfront/framework/routers'
import { defineNuxtPlugin } from '#imports'
import Resource from '../resource'

type AppRouter = typeof appRouter

export default defineNuxtPlugin(() => {
  /**
   * createTRPCNuxtClient adds a `useQuery` composable
   * built on top of `useAsyncData`.
   */
  const rpc = createTRPCNuxtClient<AppRouter>({
    transformer: superjson,
    links: [
      httpBatchLink({
        url: '/api/trpc'
      })
    ]
  })

  const rest = new Resource

  return {
    provide: {
      rpc,
      rest
    }
  }
})

export type client = ReturnType<typeof createTRPCNuxtClient<AppRouter>>;
