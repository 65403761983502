import { createTRPCNuxtClient, httpBatchLink } from 'trpc-nuxt/client'
import superjson from 'superjson'
import { appRouter } from '../prisma/generated/routers'
import Resource from '../models/resource'
import { defineNuxtPlugin } from '#imports'

type AppRouter = typeof appRouter

export default defineNuxtPlugin(() => {
  /**
   * createTRPCNuxtClient adds a `useQuery` composable
   * built on top of `useAsyncData`.
   */
  const client = createTRPCNuxtClient<AppRouter>({
    transformer: superjson,
    links: [
      httpBatchLink({
        url: '/api/trpc'
      })
    ]
  })

  const resource = new Resource()

  return {
    provide: {
      client,
      resource
    }
  }
})

export type client = ReturnType<typeof createTRPCNuxtClient<AppRouter>>;
