// Notice: Icon从这里找：https://heroicons.com/
import {
  HomeModernIcon,
  CircleStackIcon,
  TvIcon,
  FlagIcon,
  QueueListIcon,
  PresentationChartBarIcon,
  RocketLaunchIcon,
  RectangleStackIcon
} from '@heroicons/vue/24/outline'
import { defineAppConfig } from '#imports'

export type Nav = {
  name: string
  path: string
  meta: {
    title?: string
    icon?: string | typeof HomeModernIcon
    // 配置识别菜单激活的匹配正则
    match?: string[] | undefined
  },
  children?: Nav[]
}

export default defineAppConfig({
  myLayer: {
    name: 'schedule'
  },
  mainMenu: {
    'schedule': {
      expanded: ['schedule-project_id-overview', 'schedule-project_id-plan', 'schedule-project_id-tracking', 'schedule-project_id-analyse', 'schedule-project_id-optimize', 'bom-material-list', 'bim-project_id-graphic'],
      navigation: [
        {
          path: '/schedule/:project_id/overview', name: 'schedule-project_id-overview', meta: { title: '进度总览', icon: TvIcon },
        },
        {
          path: '/schedule/:project_id/plan', name: 'schedule-project_id-plan', meta: { title: '进度计划', icon: FlagIcon},
          children: [
            { path: '/schedule/:project_id/plan/main', name: 'schedule-project_id-plan-main', meta: { title: '主项计划' } },
            { path: '/schedule/:project_id/plan/sub', name: 'schedule-project_id-plan-sub', meta: { title: '子项计划' } },
          ],
        },
        {
          path: '/schedule/:project_id/tracking', name: 'schedule-project_id-tracking', meta: { title: '进度跟踪', icon: QueueListIcon },
          children: [
            { path: '/schedule/:project_id/tracking/duration', name: 'schedule-project_id-tracking-duration', meta: { title: '工期进度' } },
            { path: '/schedule/:project_id/tracking/figure', name: 'schedule-project_id-tracking-figure', meta: { title: '形象进度' } },
            { path: '/schedule/:project_id/tracking/entity', name: 'schedule-project_id-tracking-entity', meta: { title: '实体进度' } },
          ],
        },
        {
          path: '/schedule/:project_id/analyse', name: 'schedule-project_id-analyse', meta: { title: '进度分析', icon: PresentationChartBarIcon },
          children: [
            { path: '/schedule/:project_id/analyse/simulate', name: 'schedule-project_id-analyse-simulate', meta: { title: '模拟建造' } },
            { path: '/schedule/:project_id/analyse/critical', name: 'schedule-project_id-analyse-critical', meta: { title: '关键路径' } },
            { path: '/schedule/:project_id/analyse/deviation', name: 'schedule-project_id-analyse-deviation', meta: { title: '偏差分析' } },
          ],
        },
        {
          path: '/schedule/:project_id/optimize', name: 'schedule-project_id-optimize', meta: { title: '进度优化', icon: RocketLaunchIcon },
          children: [
            { path: '/schedule/:project_id/optimize/renew', name: 'schedule-project_id-optimize-renew', meta: { title: '计划更新' } },
            { path: '/schedule/:project_id/optimize/cost', name: 'schedule-project_id-optimize-cost', meta: { title: '成本优化' } },
          ],
        },
        {
          path: '/bom/material-list', name: 'bom-material-list', meta: { title: '物料清单', icon: RectangleStackIcon },
          children: [
            { path: '/bom/material-list/admin', name: '/bom/material-list/admin', meta: { title: '清单管理' } },
          ],
        },
        {
        path: '/bim/:project_id/graphic', name: 'bim-project_id-graphic', meta: { title: '数据归集', icon: CircleStackIcon },
        children: [
          { path: '/bim/:project_id/graphic/model', name: 'bim-project_id-graphic-model', meta: { title: '模型归集' } },
        ],
        },
      
      ]
    }
  }
})

declare module '@nuxt/schema' {
  interface AppConfigInput {
    myLayer?: {
      /** module name */
      name?: string
    },
    siteConfig?: {
      name: string,
      enName: string,
      defaultPortal: string
    },
    mainMenu?: {
      // key为模块标识名
      [key: string]: {
        // 默认展开的菜单项，其值为
        expanded?: string[],
        // 导航定义
        navigation?: Nav[]
      }
    },
  }
}
