import { default as docDUEhsBbQTeMeta } from "/root/workspace/webspace_cloud/modules/openapi/src/runtime/pages/doc.vue?macro=true";
import { default as index61aFT9r8KWMeta } from "/root/workspace/webspace_cloud/modules/render/pages/admin/index.vue?macro=true";
import { default as _91id_93p23gMOQz4jMeta } from "/root/workspace/webspace_cloud/modules/yp231519/pages/admin/todos/[id].vue?macro=true";
import { default as indexRHi4hua5bGMeta } from "/root/workspace/webspace_cloud/modules/yp231519/pages/admin/todos/index.vue?macro=true";
import { default as authiQzkzDd5hVMeta } from "/root/workspace/webspace_cloud/modules/organization/pages/apps/[id]/auth.vue?macro=true";
import { default as detailRJmTCTiMvRMeta } from "/root/workspace/webspace_cloud/modules/organization/pages/apps/[id]/detail.vue?macro=true";
import { default as _91id_93MwGVZKeBi6Meta } from "/root/workspace/webspace_cloud/modules/organization/pages/apps/[id].vue?macro=true";
import { default as indexu8QJlzEEerMeta } from "/root/workspace/webspace_cloud/modules/organization/pages/apps/index.vue?macro=true";
import { default as listvdHEVwa31iMeta } from "/root/workspace/webspace_cloud/modules/organization/pages/apps/list.vue?macro=true";
import { default as _91oid_939iCZFlrtC3Meta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/analyze/collision/[oid].vue?macro=true";
import { default as ViewerKvYLk3HytWMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/analyze/collision/components/Viewer.vue?macro=true";
import { default as ViewerZJhh9f7r5PLZMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/analyze/collision/components/ViewerZJ.vue?macro=true";
import { default as createBgkZzyPyXeMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/analyze/collision/create.vue?macro=true";
import { default as indexIJCC33nTF3Meta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/analyze/collision/index.vue?macro=true";
import { default as _91id_93VqvcyEiXpiMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/analyze/diff/[id].vue?macro=true";
import { default as BimDoubleVieweraOujmq6EYsMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/analyze/diff/components/BimDoubleViewer.vue?macro=true";
import { default as BimDoubleViewerZJmUVo6PBwhRMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/analyze/diff/components/BimDoubleViewerZJ.vue?macro=true";
import { default as indexYtwIhB7EWuMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/analyze/diff/index.vue?macro=true";
import { default as indexjQI0M0WZYoMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/analyze/graph/index.vue?macro=true";
import { default as indexbwzl8WCKOIMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/analyze/trace/index.vue?macro=true";
import { default as edit3VpLsQN75SMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/catalog/component/edit.vue?macro=true";
import { default as indexEjWBbf6DhcMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/catalog/component/index.vue?macro=true";
import { default as edit_46old7sVkIVGN55Meta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/catalog/model/edit.old.vue?macro=true";
import { default as editARPQfCSYLVMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/catalog/model/edit.vue?macro=true";
import { default as indexwYdfoQt90XMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/catalog/model/index.vue?macro=true";
import { default as _91id_93VxWe3AeMwlMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/checker/quality/[id].vue?macro=true";
import { default as CertainRuleCRfd3HbysxXdMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/checker/quality/compoents/CertainRuleCR.vue?macro=true";
import { default as DefaultLayoutpxB4z9NM87Meta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/checker/quality/compoents/DefaultLayout.vue?macro=true";
import { default as ModelCheckResultBrowserbHERFH1Ol3Meta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/checker/quality/compoents/ModelCheckResultBrowser.vue?macro=true";
import { default as Vieweryw6qtFkjv5Meta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/checker/quality/compoents/Viewer.vue?macro=true";
import { default as ViewerZJLfzrxtLbtMMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/checker/quality/compoents/ViewerZJ.vue?macro=true";
import { default as createBTsDgSAHAjMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/checker/quality/create.vue?macro=true";
import { default as indexfgWLvfQcpVMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/checker/quality/index.vue?macro=true";
import { default as listlP8LPFOuufMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/checker/quality/list.vue?macro=true";
import { default as indexHubGC5D3D7Meta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/checker/topology/index.vue?macro=true";
import { default as listpG3gcWK5r9Meta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/checker/topology/list.vue?macro=true";
import { default as georeferencing6iaFCmQdxQMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/collection/model/[id]/georeferencing.vue?macro=true";
import { default as indexBB69PyTXl4Meta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/collection/model/[id]/index.vue?macro=true";
import { default as listT7AhKSzqloMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/collection/model/list.vue?macro=true";
import { default as overviewXn5bLCkAtBMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/collection/model/overview.vue?macro=true";
import { default as trashtUd5oBdf8EMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/collection/model/trash.vue?macro=true";
import { default as model4GEkRM2jzyMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/collection/model.vue?macro=true";
import { default as indexAqkYaOxgeOMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/converter/coordinate/index.vue?macro=true";
import { default as result0B68qBgzuMMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/converter/format/gfc2ifc/[id]/result.vue?macro=true";
import { default as indexDrUEGxUlMlMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/converter/format/gfc2ifc/create/index.vue?macro=true";
import { default as indexouMG3FfYLzMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/converter/format/gfc2ifc/list/index.vue?macro=true";
import { default as index91tTXZaIjuMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/converter/format/index.vue?macro=true";
import { default as resultBR7a23ONzDMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/converter/format/p3d2ifc/[id]/result.vue?macro=true";
import { default as indexhhmvglDEFTMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/converter/format/p3d2ifc/create/index.vue?macro=true";
import { default as indexUAfKOgAZA9Meta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/converter/format/p3d2ifc/list/index.vue?macro=true";
import { default as resultRk3TiY1QSmMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/converter/format/rvt2ifc/[id]/result.vue?macro=true";
import { default as indexZonS1sXjirMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/converter/format/rvt2ifc/create/index.vue?macro=true";
import { default as indexqsIF6ZChBIMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/converter/format/rvt2ifc/list/index.vue?macro=true";
import { default as index4oANSjyg4QMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/explore/image/index.vue?macro=true";
import { default as _91id_93LYzBLc6M2nMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/explore/model/[id].vue?macro=true";
import { default as indexwGCzjTD6ffMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/explore/model/index.vue?macro=true";
import { default as _91id_93f2e2IHWLYVMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/graphic/component/[id].vue?macro=true";
import { default as listRdOjMvSbKPMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/graphic/component/list.vue?macro=true";
import { default as trashGAj3CAJ2AHMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/graphic/component/trash.vue?macro=true";
import { default as componentqPool9BIZCMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/graphic/component.vue?macro=true";
import { default as indexl56NWfNAqBMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/graphic/index.vue?macro=true";
import { default as _91id_93z68u4TbcyIMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/graphic/model/[id].vue?macro=true";
import { default as listVkFfT6qwUdMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/graphic/model/list.vue?macro=true";
import { default as trashP4yv6Y4kO6Meta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/graphic/model/trash.vue?macro=true";
import { default as modelr0eX40g6jVMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/graphic/model.vue?macro=true";
import { default as homexCB0kNYS9hMeta } from "/root/workspace/webspace_cloud/modules/yp231519/pages/bim/[project_id]/overview/home.vue?macro=true";
import { default as indexZ3yeBvSCGvMeta } from "/root/workspace/webspace_cloud/modules/yp231519/pages/bim/[project_id]/overview/index.vue?macro=true";
import { default as index8ADEeakmMoMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/process/extract/create/index.vue?macro=true";
import { default as indexE0bmvRjMBzMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/process/extract/index.vue?macro=true";
import { default as indexb8RdocWvJyMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/process/merge/create/index.vue?macro=true";
import { default as indexK6WCVBvcYyMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/process/merge/index.vue?macro=true";
import { default as _91id_93LXFtqubrxJMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/programme/device/[id].vue?macro=true";
import { default as SelectTree8oadqNDrjMMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/programme/device/components/SelectTree.vue?macro=true";
import { default as useCreateDesignPlanETJYSEPqRNMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/programme/device/composables/useCreateDesignPlan.ts?macro=true";
import { default as machineMeHHYKYuHyMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/programme/device/machine.vue?macro=true";
import { default as standardWjLmC6qIZqMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/programme/device/standard.vue?macro=true";
import { default as verticalmOfxgI1tBxMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/programme/device/vertical.vue?macro=true";
import { default as devicefKLQbAQaEzMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/programme/device.vue?macro=true";
import { default as pluginIA1a1mNab7Meta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/transmission/upload/plugin.vue?macro=true";
import { default as uploadASc7WgzYowMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/transmission/upload.vue?macro=true";
import { default as indexqRXUQ7uSruMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/index.vue?macro=true";
import { default as _91id_93CpH57yi7w8Meta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/todos/[id].vue?macro=true";
import { default as index8ZPzmOub8iMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/todos/index.vue?macro=true";
import { default as viewerGlsQGqw0QyMeta } from "/root/workspace/webspace_cloud/modules/bim/pages/bim/viewer.vue?macro=true";
import { default as _91content_93uFSbH65mI3Meta } from "/root/workspace/webspace_cloud/apps/platform/pages/blog/[content].vue?macro=true";
import { default as indexK9K9rt3cnKMeta } from "/root/workspace/webspace_cloud/apps/platform/pages/blog/index.vue?macro=true";
import { default as indexQPk7F1rkErMeta } from "/root/workspace/webspace_cloud/modules/bom/pages/bom/[project_id]/material-list/admin/index.vue?macro=true";
import { default as indexVxxyqgIhSwMeta } from "/root/workspace/webspace_cloud/modules/bom/pages/bom/[project_id]/material-list/index.vue?macro=true";
import { default as index6lkojDx9Q6Meta } from "/root/workspace/webspace_cloud/modules/bom/pages/bom/index.vue?macro=true";
import { default as _91key_93XZZ6ZaxHRaMeta } from "/root/workspace/webspace_cloud/modules/catalog/pages/catalog/admin/catalogs/[key].vue?macro=true";
import { default as createJQsVWeY2hxMeta } from "/root/workspace/webspace_cloud/modules/catalog/pages/catalog/admin/catalogs/create.vue?macro=true";
import { default as indexeLVbwPBDhIMeta } from "/root/workspace/webspace_cloud/modules/catalog/pages/catalog/admin/catalogs/index.vue?macro=true";
import { default as indexf25nLbGxt6Meta } from "/root/workspace/webspace_cloud/modules/catalog/pages/catalog/admin/index.vue?macro=true";
import { default as _91id_93CVhs9HZwWEMeta } from "/root/workspace/webspace_cloud/modules/catalog/pages/catalog/admin/todos/[id].vue?macro=true";
import { default as createcdqFCxAQzbMeta } from "/root/workspace/webspace_cloud/modules/catalog/pages/catalog/admin/todos/create.vue?macro=true";
import { default as indexds8CQup9P0Meta } from "/root/workspace/webspace_cloud/modules/catalog/pages/catalog/admin/todos/index.vue?macro=true";
import { default as editNHLx9vIzU3Meta } from "/root/workspace/webspace_cloud/modules/catalog/pages/catalog/catalog/edit.vue?macro=true";
import { default as listgtK3ziGlUGMeta } from "/root/workspace/webspace_cloud/modules/catalog/pages/catalog/catalog/list.vue?macro=true";
import { default as actionA4wxyldaemMeta } from "/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/approve/mine/[id]/action.vue?macro=true";
import { default as detailsuMZ6ghoS5Meta } from "/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/approve/mine/[id]/detail.vue?macro=true";
import { default as indexSoRrn2VJjzMeta } from "/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/approve/mine/index.vue?macro=true";
import { default as donesrVJu9QuGhMeta } from "/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/approve/mine/record/done.vue?macro=true";
import { default as receivexgUTTdeQlLMeta } from "/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/approve/mine/record/receive.vue?macro=true";
import { default as starthgLxX83u1cMeta } from "/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/approve/mine/record/start.vue?macro=true";
import { default as undomPy8oVqZc9Meta } from "/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/approve/mine/record/undo.vue?macro=true";
import { default as recordfEXFCjbFKRMeta } from "/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/approve/mine/record.vue?macro=true";
import { default as base4eu9iYQwuDMeta } from "/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/manage/approvals/[id]/base.vue?macro=true";
import { default as formxK0I2cbJdzMeta } from "/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/manage/approvals/[id]/form.vue?macro=true";
import { default as nodeypFtM3PySCMeta } from "/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/manage/approvals/[id]/node.vue?macro=true";
import { default as _91id_93h79NdrRkktMeta } from "/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/manage/approvals/[id].vue?macro=true";
import { default as indexQgBCj9PTRUMeta } from "/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/manage/approvals/index.vue?macro=true";
import { default as indexZCGzJlUJ7QMeta } from "/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/manage/index.vue?macro=true";
import { default as actionA1fZF6NLATMeta } from "/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/manage/list/[id]/action.vue?macro=true";
import { default as indexLzudRO74FhMeta } from "/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/manage/list/index.vue?macro=true";
import { default as index2KbOpD4iy8Meta } from "/root/workspace/webspace_cloud/apps/platform/pages/index.vue?macro=true";
import { default as index0q41QU3YzTMeta } from "/root/workspace/webspace_cloud/modules/lifecycle/pages/lifecycle/[project_id]/ifc/index.vue?macro=true";
import { default as modelsxiDzBig2dzMeta } from "/root/workspace/webspace_cloud/modules/lifecycle/pages/lifecycle/[project_id]/models.vue?macro=true";
import { default as indexPruO57wbY4Meta } from "/root/workspace/webspace_cloud/modules/lifecycle/pages/lifecycle/index.vue?macro=true";
import { default as login_46authjsbc5oPSFPo9Meta } from "/root/workspace/webspace_cloud/framework/pages/login.authjs.vue?macro=true";
import { default as logindWnaDfvTGzMeta } from "/root/workspace/webspace_cloud/framework/pages/login.vue?macro=true";
import { default as MainLayoutVsTrufoZrUMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/MainLayout.vue?macro=true";
import { default as indexpTD4mrcm40Meta } from "/root/workspace/webspace_cloud/modules/monitor/pages/monitor/[project_id]/map/index.vue?macro=true";
import { default as indexCsjgktm9GVMeta } from "/root/workspace/webspace_cloud/modules/monitor/pages/monitor/[project_id]/operate/index.vue?macro=true";
import { default as indexCJ9CUE110PMeta } from "/root/workspace/webspace_cloud/modules/monitor/pages/monitor/[project_id]/overview/index.vue?macro=true";
import { default as indexN8HwxLUjMrMeta } from "/root/workspace/webspace_cloud/modules/monitor/pages/monitor/[project_id]/players/index.vue?macro=true";
import { default as index0EFu5TGuGDMeta } from "/root/workspace/webspace_cloud/modules/monitor/pages/monitor/[project_id]/receivers/index.vue?macro=true";
import { default as indexdAaLTzJkDeMeta } from "/root/workspace/webspace_cloud/modules/om/pages/om/index.vue?macro=true";
import { default as indexLz7wTPSjnWMeta } from "/root/workspace/webspace_cloud/modules/om/pages/om/orgs/index.vue?macro=true";
import { default as indexkgnPTSe0EZMeta } from "/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/access/auth/index.vue?macro=true";
import { default as index9MyBbTR72oMeta } from "/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/access/role/index.vue?macro=true";
import { default as indexq9cZtj4UVoMeta } from "/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/culture/appearance/index.vue?macro=true";
import { default as indexskGw9NmvRmMeta } from "/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/culture/dict/index.vue?macro=true";
import { default as indexiXVCXSPdfPMeta } from "/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/culture/field/index.vue?macro=true";
import { default as apiautheide1ZmR8bMeta } from "/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/employee/[employee_oid]/apiauth.vue?macro=true";
import { default as basiczZtSiW5Hm7Meta } from "/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/employee/[employee_oid]/basic.vue?macro=true";
import { default as _91employee_oid_93otR2I2vwdJMeta } from "/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/employee/[employee_oid].vue?macro=true";
import { default as basicU3I6kBlUkAMeta } from "/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/info/basic.vue?macro=true";
import { default as mapRq3Hvicm4fMeta } from "/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/info/map.vue?macro=true";
import { default as infok4MzJgIYXzMeta } from "/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/info.vue?macro=true";
import { default as indexM30f2C98IvMeta } from "/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/structure/department/index.vue?macro=true";
import { default as indexuEC0gt69vrMeta } from "/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/structure/employee/index.vue?macro=true";
import { default as indexOqQcNsd8dUMeta } from "/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/structure/group/index.vue?macro=true";
import { default as indexuroDAr2n2QMeta } from "/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/structure/v1/department/index.vue?macro=true";
import { default as normaljcPTHbO9pYMeta } from "/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/structure/v1/employee/normal.vue?macro=true";
import { default as employeesOmpvO3JoeMeta } from "/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/structure/v1/employee.vue?macro=true";
import { default as _91oid_93TnRutLUSrKMeta } from "/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid].vue?macro=true";
import { default as indexu1ipsRV4FhMeta } from "/root/workspace/webspace_cloud/modules/organization/pages/organization/index.vue?macro=true";
import { default as index5q8XPPgZhbMeta } from "/root/workspace/webspace_cloud/modules/pm/pages/pm/admin/catalog/index.vue?macro=true";
import { default as orgdSz2MR2uXaMeta } from "/root/workspace/webspace_cloud/modules/pm/pages/pm/admin/collaboration/org.vue?macro=true";
import { default as users2Rb5Btr3nMeta } from "/root/workspace/webspace_cloud/modules/pm/pages/pm/admin/collaboration/user.vue?macro=true";
import { default as indexmapGsCcT3pMeta } from "/root/workspace/webspace_cloud/modules/pm/pages/pm/admin/index.vue?macro=true";
import { default as focusARM2873Uj9Meta } from "/root/workspace/webspace_cloud/modules/pm/pages/pm/admin/project/focus.vue?macro=true";
import { default as indexssMeTdXcitMeta } from "/root/workspace/webspace_cloud/modules/pm/pages/pm/admin/project/index.vue?macro=true";
import { default as indexcljwPe728UMeta } from "/root/workspace/webspace_cloud/modules/pm/pages/pm/admin/project/old/index.vue?macro=true";
import { default as staruPU5rcXvXHMeta } from "/root/workspace/webspace_cloud/modules/pm/pages/pm/admin/project/star.vue?macro=true";
import { default as groupB8r5ajXWT3Meta } from "/root/workspace/webspace_cloud/modules/pm/pages/pm/admin/setting/group.vue?macro=true";
import { default as user5yMfibi5cOMeta } from "/root/workspace/webspace_cloud/modules/pm/pages/pm/admin/setting/user.vue?macro=true";
import { default as indexhlUVq5Hsq4Meta } from "/root/workspace/webspace_cloud/modules/pm/pages/pm/admin/system/index.vue?macro=true";
import { default as indexi1LDcPEoIvMeta } from "/root/workspace/webspace_cloud/modules/pm/pages/pm/index.vue?macro=true";
import { default as client5vwKD2PUjPMeta } from "/root/workspace/webspace_cloud/modules/portal/pages/portal/doc/client.vue?macro=true";
import { default as serverJsgx5RMjBvMeta } from "/root/workspace/webspace_cloud/modules/portal/pages/portal/doc/server.vue?macro=true";
import { default as indexfQ9wUFGTWxMeta } from "/root/workspace/webspace_cloud/modules/portal/pages/portal/example/compose/[id]/index.vue?macro=true";
import { default as indexqYTqh3TVx5Meta } from "/root/workspace/webspace_cloud/modules/portal/pages/portal/example/compose/index.vue?macro=true";
import { default as indexrDtrsaDtoHMeta } from "/root/workspace/webspace_cloud/modules/portal/pages/portal/example/exampleBrowsing/[id]/index.vue?macro=true";
import { default as indexKxfRMSeSJsMeta } from "/root/workspace/webspace_cloud/modules/portal/pages/portal/example/exampleBrowsing/index.vue?macro=true";
import { default as groupwuVFU39obNMeta } from "/root/workspace/webspace_cloud/modules/portal/pages/portal/example/group.vue?macro=true";
import { default as clientOZHrAtdGVFMeta } from "/root/workspace/webspace_cloud/modules/portal/pages/portal/home/docs/client.vue?macro=true";
import { default as serverDNw50EAUEIMeta } from "/root/workspace/webspace_cloud/modules/portal/pages/portal/home/docs/server.vue?macro=true";
import { default as _91id_93R4dX0BMW2fMeta } from "/root/workspace/webspace_cloud/modules/portal/pages/portal/home/example/[id].vue?macro=true";
import { default as indexATETxnk6aQMeta } from "/root/workspace/webspace_cloud/modules/portal/pages/portal/home/example/index.vue?macro=true";
import { default as _91id_93LTS0qVxmVIMeta } from "/root/workspace/webspace_cloud/modules/portal/pages/portal/home/feature/[id].vue?macro=true";
import { default as indexdXyKZkjlluMeta } from "/root/workspace/webspace_cloud/modules/portal/pages/portal/home/feature/index.vue?macro=true";
import { default as indexX3CBDwT3O2Meta } from "/root/workspace/webspace_cloud/modules/portal/pages/portal/home/index.vue?macro=true";
import { default as browsey45crXNsX1Meta } from "/root/workspace/webspace_cloud/modules/portal/pages/portal/home/standard/browse.vue?macro=true";
import { default as searchMwXoJgzq6YMeta } from "/root/workspace/webspace_cloud/modules/portal/pages/portal/home/standard/search.vue?macro=true";
import { default as index2YzW9XJKJOMeta } from "/root/workspace/webspace_cloud/modules/portal/pages/portal/scene/arrange/[id]/index.vue?macro=true";
import { default as indexoyshPwvGxLMeta } from "/root/workspace/webspace_cloud/modules/portal/pages/portal/scene/arrange/index.vue?macro=true";
import { default as groupLivTyV3zMaMeta } from "/root/workspace/webspace_cloud/modules/portal/pages/portal/scene/group.vue?macro=true";
import { default as indexYfQWmyqr7KMeta } from "/root/workspace/webspace_cloud/modules/portal/pages/portal/scene/sceneBrowsing/[id]/index.vue?macro=true";
import { default as index92tmoYQNZHMeta } from "/root/workspace/webspace_cloud/modules/portal/pages/portal/scene/sceneBrowsing/index.vue?macro=true";
import { default as prismaaaz1YpIJMMMeta } from "/root/workspace/webspace_cloud/framework/pages/prisma.vue?macro=true";
import { default as indexbbAD2Th63uMeta } from "/root/workspace/webspace_cloud/modules/project-org/pages/project-org/access/member/index.vue?macro=true";
import { default as indexBTbMRe4ZaBMeta } from "/root/workspace/webspace_cloud/modules/project-org/pages/project-org/access/policy/index.vue?macro=true";
import { default as index9jIP0dtXXxMeta } from "/root/workspace/webspace_cloud/modules/project-org/pages/project-org/access/role/index.vue?macro=true";
import { default as indexMNd7nvC2SsMeta } from "/root/workspace/webspace_cloud/modules/project-org/pages/project-org/culture/appearance/index.vue?macro=true";
import { default as indexLwEMSEUKZUMeta } from "/root/workspace/webspace_cloud/modules/project-org/pages/project-org/culture/menu/index.vue?macro=true";
import { default as index3URQGueFa9Meta } from "/root/workspace/webspace_cloud/modules/project-org/pages/project-org/index.vue?macro=true";
import { default as indexV8y4YA2AzJMeta } from "/root/workspace/webspace_cloud/modules/project-org/pages/project-org/integration/connect/index.vue?macro=true";
import { default as indexv7Oq37knBsMeta } from "/root/workspace/webspace_cloud/modules/project-org/pages/project-org/integration/iot/index.vue?macro=true";
import { default as index0zbfoEVtjRMeta } from "/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/cehua/pbs/[id_name]/index.vue?macro=true";
import { default as catalog_45selectmmtvB58W3sMeta } from "/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/cehua/pbs/catalog-select.vue?macro=true";
import { default as catalog_45viewJIFaHx1qaYMeta } from "/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/cehua/pbs/catalog-view.vue?macro=true";
import { default as index_46oldykJZR45aXmMeta } from "/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/cehua/pbs/index.old.vue?macro=true";
import { default as indexPpu2Sdqc5SMeta } from "/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/cehua/pbs/index.vue?macro=true";
import { default as normalV87xa4XuowMeta } from "/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/cehua/unit/normal.vue?macro=true";
import { default as trashguMTbz7yOXMeta } from "/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/cehua/unit/trash.vue?macro=true";
import { default as unitNaLsrQ35tmMeta } from "/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/cehua/unit.vue?macro=true";
import { default as basiciyqYm7wmyZMeta } from "/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/config/basic.vue?macro=true";
import { default as indexxIj3cscKgeMeta } from "/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/index.vue?macro=true";
import { default as basic_45oldSbuCVs5syHMeta } from "/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/info/basic-old.vue?macro=true";
import { default as advanceSettingRPNdovUglEMeta } from "/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/info/basic/advanceSetting.vue?macro=true";
import { default as extendInfoDwkmQxYIdGMeta } from "/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/info/basic/extendInfo.vue?macro=true";
import { default as index6On73mkqBoMeta } from "/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/info/basic/index.vue?macro=true";
import { default as basic9Xj83s2XjyMeta } from "/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/info/basic.vue?macro=true";
import { default as coverF18UzB8mPgMeta } from "/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/info/cover.vue?macro=true";
import { default as mapM9eLOpWSh8Meta } from "/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/info/map.vue?macro=true";
import { default as protected0gTvo00IXxMeta } from "/root/workspace/webspace_cloud/framework/pages/protected.vue?macro=true";
import { default as _91id_935MDCFabwKbMeta } from "/root/workspace/webspace_cloud/modules/render/pages/render/[first_catalog]/[id].vue?macro=true";
import { default as _91id_93M4PMs6WHuxMeta } from "/root/workspace/webspace_cloud/modules/render/pages/render/[first_catalog]/[second_catalog]/[id].vue?macro=true";
import { default as indexkajiMaxYSnMeta } from "/root/workspace/webspace_cloud/modules/render/pages/render/[first_catalog]/[second_catalog]/index.vue?macro=true";
import { default as indexeEEhI9WwruMeta } from "/root/workspace/webspace_cloud/modules/render/pages/render/[first_catalog]/index.vue?macro=true";
import { default as index5oeIRuOmyPMeta } from "/root/workspace/webspace_cloud/modules/render/pages/render/doc/index.vue?macro=true";
import { default as editQRfY3CUtWaMeta } from "/root/workspace/webspace_cloud/modules/render/pages/render/example_old/[first_catalog]/[id]/edit.vue?macro=true";
import { default as index8CA1Gjd5qiMeta } from "/root/workspace/webspace_cloud/modules/render/pages/render/example_old/[first_catalog]/[id]/index.vue?macro=true";
import { default as index4Pm9xey8B3Meta } from "/root/workspace/webspace_cloud/modules/render/pages/render/example_old/[first_catalog]/index.vue?macro=true";
import { default as _91id_93sX2LTHXdHaMeta } from "/root/workspace/webspace_cloud/modules/render/pages/render/example/scene/[id].vue?macro=true";
import { default as indexMVuxh5YCthMeta } from "/root/workspace/webspace_cloud/modules/render/pages/render/example/scene/index.vue?macro=true";
import { default as _91id_93pf8bO4QjDmMeta } from "/root/workspace/webspace_cloud/modules/render/pages/render/gallery/[id].vue?macro=true";
import { default as indexvU70lKemf7Meta } from "/root/workspace/webspace_cloud/modules/render/pages/render/gallery/index.vue?macro=true";
import { default as indexhnQrgY0ZOmMeta } from "/root/workspace/webspace_cloud/modules/render/pages/render/scaffold/index.vue?macro=true";
import { default as _91id_93cs1OAyeXsIMeta } from "/root/workspace/webspace_cloud/modules/render/pages/render/scenedisplay/[id].vue?macro=true";
import { default as indexwXX5yGMH14Meta } from "/root/workspace/webspace_cloud/modules/render/pages/render/scenedisplay/index.vue?macro=true";
import { default as doc_46oldxuIBjIHVeGMeta } from "/root/workspace/webspace_cloud/modules/render/pages/render/sdk/doc.old.vue?macro=true";
import { default as docED10RE8S6DMeta } from "/root/workspace/webspace_cloud/modules/render/pages/render/sdk/doc.vue?macro=true";
import { default as doc_46oldgcZhNOMwq6Meta } from "/root/workspace/webspace_cloud/modules/render/pages/render/server_sdk/doc.old.vue?macro=true";
import { default as docyWfbX1wPGMMeta } from "/root/workspace/webspace_cloud/modules/render/pages/render/server_sdk/doc.vue?macro=true";
import { default as indexoITIr4KvZqMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/analyse/critical/index.vue?macro=true";
import { default as indexFgJCoa8zyyMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/analyse/deviation/[id]/begin-work/index.vue?macro=true";
import { default as indexCK1t9KqkL7Meta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/analyse/deviation/[id]/bim-model/index.vue?macro=true";
import { default as indexg8awhOOlZVMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/analyse/deviation/[id]/duration/index.vue?macro=true";
import { default as indexNie4xqRCbMMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/analyse/deviation/[id]/end-work/index.vue?macro=true";
import { default as _91id_930xDzg54g80Meta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/analyse/deviation/[id].vue?macro=true";
import { default as index2qV4AxcMpmMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/analyse/deviation/index.vue?macro=true";
import { default as indexLrGjw9fpSTMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/analyse/simulate/[id]/actual/index.vue?macro=true";
import { default as indexmEXNFA1zLbMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/analyse/simulate/[id]/plan/index.vue?macro=true";
import { default as _91id_93u0TAhClxDXMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/analyse/simulate/[id].vue?macro=true";
import { default as index2sTT9c7tC4Meta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/analyse/simulate/index.vue?macro=true";
import { default as indexOI43L3MNADMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/optimize/cost/index.vue?macro=true";
import { default as indexYcQdMu69SHMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/optimize/cost/tabs/analysis/index.vue?macro=true";
import { default as index0j2yKfMKcRMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/optimize/cost/tabs/deviation/index.vue?macro=true";
import { default as indexVmksAjY0jKMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/optimize/cost/tabs/information/index.vue?macro=true";
import { default as tabstSSpUwBrgoMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/optimize/cost/tabs.vue?macro=true";
import { default as indexk1SmT6J8jYMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/optimize/renew/[id]/now/index.vue?macro=true";
import { default as indexaNndtoS0YDMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/optimize/renew/[id]/plan/index.vue?macro=true";
import { default as useSvgLvhNLsafJBMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/optimize/renew/[id]/useSvg.ts?macro=true";
import { default as _91id_93ULYtRs7QYSMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/optimize/renew/[id].vue?macro=true";
import { default as indexezTzob9KELMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/optimize/renew/index.vue?macro=true";
import { default as indexLDwPy3LtRTMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/overview/index.vue?macro=true";
import { default as indexT57ZyX6cftMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/index.vue?macro=true";
import { default as indexWhQta38WcdMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/main/index.vue?macro=true";
import { default as index0h3EQt6qCbMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/major/index.vue?macro=true";
import { default as indexSdfG3NKZMQMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/major/tabs/gantt-chart/index.vue?macro=true";
import { default as indexKzoBb6BECUMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/major/tabs/network-diagram/index.vue?macro=true";
import { default as useSvgB0kwXkkDf9Meta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/major/tabs/network-diagram/useSvg.ts?macro=true";
import { default as index2DLrvysW2AMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/major/tabs/worksheet/index.vue?macro=true";
import { default as tabs7JhIYRzuzaMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/major/tabs.vue?macro=true";
import { default as indexomtcMHg1ZJMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/sub/[id]/bim-model/index.vue?macro=true";
import { default as indexbDJEyP9O0xMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/sub/[id]/gantt-chart/index.vue?macro=true";
import { default as indexsNjs6sABmDMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/sub/[id]/network-diagram/index.vue?macro=true";
import { default as useSvgAUqh1fCWW8Meta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/sub/[id]/network-diagram/useSvg.ts?macro=true";
import { default as key_45worksPhukVEBIfUMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/sub/[id]/overview/components/key-works.vue?macro=true";
import { default as indextwzg7yjAvFMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/sub/[id]/overview/index.vue?macro=true";
import { default as indexzttgK3giNcMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/sub/[id]/worksheet/index.vue?macro=true";
import { default as _91id_93h0MloiKsQuMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/sub/[id].vue?macro=true";
import { default as indexmJQ99hclFGMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/sub/index.vue?macro=true";
import { default as indexfwGAzhHzYiMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/tracking/duration/[id]/index.vue?macro=true";
import { default as indexfAUIIFNP3EMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/tracking/duration/index.vue?macro=true";
import { default as indexRUi4M8Z7N4Meta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/tracking/entity/[id]/index.vue?macro=true";
import { default as index5iWO92aSmyMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/tracking/entity/index.vue?macro=true";
import { default as indexF7l6FixP1aMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/tracking/figure/index.vue?macro=true";
import { default as indexeh40yxGtZeMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/index.vue?macro=true";
import { default as _91id_93g49J2XYhdhMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/todos/[id].vue?macro=true";
import { default as indexfgwniqZ8SxMeta } from "/root/workspace/webspace_cloud/modules/schedule/pages/schedule/todos/index.vue?macro=true";
import { default as entitycheckXqDtYEKISLMeta } from "/root/workspace/webspace_cloud/modules/standard/pages/standard/delivery/[id]/entitycheck.vue?macro=true";
import { default as geometrycheckXBGD6vH5JbMeta } from "/root/workspace/webspace_cloud/modules/standard/pages/standard/delivery/[id]/geometrycheck.vue?macro=true";
import { default as materialcheckQzHICoaweUMeta } from "/root/workspace/webspace_cloud/modules/standard/pages/standard/delivery/[id]/materialcheck.vue?macro=true";
import { default as propertycheckOLIEMh1pfYMeta } from "/root/workspace/webspace_cloud/modules/standard/pages/standard/delivery/[id]/propertycheck.vue?macro=true";
import { default as quantitycheck1CNZMMPirqMeta } from "/root/workspace/webspace_cloud/modules/standard/pages/standard/delivery/[id]/quantitycheck.vue?macro=true";
import { default as _91id_93uEd0t9bMtYMeta } from "/root/workspace/webspace_cloud/modules/standard/pages/standard/delivery/[id].vue?macro=true";
import { default as indexLiyHml1yyIMeta } from "/root/workspace/webspace_cloud/modules/standard/pages/standard/delivery/index.vue?macro=true";
import { default as _91id_93hLrLpaMKGtMeta } from "/root/workspace/webspace_cloud/modules/standard/pages/standard/dictionary/[id].vue?macro=true";
import { default as indexF5Fzu1lflgMeta } from "/root/workspace/webspace_cloud/modules/standard/pages/standard/dictionary/index.vue?macro=true";
import { default as entitynpndutOHNwMeta } from "/root/workspace/webspace_cloud/modules/standard/pages/standard/schema/[id]/entity.vue?macro=true";
import { default as enumpGUMI1aZoEMeta } from "/root/workspace/webspace_cloud/modules/standard/pages/standard/schema/[id]/enum.vue?macro=true";
import { default as pset9LIkgSTwIbMeta } from "/root/workspace/webspace_cloud/modules/standard/pages/standard/schema/[id]/pset.vue?macro=true";
import { default as qsetR0A0EFW1TeMeta } from "/root/workspace/webspace_cloud/modules/standard/pages/standard/schema/[id]/qset.vue?macro=true";
import { default as _91id_93mfZDUKlEz4Meta } from "/root/workspace/webspace_cloud/modules/standard/pages/standard/schema/[id].vue?macro=true";
import { default as indexUhpOMWqAmKMeta } from "/root/workspace/webspace_cloud/modules/standard/pages/standard/schema/index.vue?macro=true";
import { default as trpcYbj9JdqGAiMeta } from "/root/workspace/webspace_cloud/framework/pages/trpc.vue?macro=true";
import { default as index6o4HiqhdkMMeta } from "/root/workspace/webspace_cloud/modules/user-space/pages/user/info/index.vue?macro=true";
import { default as indexJFao6HtQQmMeta } from "/root/workspace/webspace_cloud/modules/user-space/pages/user/security/index.vue?macro=true";
import { default as indexTjaYmQMRaiMeta } from "/root/workspace/webspace_cloud/modules/yp231519/pages/yp231519/index.vue?macro=true";
export default [
  {
    name: docDUEhsBbQTeMeta?.name ?? "docs",
    path: docDUEhsBbQTeMeta?.path ?? "/docs",
    meta: { ...(docDUEhsBbQTeMeta || {}), ...{"apiDocUrl":"http://localhost:3001/api/docs","auth":false} },
    alias: docDUEhsBbQTeMeta?.alias || [],
    redirect: docDUEhsBbQTeMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/openapi/src/runtime/pages/doc.vue").then(m => m.default || m)
  },
  {
    name: docDUEhsBbQTeMeta?.name ?? "docs-release",
    path: docDUEhsBbQTeMeta?.path ?? "/docs/release",
    meta: { ...(docDUEhsBbQTeMeta || {}), ...{"apiDocUrl":"https://cdn.hb6oss.xstore.ctyun.cn/doc/openapi.json","auth":false} },
    alias: docDUEhsBbQTeMeta?.alias || [],
    redirect: docDUEhsBbQTeMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/openapi/src/runtime/pages/doc.vue").then(m => m.default || m)
  },
  {
    name: docDUEhsBbQTeMeta?.name ?? "docs",
    path: docDUEhsBbQTeMeta?.path ?? "/docs",
    meta: { ...(docDUEhsBbQTeMeta || {}), ...{"apiDocUrl":"/openapi/docs","auth":false} },
    alias: docDUEhsBbQTeMeta?.alias || [],
    redirect: docDUEhsBbQTeMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/openapi/src/runtime/pages/doc.vue").then(m => m.default || m)
  },
  {
    name: index61aFT9r8KWMeta?.name ?? "admin",
    path: index61aFT9r8KWMeta?.path ?? "/admin",
    meta: index61aFT9r8KWMeta || {},
    alias: index61aFT9r8KWMeta?.alias || [],
    redirect: index61aFT9r8KWMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/render/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93p23gMOQz4jMeta?.name ?? "admin-todos-id",
    path: _91id_93p23gMOQz4jMeta?.path ?? "/admin/todos/:id()",
    meta: _91id_93p23gMOQz4jMeta || {},
    alias: _91id_93p23gMOQz4jMeta?.alias || [],
    redirect: _91id_93p23gMOQz4jMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/yp231519/pages/admin/todos/[id].vue").then(m => m.default || m)
  },
  {
    name: indexRHi4hua5bGMeta?.name ?? "admin-todos",
    path: indexRHi4hua5bGMeta?.path ?? "/admin/todos",
    meta: indexRHi4hua5bGMeta || {},
    alias: indexRHi4hua5bGMeta?.alias || [],
    redirect: indexRHi4hua5bGMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/yp231519/pages/admin/todos/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93MwGVZKeBi6Meta?.name ?? "apps-id",
    path: _91id_93MwGVZKeBi6Meta?.path ?? "/apps/:id()",
    meta: _91id_93MwGVZKeBi6Meta || {},
    alias: _91id_93MwGVZKeBi6Meta?.alias || [],
    redirect: _91id_93MwGVZKeBi6Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/organization/pages/apps/[id].vue").then(m => m.default || m),
    children: [
  {
    name: authiQzkzDd5hVMeta?.name ?? "apps-id-auth",
    path: authiQzkzDd5hVMeta?.path ?? "auth",
    meta: authiQzkzDd5hVMeta || {},
    alias: authiQzkzDd5hVMeta?.alias || [],
    redirect: authiQzkzDd5hVMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/organization/pages/apps/[id]/auth.vue").then(m => m.default || m)
  },
  {
    name: detailRJmTCTiMvRMeta?.name ?? "apps-id-detail",
    path: detailRJmTCTiMvRMeta?.path ?? "detail",
    meta: detailRJmTCTiMvRMeta || {},
    alias: detailRJmTCTiMvRMeta?.alias || [],
    redirect: detailRJmTCTiMvRMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/organization/pages/apps/[id]/detail.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexu8QJlzEEerMeta?.name ?? "apps",
    path: indexu8QJlzEEerMeta?.path ?? "/apps",
    meta: indexu8QJlzEEerMeta || {},
    alias: indexu8QJlzEEerMeta?.alias || [],
    redirect: indexu8QJlzEEerMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/organization/pages/apps/index.vue").then(m => m.default || m)
  },
  {
    name: listvdHEVwa31iMeta?.name ?? "apps-list",
    path: listvdHEVwa31iMeta?.path ?? "/apps/list",
    meta: listvdHEVwa31iMeta || {},
    alias: listvdHEVwa31iMeta?.alias || [],
    redirect: listvdHEVwa31iMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/organization/pages/apps/list.vue").then(m => m.default || m)
  },
  {
    name: _91oid_939iCZFlrtC3Meta?.name ?? "bim-project_id-analyze-collision-oid",
    path: _91oid_939iCZFlrtC3Meta?.path ?? "/bim/:project_id()/analyze/collision/:oid()",
    meta: _91oid_939iCZFlrtC3Meta || {},
    alias: _91oid_939iCZFlrtC3Meta?.alias || [],
    redirect: _91oid_939iCZFlrtC3Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/analyze/collision/[oid].vue").then(m => m.default || m)
  },
  {
    name: ViewerKvYLk3HytWMeta?.name ?? "bim-project_id-analyze-collision-components-Viewer",
    path: ViewerKvYLk3HytWMeta?.path ?? "/bim/:project_id()/analyze/collision/components/Viewer",
    meta: ViewerKvYLk3HytWMeta || {},
    alias: ViewerKvYLk3HytWMeta?.alias || [],
    redirect: ViewerKvYLk3HytWMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/analyze/collision/components/Viewer.vue").then(m => m.default || m)
  },
  {
    name: ViewerZJhh9f7r5PLZMeta?.name ?? "bim-project_id-analyze-collision-components-ViewerZJ",
    path: ViewerZJhh9f7r5PLZMeta?.path ?? "/bim/:project_id()/analyze/collision/components/ViewerZJ",
    meta: ViewerZJhh9f7r5PLZMeta || {},
    alias: ViewerZJhh9f7r5PLZMeta?.alias || [],
    redirect: ViewerZJhh9f7r5PLZMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/analyze/collision/components/ViewerZJ.vue").then(m => m.default || m)
  },
  {
    name: createBgkZzyPyXeMeta?.name ?? "bim-project_id-analyze-collision-create",
    path: createBgkZzyPyXeMeta?.path ?? "/bim/:project_id()/analyze/collision/create",
    meta: createBgkZzyPyXeMeta || {},
    alias: createBgkZzyPyXeMeta?.alias || [],
    redirect: createBgkZzyPyXeMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/analyze/collision/create.vue").then(m => m.default || m)
  },
  {
    name: indexIJCC33nTF3Meta?.name ?? "bim-project_id-analyze-collision",
    path: indexIJCC33nTF3Meta?.path ?? "/bim/:project_id()/analyze/collision",
    meta: indexIJCC33nTF3Meta || {},
    alias: indexIJCC33nTF3Meta?.alias || [],
    redirect: indexIJCC33nTF3Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/analyze/collision/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93VqvcyEiXpiMeta?.name ?? "bim-project_id-analyze-diff-id",
    path: _91id_93VqvcyEiXpiMeta?.path ?? "/bim/:project_id()/analyze/diff/:id()",
    meta: _91id_93VqvcyEiXpiMeta || {},
    alias: _91id_93VqvcyEiXpiMeta?.alias || [],
    redirect: _91id_93VqvcyEiXpiMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/analyze/diff/[id].vue").then(m => m.default || m)
  },
  {
    name: BimDoubleVieweraOujmq6EYsMeta?.name ?? "bim-project_id-analyze-diff-components-BimDoubleViewer",
    path: BimDoubleVieweraOujmq6EYsMeta?.path ?? "/bim/:project_id()/analyze/diff/components/BimDoubleViewer",
    meta: BimDoubleVieweraOujmq6EYsMeta || {},
    alias: BimDoubleVieweraOujmq6EYsMeta?.alias || [],
    redirect: BimDoubleVieweraOujmq6EYsMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/analyze/diff/components/BimDoubleViewer.vue").then(m => m.default || m)
  },
  {
    name: BimDoubleViewerZJmUVo6PBwhRMeta?.name ?? "bim-project_id-analyze-diff-components-BimDoubleViewerZJ",
    path: BimDoubleViewerZJmUVo6PBwhRMeta?.path ?? "/bim/:project_id()/analyze/diff/components/BimDoubleViewerZJ",
    meta: BimDoubleViewerZJmUVo6PBwhRMeta || {},
    alias: BimDoubleViewerZJmUVo6PBwhRMeta?.alias || [],
    redirect: BimDoubleViewerZJmUVo6PBwhRMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/analyze/diff/components/BimDoubleViewerZJ.vue").then(m => m.default || m)
  },
  {
    name: indexYtwIhB7EWuMeta?.name ?? "bim-project_id-analyze-diff",
    path: indexYtwIhB7EWuMeta?.path ?? "/bim/:project_id()/analyze/diff",
    meta: indexYtwIhB7EWuMeta || {},
    alias: indexYtwIhB7EWuMeta?.alias || [],
    redirect: indexYtwIhB7EWuMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/analyze/diff/index.vue").then(m => m.default || m)
  },
  {
    name: indexjQI0M0WZYoMeta?.name ?? "bim-project_id-analyze-graph",
    path: indexjQI0M0WZYoMeta?.path ?? "/bim/:project_id()/analyze/graph",
    meta: indexjQI0M0WZYoMeta || {},
    alias: indexjQI0M0WZYoMeta?.alias || [],
    redirect: indexjQI0M0WZYoMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/analyze/graph/index.vue").then(m => m.default || m)
  },
  {
    name: indexbwzl8WCKOIMeta?.name ?? "bim-project_id-analyze-trace",
    path: indexbwzl8WCKOIMeta?.path ?? "/bim/:project_id()/analyze/trace",
    meta: indexbwzl8WCKOIMeta || {},
    alias: indexbwzl8WCKOIMeta?.alias || [],
    redirect: indexbwzl8WCKOIMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/analyze/trace/index.vue").then(m => m.default || m)
  },
  {
    name: edit3VpLsQN75SMeta?.name ?? "bim-project_id-catalog-component-edit",
    path: edit3VpLsQN75SMeta?.path ?? "/bim/:project_id()/catalog/component/edit",
    meta: edit3VpLsQN75SMeta || {},
    alias: edit3VpLsQN75SMeta?.alias || [],
    redirect: edit3VpLsQN75SMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/catalog/component/edit.vue").then(m => m.default || m)
  },
  {
    name: indexEjWBbf6DhcMeta?.name ?? "bim-project_id-catalog-component",
    path: indexEjWBbf6DhcMeta?.path ?? "/bim/:project_id()/catalog/component",
    meta: indexEjWBbf6DhcMeta || {},
    alias: indexEjWBbf6DhcMeta?.alias || [],
    redirect: indexEjWBbf6DhcMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/catalog/component/index.vue").then(m => m.default || m)
  },
  {
    name: edit_46old7sVkIVGN55Meta?.name ?? "bim-project_id-catalog-model-edit.old",
    path: edit_46old7sVkIVGN55Meta?.path ?? "/bim/:project_id()/catalog/model/edit.old",
    meta: edit_46old7sVkIVGN55Meta || {},
    alias: edit_46old7sVkIVGN55Meta?.alias || [],
    redirect: edit_46old7sVkIVGN55Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/catalog/model/edit.old.vue").then(m => m.default || m)
  },
  {
    name: editARPQfCSYLVMeta?.name ?? "bim-project_id-catalog-model-edit",
    path: editARPQfCSYLVMeta?.path ?? "/bim/:project_id()/catalog/model/edit",
    meta: editARPQfCSYLVMeta || {},
    alias: editARPQfCSYLVMeta?.alias || [],
    redirect: editARPQfCSYLVMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/catalog/model/edit.vue").then(m => m.default || m)
  },
  {
    name: indexwYdfoQt90XMeta?.name ?? "bim-project_id-catalog-model",
    path: indexwYdfoQt90XMeta?.path ?? "/bim/:project_id()/catalog/model",
    meta: indexwYdfoQt90XMeta || {},
    alias: indexwYdfoQt90XMeta?.alias || [],
    redirect: indexwYdfoQt90XMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/catalog/model/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93VxWe3AeMwlMeta?.name ?? "bim-project_id-checker-quality-id",
    path: _91id_93VxWe3AeMwlMeta?.path ?? "/bim/:project_id()/checker/quality/:id()",
    meta: _91id_93VxWe3AeMwlMeta || {},
    alias: _91id_93VxWe3AeMwlMeta?.alias || [],
    redirect: _91id_93VxWe3AeMwlMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/checker/quality/[id].vue").then(m => m.default || m)
  },
  {
    name: CertainRuleCRfd3HbysxXdMeta?.name ?? "bim-project_id-checker-quality-compoents-CertainRuleCR",
    path: CertainRuleCRfd3HbysxXdMeta?.path ?? "/bim/:project_id()/checker/quality/compoents/CertainRuleCR",
    meta: CertainRuleCRfd3HbysxXdMeta || {},
    alias: CertainRuleCRfd3HbysxXdMeta?.alias || [],
    redirect: CertainRuleCRfd3HbysxXdMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/checker/quality/compoents/CertainRuleCR.vue").then(m => m.default || m)
  },
  {
    name: DefaultLayoutpxB4z9NM87Meta?.name ?? "bim-project_id-checker-quality-compoents-DefaultLayout",
    path: DefaultLayoutpxB4z9NM87Meta?.path ?? "/bim/:project_id()/checker/quality/compoents/DefaultLayout",
    meta: DefaultLayoutpxB4z9NM87Meta || {},
    alias: DefaultLayoutpxB4z9NM87Meta?.alias || [],
    redirect: DefaultLayoutpxB4z9NM87Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/checker/quality/compoents/DefaultLayout.vue").then(m => m.default || m)
  },
  {
    name: ModelCheckResultBrowserbHERFH1Ol3Meta?.name ?? "bim-project_id-checker-quality-compoents-ModelCheckResultBrowser",
    path: ModelCheckResultBrowserbHERFH1Ol3Meta?.path ?? "/bim/:project_id()/checker/quality/compoents/ModelCheckResultBrowser",
    meta: ModelCheckResultBrowserbHERFH1Ol3Meta || {},
    alias: ModelCheckResultBrowserbHERFH1Ol3Meta?.alias || [],
    redirect: ModelCheckResultBrowserbHERFH1Ol3Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/checker/quality/compoents/ModelCheckResultBrowser.vue").then(m => m.default || m)
  },
  {
    name: Vieweryw6qtFkjv5Meta?.name ?? "bim-project_id-checker-quality-compoents-Viewer",
    path: Vieweryw6qtFkjv5Meta?.path ?? "/bim/:project_id()/checker/quality/compoents/Viewer",
    meta: Vieweryw6qtFkjv5Meta || {},
    alias: Vieweryw6qtFkjv5Meta?.alias || [],
    redirect: Vieweryw6qtFkjv5Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/checker/quality/compoents/Viewer.vue").then(m => m.default || m)
  },
  {
    name: ViewerZJLfzrxtLbtMMeta?.name ?? "bim-project_id-checker-quality-compoents-ViewerZJ",
    path: ViewerZJLfzrxtLbtMMeta?.path ?? "/bim/:project_id()/checker/quality/compoents/ViewerZJ",
    meta: ViewerZJLfzrxtLbtMMeta || {},
    alias: ViewerZJLfzrxtLbtMMeta?.alias || [],
    redirect: ViewerZJLfzrxtLbtMMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/checker/quality/compoents/ViewerZJ.vue").then(m => m.default || m)
  },
  {
    name: createBTsDgSAHAjMeta?.name ?? "bim-project_id-checker-quality-create",
    path: createBTsDgSAHAjMeta?.path ?? "/bim/:project_id()/checker/quality/create",
    meta: createBTsDgSAHAjMeta || {},
    alias: createBTsDgSAHAjMeta?.alias || [],
    redirect: createBTsDgSAHAjMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/checker/quality/create.vue").then(m => m.default || m)
  },
  {
    name: indexfgWLvfQcpVMeta?.name ?? "bim-project_id-checker-quality",
    path: indexfgWLvfQcpVMeta?.path ?? "/bim/:project_id()/checker/quality",
    meta: indexfgWLvfQcpVMeta || {},
    alias: indexfgWLvfQcpVMeta?.alias || [],
    redirect: indexfgWLvfQcpVMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/checker/quality/index.vue").then(m => m.default || m)
  },
  {
    name: listlP8LPFOuufMeta?.name ?? "bim-project_id-checker-quality-list",
    path: listlP8LPFOuufMeta?.path ?? "/bim/:project_id()/checker/quality/list",
    meta: listlP8LPFOuufMeta || {},
    alias: listlP8LPFOuufMeta?.alias || [],
    redirect: listlP8LPFOuufMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/checker/quality/list.vue").then(m => m.default || m)
  },
  {
    name: indexHubGC5D3D7Meta?.name ?? "bim-project_id-checker-topology",
    path: indexHubGC5D3D7Meta?.path ?? "/bim/:project_id()/checker/topology",
    meta: indexHubGC5D3D7Meta || {},
    alias: indexHubGC5D3D7Meta?.alias || [],
    redirect: indexHubGC5D3D7Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/checker/topology/index.vue").then(m => m.default || m)
  },
  {
    name: listpG3gcWK5r9Meta?.name ?? "bim-project_id-checker-topology-list",
    path: listpG3gcWK5r9Meta?.path ?? "/bim/:project_id()/checker/topology/list",
    meta: listpG3gcWK5r9Meta || {},
    alias: listpG3gcWK5r9Meta?.alias || [],
    redirect: listpG3gcWK5r9Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/checker/topology/list.vue").then(m => m.default || m)
  },
  {
    name: model4GEkRM2jzyMeta?.name ?? "bim-project_id-collection-model",
    path: model4GEkRM2jzyMeta?.path ?? "/bim/:project_id()/collection/model",
    meta: model4GEkRM2jzyMeta || {},
    alias: model4GEkRM2jzyMeta?.alias || [],
    redirect: model4GEkRM2jzyMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/collection/model.vue").then(m => m.default || m),
    children: [
  {
    name: georeferencing6iaFCmQdxQMeta?.name ?? "bim-project_id-collection-model-id-georeferencing",
    path: georeferencing6iaFCmQdxQMeta?.path ?? ":id()/georeferencing",
    meta: georeferencing6iaFCmQdxQMeta || {},
    alias: georeferencing6iaFCmQdxQMeta?.alias || [],
    redirect: georeferencing6iaFCmQdxQMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/collection/model/[id]/georeferencing.vue").then(m => m.default || m)
  },
  {
    name: indexBB69PyTXl4Meta?.name ?? "bim-project_id-collection-model-id",
    path: indexBB69PyTXl4Meta?.path ?? ":id()",
    meta: indexBB69PyTXl4Meta || {},
    alias: indexBB69PyTXl4Meta?.alias || [],
    redirect: indexBB69PyTXl4Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/collection/model/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: listT7AhKSzqloMeta?.name ?? "bim-project_id-collection-model-list",
    path: listT7AhKSzqloMeta?.path ?? "list",
    meta: listT7AhKSzqloMeta || {},
    alias: listT7AhKSzqloMeta?.alias || [],
    redirect: listT7AhKSzqloMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/collection/model/list.vue").then(m => m.default || m)
  },
  {
    name: overviewXn5bLCkAtBMeta?.name ?? "bim-project_id-collection-model-overview",
    path: overviewXn5bLCkAtBMeta?.path ?? "overview",
    meta: overviewXn5bLCkAtBMeta || {},
    alias: overviewXn5bLCkAtBMeta?.alias || [],
    redirect: overviewXn5bLCkAtBMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/collection/model/overview.vue").then(m => m.default || m)
  },
  {
    name: trashtUd5oBdf8EMeta?.name ?? "bim-project_id-collection-model-trash",
    path: trashtUd5oBdf8EMeta?.path ?? "trash",
    meta: trashtUd5oBdf8EMeta || {},
    alias: trashtUd5oBdf8EMeta?.alias || [],
    redirect: trashtUd5oBdf8EMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/collection/model/trash.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexAqkYaOxgeOMeta?.name ?? "bim-project_id-converter-coordinate",
    path: indexAqkYaOxgeOMeta?.path ?? "/bim/:project_id()/converter/coordinate",
    meta: indexAqkYaOxgeOMeta || {},
    alias: indexAqkYaOxgeOMeta?.alias || [],
    redirect: indexAqkYaOxgeOMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/converter/coordinate/index.vue").then(m => m.default || m)
  },
  {
    name: result0B68qBgzuMMeta?.name ?? "bim-project_id-converter-format-gfc2ifc-id-result",
    path: result0B68qBgzuMMeta?.path ?? "/bim/:project_id()/converter/format/gfc2ifc/:id()/result",
    meta: result0B68qBgzuMMeta || {},
    alias: result0B68qBgzuMMeta?.alias || [],
    redirect: result0B68qBgzuMMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/converter/format/gfc2ifc/[id]/result.vue").then(m => m.default || m)
  },
  {
    name: indexDrUEGxUlMlMeta?.name ?? "bim-project_id-converter-format-gfc2ifc-create",
    path: indexDrUEGxUlMlMeta?.path ?? "/bim/:project_id()/converter/format/gfc2ifc/create",
    meta: indexDrUEGxUlMlMeta || {},
    alias: indexDrUEGxUlMlMeta?.alias || [],
    redirect: indexDrUEGxUlMlMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/converter/format/gfc2ifc/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexouMG3FfYLzMeta?.name ?? "bim-project_id-converter-format-gfc2ifc-list",
    path: indexouMG3FfYLzMeta?.path ?? "/bim/:project_id()/converter/format/gfc2ifc/list",
    meta: indexouMG3FfYLzMeta || {},
    alias: indexouMG3FfYLzMeta?.alias || [],
    redirect: indexouMG3FfYLzMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/converter/format/gfc2ifc/list/index.vue").then(m => m.default || m)
  },
  {
    name: index91tTXZaIjuMeta?.name ?? "bim-project_id-converter-format",
    path: index91tTXZaIjuMeta?.path ?? "/bim/:project_id()/converter/format",
    meta: index91tTXZaIjuMeta || {},
    alias: index91tTXZaIjuMeta?.alias || [],
    redirect: index91tTXZaIjuMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/converter/format/index.vue").then(m => m.default || m)
  },
  {
    name: resultBR7a23ONzDMeta?.name ?? "bim-project_id-converter-format-p3d2ifc-id-result",
    path: resultBR7a23ONzDMeta?.path ?? "/bim/:project_id()/converter/format/p3d2ifc/:id()/result",
    meta: resultBR7a23ONzDMeta || {},
    alias: resultBR7a23ONzDMeta?.alias || [],
    redirect: resultBR7a23ONzDMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/converter/format/p3d2ifc/[id]/result.vue").then(m => m.default || m)
  },
  {
    name: indexhhmvglDEFTMeta?.name ?? "bim-project_id-converter-format-p3d2ifc-create",
    path: indexhhmvglDEFTMeta?.path ?? "/bim/:project_id()/converter/format/p3d2ifc/create",
    meta: indexhhmvglDEFTMeta || {},
    alias: indexhhmvglDEFTMeta?.alias || [],
    redirect: indexhhmvglDEFTMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/converter/format/p3d2ifc/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexUAfKOgAZA9Meta?.name ?? "bim-project_id-converter-format-p3d2ifc-list",
    path: indexUAfKOgAZA9Meta?.path ?? "/bim/:project_id()/converter/format/p3d2ifc/list",
    meta: indexUAfKOgAZA9Meta || {},
    alias: indexUAfKOgAZA9Meta?.alias || [],
    redirect: indexUAfKOgAZA9Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/converter/format/p3d2ifc/list/index.vue").then(m => m.default || m)
  },
  {
    name: resultRk3TiY1QSmMeta?.name ?? "bim-project_id-converter-format-rvt2ifc-id-result",
    path: resultRk3TiY1QSmMeta?.path ?? "/bim/:project_id()/converter/format/rvt2ifc/:id()/result",
    meta: resultRk3TiY1QSmMeta || {},
    alias: resultRk3TiY1QSmMeta?.alias || [],
    redirect: resultRk3TiY1QSmMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/converter/format/rvt2ifc/[id]/result.vue").then(m => m.default || m)
  },
  {
    name: indexZonS1sXjirMeta?.name ?? "bim-project_id-converter-format-rvt2ifc-create",
    path: indexZonS1sXjirMeta?.path ?? "/bim/:project_id()/converter/format/rvt2ifc/create",
    meta: indexZonS1sXjirMeta || {},
    alias: indexZonS1sXjirMeta?.alias || [],
    redirect: indexZonS1sXjirMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/converter/format/rvt2ifc/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexqsIF6ZChBIMeta?.name ?? "bim-project_id-converter-format-rvt2ifc-list",
    path: indexqsIF6ZChBIMeta?.path ?? "/bim/:project_id()/converter/format/rvt2ifc/list",
    meta: indexqsIF6ZChBIMeta || {},
    alias: indexqsIF6ZChBIMeta?.alias || [],
    redirect: indexqsIF6ZChBIMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/converter/format/rvt2ifc/list/index.vue").then(m => m.default || m)
  },
  {
    name: index4oANSjyg4QMeta?.name ?? "bim-project_id-explore-image",
    path: index4oANSjyg4QMeta?.path ?? "/bim/:project_id()/explore/image",
    meta: index4oANSjyg4QMeta || {},
    alias: index4oANSjyg4QMeta?.alias || [],
    redirect: index4oANSjyg4QMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/explore/image/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93LYzBLc6M2nMeta?.name ?? "bim-project_id-explore-model-id",
    path: _91id_93LYzBLc6M2nMeta?.path ?? "/bim/:project_id()/explore/model/:id()",
    meta: _91id_93LYzBLc6M2nMeta || {},
    alias: _91id_93LYzBLc6M2nMeta?.alias || [],
    redirect: _91id_93LYzBLc6M2nMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/explore/model/[id].vue").then(m => m.default || m)
  },
  {
    name: indexwGCzjTD6ffMeta?.name ?? "bim-project_id-explore-model",
    path: indexwGCzjTD6ffMeta?.path ?? "/bim/:project_id()/explore/model",
    meta: indexwGCzjTD6ffMeta || {},
    alias: indexwGCzjTD6ffMeta?.alias || [],
    redirect: indexwGCzjTD6ffMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/explore/model/index.vue").then(m => m.default || m)
  },
  {
    name: componentqPool9BIZCMeta?.name ?? "bim-project_id-graphic-component",
    path: componentqPool9BIZCMeta?.path ?? "/bim/:project_id()/graphic/component",
    meta: componentqPool9BIZCMeta || {},
    alias: componentqPool9BIZCMeta?.alias || [],
    redirect: componentqPool9BIZCMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/graphic/component.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93f2e2IHWLYVMeta?.name ?? "bim-project_id-graphic-component-id",
    path: _91id_93f2e2IHWLYVMeta?.path ?? ":id()",
    meta: _91id_93f2e2IHWLYVMeta || {},
    alias: _91id_93f2e2IHWLYVMeta?.alias || [],
    redirect: _91id_93f2e2IHWLYVMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/graphic/component/[id].vue").then(m => m.default || m)
  },
  {
    name: listRdOjMvSbKPMeta?.name ?? "bim-project_id-graphic-component-list",
    path: listRdOjMvSbKPMeta?.path ?? "list",
    meta: listRdOjMvSbKPMeta || {},
    alias: listRdOjMvSbKPMeta?.alias || [],
    redirect: listRdOjMvSbKPMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/graphic/component/list.vue").then(m => m.default || m)
  },
  {
    name: trashGAj3CAJ2AHMeta?.name ?? "bim-project_id-graphic-component-trash",
    path: trashGAj3CAJ2AHMeta?.path ?? "trash",
    meta: trashGAj3CAJ2AHMeta || {},
    alias: trashGAj3CAJ2AHMeta?.alias || [],
    redirect: trashGAj3CAJ2AHMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/graphic/component/trash.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexl56NWfNAqBMeta?.name ?? "bim-project_id-graphic",
    path: indexl56NWfNAqBMeta?.path ?? "/bim/:project_id()/graphic",
    meta: indexl56NWfNAqBMeta || {},
    alias: indexl56NWfNAqBMeta?.alias || [],
    redirect: indexl56NWfNAqBMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/graphic/index.vue").then(m => m.default || m)
  },
  {
    name: modelr0eX40g6jVMeta?.name ?? "bim-project_id-graphic-model",
    path: modelr0eX40g6jVMeta?.path ?? "/bim/:project_id()/graphic/model",
    meta: modelr0eX40g6jVMeta || {},
    alias: modelr0eX40g6jVMeta?.alias || [],
    redirect: modelr0eX40g6jVMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/graphic/model.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93z68u4TbcyIMeta?.name ?? "bim-project_id-graphic-model-id",
    path: _91id_93z68u4TbcyIMeta?.path ?? ":id()",
    meta: _91id_93z68u4TbcyIMeta || {},
    alias: _91id_93z68u4TbcyIMeta?.alias || [],
    redirect: _91id_93z68u4TbcyIMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/graphic/model/[id].vue").then(m => m.default || m)
  },
  {
    name: listVkFfT6qwUdMeta?.name ?? "bim-project_id-graphic-model-list",
    path: listVkFfT6qwUdMeta?.path ?? "list",
    meta: listVkFfT6qwUdMeta || {},
    alias: listVkFfT6qwUdMeta?.alias || [],
    redirect: listVkFfT6qwUdMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/graphic/model/list.vue").then(m => m.default || m)
  },
  {
    name: trashP4yv6Y4kO6Meta?.name ?? "bim-project_id-graphic-model-trash",
    path: trashP4yv6Y4kO6Meta?.path ?? "trash",
    meta: trashP4yv6Y4kO6Meta || {},
    alias: trashP4yv6Y4kO6Meta?.alias || [],
    redirect: trashP4yv6Y4kO6Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/graphic/model/trash.vue").then(m => m.default || m)
  }
]
  },
  {
    name: homexCB0kNYS9hMeta?.name ?? "bim-project_id-overview-home",
    path: homexCB0kNYS9hMeta?.path ?? "/bim/:project_id()/overview/home",
    meta: homexCB0kNYS9hMeta || {},
    alias: homexCB0kNYS9hMeta?.alias || [],
    redirect: homexCB0kNYS9hMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/yp231519/pages/bim/[project_id]/overview/home.vue").then(m => m.default || m)
  },
  {
    name: indexZ3yeBvSCGvMeta?.name ?? "bim-project_id-overview",
    path: indexZ3yeBvSCGvMeta?.path ?? "/bim/:project_id()/overview",
    meta: indexZ3yeBvSCGvMeta || {},
    alias: indexZ3yeBvSCGvMeta?.alias || [],
    redirect: indexZ3yeBvSCGvMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/yp231519/pages/bim/[project_id]/overview/index.vue").then(m => m.default || m)
  },
  {
    name: index8ADEeakmMoMeta?.name ?? "bim-project_id-process-extract-create",
    path: index8ADEeakmMoMeta?.path ?? "/bim/:project_id()/process/extract/create",
    meta: index8ADEeakmMoMeta || {},
    alias: index8ADEeakmMoMeta?.alias || [],
    redirect: index8ADEeakmMoMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/process/extract/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexE0bmvRjMBzMeta?.name ?? "bim-project_id-process-extract",
    path: indexE0bmvRjMBzMeta?.path ?? "/bim/:project_id()/process/extract",
    meta: indexE0bmvRjMBzMeta || {},
    alias: indexE0bmvRjMBzMeta?.alias || [],
    redirect: indexE0bmvRjMBzMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/process/extract/index.vue").then(m => m.default || m)
  },
  {
    name: indexb8RdocWvJyMeta?.name ?? "bim-project_id-process-merge-create",
    path: indexb8RdocWvJyMeta?.path ?? "/bim/:project_id()/process/merge/create",
    meta: indexb8RdocWvJyMeta || {},
    alias: indexb8RdocWvJyMeta?.alias || [],
    redirect: indexb8RdocWvJyMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/process/merge/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexK6WCVBvcYyMeta?.name ?? "bim-project_id-process-merge",
    path: indexK6WCVBvcYyMeta?.path ?? "/bim/:project_id()/process/merge",
    meta: indexK6WCVBvcYyMeta || {},
    alias: indexK6WCVBvcYyMeta?.alias || [],
    redirect: indexK6WCVBvcYyMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/process/merge/index.vue").then(m => m.default || m)
  },
  {
    name: devicefKLQbAQaEzMeta?.name ?? "bim-project_id-programme-device",
    path: devicefKLQbAQaEzMeta?.path ?? "/bim/:project_id()/programme/device",
    meta: devicefKLQbAQaEzMeta || {},
    alias: devicefKLQbAQaEzMeta?.alias || [],
    redirect: devicefKLQbAQaEzMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/programme/device.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93LXFtqubrxJMeta?.name ?? "bim-project_id-programme-device-id",
    path: _91id_93LXFtqubrxJMeta?.path ?? ":id()",
    meta: _91id_93LXFtqubrxJMeta || {},
    alias: _91id_93LXFtqubrxJMeta?.alias || [],
    redirect: _91id_93LXFtqubrxJMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/programme/device/[id].vue").then(m => m.default || m)
  },
  {
    name: SelectTree8oadqNDrjMMeta?.name ?? "bim-project_id-programme-device-components-SelectTree",
    path: SelectTree8oadqNDrjMMeta?.path ?? "components/SelectTree",
    meta: SelectTree8oadqNDrjMMeta || {},
    alias: SelectTree8oadqNDrjMMeta?.alias || [],
    redirect: SelectTree8oadqNDrjMMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/programme/device/components/SelectTree.vue").then(m => m.default || m)
  },
  {
    name: useCreateDesignPlanETJYSEPqRNMeta?.name ?? "bim-project_id-programme-device-composables-useCreateDesignPlan",
    path: useCreateDesignPlanETJYSEPqRNMeta?.path ?? "composables/useCreateDesignPlan",
    meta: useCreateDesignPlanETJYSEPqRNMeta || {},
    alias: useCreateDesignPlanETJYSEPqRNMeta?.alias || [],
    redirect: useCreateDesignPlanETJYSEPqRNMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/programme/device/composables/useCreateDesignPlan.ts").then(m => m.default || m)
  },
  {
    name: machineMeHHYKYuHyMeta?.name ?? "bim-project_id-programme-device-machine",
    path: machineMeHHYKYuHyMeta?.path ?? "machine",
    meta: machineMeHHYKYuHyMeta || {},
    alias: machineMeHHYKYuHyMeta?.alias || [],
    redirect: machineMeHHYKYuHyMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/programme/device/machine.vue").then(m => m.default || m)
  },
  {
    name: standardWjLmC6qIZqMeta?.name ?? "bim-project_id-programme-device-standard",
    path: standardWjLmC6qIZqMeta?.path ?? "standard",
    meta: standardWjLmC6qIZqMeta || {},
    alias: standardWjLmC6qIZqMeta?.alias || [],
    redirect: standardWjLmC6qIZqMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/programme/device/standard.vue").then(m => m.default || m)
  },
  {
    name: verticalmOfxgI1tBxMeta?.name ?? "bim-project_id-programme-device-vertical",
    path: verticalmOfxgI1tBxMeta?.path ?? "vertical",
    meta: verticalmOfxgI1tBxMeta || {},
    alias: verticalmOfxgI1tBxMeta?.alias || [],
    redirect: verticalmOfxgI1tBxMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/programme/device/vertical.vue").then(m => m.default || m)
  }
]
  },
  {
    name: uploadASc7WgzYowMeta?.name ?? "bim-project_id-transmission-upload",
    path: uploadASc7WgzYowMeta?.path ?? "/bim/:project_id()/transmission/upload",
    meta: uploadASc7WgzYowMeta || {},
    alias: uploadASc7WgzYowMeta?.alias || [],
    redirect: uploadASc7WgzYowMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/transmission/upload.vue").then(m => m.default || m),
    children: [
  {
    name: pluginIA1a1mNab7Meta?.name ?? "bim-project_id-transmission-upload-plugin",
    path: pluginIA1a1mNab7Meta?.path ?? "plugin",
    meta: pluginIA1a1mNab7Meta || {},
    alias: pluginIA1a1mNab7Meta?.alias || [],
    redirect: pluginIA1a1mNab7Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/[project_id]/transmission/upload/plugin.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexqRXUQ7uSruMeta?.name ?? "bim",
    path: indexqRXUQ7uSruMeta?.path ?? "/bim",
    meta: indexqRXUQ7uSruMeta || {},
    alias: indexqRXUQ7uSruMeta?.alias || [],
    redirect: indexqRXUQ7uSruMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93CpH57yi7w8Meta?.name ?? "bim-todos-id",
    path: _91id_93CpH57yi7w8Meta?.path ?? "/bim/todos/:id()",
    meta: _91id_93CpH57yi7w8Meta || {},
    alias: _91id_93CpH57yi7w8Meta?.alias || [],
    redirect: _91id_93CpH57yi7w8Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/todos/[id].vue").then(m => m.default || m)
  },
  {
    name: index8ZPzmOub8iMeta?.name ?? "bim-todos",
    path: index8ZPzmOub8iMeta?.path ?? "/bim/todos",
    meta: index8ZPzmOub8iMeta || {},
    alias: index8ZPzmOub8iMeta?.alias || [],
    redirect: index8ZPzmOub8iMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/todos/index.vue").then(m => m.default || m)
  },
  {
    name: viewerGlsQGqw0QyMeta?.name ?? "bim-viewer",
    path: viewerGlsQGqw0QyMeta?.path ?? "/bim/viewer",
    meta: viewerGlsQGqw0QyMeta || {},
    alias: viewerGlsQGqw0QyMeta?.alias || [],
    redirect: viewerGlsQGqw0QyMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bim/pages/bim/viewer.vue").then(m => m.default || m)
  },
  {
    name: _91content_93uFSbH65mI3Meta?.name ?? "blog-content",
    path: _91content_93uFSbH65mI3Meta?.path ?? "/blog/:content()",
    meta: _91content_93uFSbH65mI3Meta || {},
    alias: _91content_93uFSbH65mI3Meta?.alias || [],
    redirect: _91content_93uFSbH65mI3Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/apps/platform/pages/blog/[content].vue").then(m => m.default || m)
  },
  {
    name: indexK9K9rt3cnKMeta?.name ?? "blog",
    path: indexK9K9rt3cnKMeta?.path ?? "/blog",
    meta: indexK9K9rt3cnKMeta || {},
    alias: indexK9K9rt3cnKMeta?.alias || [],
    redirect: indexK9K9rt3cnKMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/apps/platform/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexQPk7F1rkErMeta?.name ?? "bom-project_id-material-list-admin",
    path: indexQPk7F1rkErMeta?.path ?? "/bom/:project_id()/material-list/admin",
    meta: indexQPk7F1rkErMeta || {},
    alias: indexQPk7F1rkErMeta?.alias || [],
    redirect: indexQPk7F1rkErMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bom/pages/bom/[project_id]/material-list/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxxyqgIhSwMeta?.name ?? "bom-project_id-material-list",
    path: indexVxxyqgIhSwMeta?.path ?? "/bom/:project_id()/material-list",
    meta: indexVxxyqgIhSwMeta || {},
    alias: indexVxxyqgIhSwMeta?.alias || [],
    redirect: indexVxxyqgIhSwMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bom/pages/bom/[project_id]/material-list/index.vue").then(m => m.default || m)
  },
  {
    name: index6lkojDx9Q6Meta?.name ?? "bom",
    path: index6lkojDx9Q6Meta?.path ?? "/bom",
    meta: index6lkojDx9Q6Meta || {},
    alias: index6lkojDx9Q6Meta?.alias || [],
    redirect: index6lkojDx9Q6Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/bom/pages/bom/index.vue").then(m => m.default || m)
  },
  {
    name: _91key_93XZZ6ZaxHRaMeta?.name ?? "catalog-admin-catalogs-key",
    path: _91key_93XZZ6ZaxHRaMeta?.path ?? "/catalog/admin/catalogs/:key()",
    meta: _91key_93XZZ6ZaxHRaMeta || {},
    alias: _91key_93XZZ6ZaxHRaMeta?.alias || [],
    redirect: _91key_93XZZ6ZaxHRaMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/catalog/pages/catalog/admin/catalogs/[key].vue").then(m => m.default || m)
  },
  {
    name: createJQsVWeY2hxMeta?.name ?? "catalog-admin-catalogs-create",
    path: createJQsVWeY2hxMeta?.path ?? "/catalog/admin/catalogs/create",
    meta: createJQsVWeY2hxMeta || {},
    alias: createJQsVWeY2hxMeta?.alias || [],
    redirect: createJQsVWeY2hxMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/catalog/pages/catalog/admin/catalogs/create.vue").then(m => m.default || m)
  },
  {
    name: indexeLVbwPBDhIMeta?.name ?? "catalog-admin-catalogs",
    path: indexeLVbwPBDhIMeta?.path ?? "/catalog/admin/catalogs",
    meta: indexeLVbwPBDhIMeta || {},
    alias: indexeLVbwPBDhIMeta?.alias || [],
    redirect: indexeLVbwPBDhIMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/catalog/pages/catalog/admin/catalogs/index.vue").then(m => m.default || m)
  },
  {
    name: indexf25nLbGxt6Meta?.name ?? "catalog-admin",
    path: indexf25nLbGxt6Meta?.path ?? "/catalog/admin",
    meta: indexf25nLbGxt6Meta || {},
    alias: indexf25nLbGxt6Meta?.alias || [],
    redirect: indexf25nLbGxt6Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/catalog/pages/catalog/admin/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93CVhs9HZwWEMeta?.name ?? "catalog-admin-todos-id",
    path: _91id_93CVhs9HZwWEMeta?.path ?? "/catalog/admin/todos/:id()",
    meta: _91id_93CVhs9HZwWEMeta || {},
    alias: _91id_93CVhs9HZwWEMeta?.alias || [],
    redirect: _91id_93CVhs9HZwWEMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/catalog/pages/catalog/admin/todos/[id].vue").then(m => m.default || m)
  },
  {
    name: createcdqFCxAQzbMeta?.name ?? "catalog-admin-todos-create",
    path: createcdqFCxAQzbMeta?.path ?? "/catalog/admin/todos/create",
    meta: createcdqFCxAQzbMeta || {},
    alias: createcdqFCxAQzbMeta?.alias || [],
    redirect: createcdqFCxAQzbMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/catalog/pages/catalog/admin/todos/create.vue").then(m => m.default || m)
  },
  {
    name: indexds8CQup9P0Meta?.name ?? "catalog-admin-todos",
    path: indexds8CQup9P0Meta?.path ?? "/catalog/admin/todos",
    meta: indexds8CQup9P0Meta || {},
    alias: indexds8CQup9P0Meta?.alias || [],
    redirect: indexds8CQup9P0Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/catalog/pages/catalog/admin/todos/index.vue").then(m => m.default || m)
  },
  {
    name: editNHLx9vIzU3Meta?.name ?? "catalog-catalog-edit",
    path: editNHLx9vIzU3Meta?.path ?? "/catalog/catalog/edit",
    meta: editNHLx9vIzU3Meta || {},
    alias: editNHLx9vIzU3Meta?.alias || [],
    redirect: editNHLx9vIzU3Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/catalog/pages/catalog/catalog/edit.vue").then(m => m.default || m)
  },
  {
    name: listgtK3ziGlUGMeta?.name ?? "catalog-catalog-list",
    path: listgtK3ziGlUGMeta?.path ?? "/catalog/catalog/list",
    meta: listgtK3ziGlUGMeta || {},
    alias: listgtK3ziGlUGMeta?.alias || [],
    redirect: listgtK3ziGlUGMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/catalog/pages/catalog/catalog/list.vue").then(m => m.default || m)
  },
  {
    name: actionA4wxyldaemMeta?.name ?? "flow-project_id-approve-mine-id-action",
    path: actionA4wxyldaemMeta?.path ?? "/flow/:project_id()/approve/mine/:id()/action",
    meta: actionA4wxyldaemMeta || {},
    alias: actionA4wxyldaemMeta?.alias || [],
    redirect: actionA4wxyldaemMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/approve/mine/[id]/action.vue").then(m => m.default || m)
  },
  {
    name: detailsuMZ6ghoS5Meta?.name ?? "flow-project_id-approve-mine-id-detail",
    path: detailsuMZ6ghoS5Meta?.path ?? "/flow/:project_id()/approve/mine/:id()/detail",
    meta: detailsuMZ6ghoS5Meta || {},
    alias: detailsuMZ6ghoS5Meta?.alias || [],
    redirect: detailsuMZ6ghoS5Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/approve/mine/[id]/detail.vue").then(m => m.default || m)
  },
  {
    name: indexSoRrn2VJjzMeta?.name ?? "flow-project_id-approve-mine",
    path: indexSoRrn2VJjzMeta?.path ?? "/flow/:project_id()/approve/mine",
    meta: indexSoRrn2VJjzMeta || {},
    alias: indexSoRrn2VJjzMeta?.alias || [],
    redirect: indexSoRrn2VJjzMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/approve/mine/index.vue").then(m => m.default || m)
  },
  {
    name: recordfEXFCjbFKRMeta?.name ?? "flow-project_id-approve-mine-record",
    path: recordfEXFCjbFKRMeta?.path ?? "/flow/:project_id()/approve/mine/record",
    meta: recordfEXFCjbFKRMeta || {},
    alias: recordfEXFCjbFKRMeta?.alias || [],
    redirect: recordfEXFCjbFKRMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/approve/mine/record.vue").then(m => m.default || m),
    children: [
  {
    name: donesrVJu9QuGhMeta?.name ?? "flow-project_id-approve-mine-record-done",
    path: donesrVJu9QuGhMeta?.path ?? "done",
    meta: donesrVJu9QuGhMeta || {},
    alias: donesrVJu9QuGhMeta?.alias || [],
    redirect: donesrVJu9QuGhMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/approve/mine/record/done.vue").then(m => m.default || m)
  },
  {
    name: receivexgUTTdeQlLMeta?.name ?? "flow-project_id-approve-mine-record-receive",
    path: receivexgUTTdeQlLMeta?.path ?? "receive",
    meta: receivexgUTTdeQlLMeta || {},
    alias: receivexgUTTdeQlLMeta?.alias || [],
    redirect: receivexgUTTdeQlLMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/approve/mine/record/receive.vue").then(m => m.default || m)
  },
  {
    name: starthgLxX83u1cMeta?.name ?? "flow-project_id-approve-mine-record-start",
    path: starthgLxX83u1cMeta?.path ?? "start",
    meta: starthgLxX83u1cMeta || {},
    alias: starthgLxX83u1cMeta?.alias || [],
    redirect: starthgLxX83u1cMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/approve/mine/record/start.vue").then(m => m.default || m)
  },
  {
    name: undomPy8oVqZc9Meta?.name ?? "flow-project_id-approve-mine-record-undo",
    path: undomPy8oVqZc9Meta?.path ?? "undo",
    meta: undomPy8oVqZc9Meta || {},
    alias: undomPy8oVqZc9Meta?.alias || [],
    redirect: undomPy8oVqZc9Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/approve/mine/record/undo.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93h79NdrRkktMeta?.name ?? "flow-project_id-manage-approvals-id",
    path: _91id_93h79NdrRkktMeta?.path ?? "/flow/:project_id()/manage/approvals/:id()",
    meta: _91id_93h79NdrRkktMeta || {},
    alias: _91id_93h79NdrRkktMeta?.alias || [],
    redirect: _91id_93h79NdrRkktMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/manage/approvals/[id].vue").then(m => m.default || m),
    children: [
  {
    name: base4eu9iYQwuDMeta?.name ?? "flow-project_id-manage-approvals-id-base",
    path: base4eu9iYQwuDMeta?.path ?? "base",
    meta: base4eu9iYQwuDMeta || {},
    alias: base4eu9iYQwuDMeta?.alias || [],
    redirect: base4eu9iYQwuDMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/manage/approvals/[id]/base.vue").then(m => m.default || m)
  },
  {
    name: formxK0I2cbJdzMeta?.name ?? "flow-project_id-manage-approvals-id-form",
    path: formxK0I2cbJdzMeta?.path ?? "form",
    meta: formxK0I2cbJdzMeta || {},
    alias: formxK0I2cbJdzMeta?.alias || [],
    redirect: formxK0I2cbJdzMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/manage/approvals/[id]/form.vue").then(m => m.default || m)
  },
  {
    name: nodeypFtM3PySCMeta?.name ?? "flow-project_id-manage-approvals-id-node",
    path: nodeypFtM3PySCMeta?.path ?? "node",
    meta: nodeypFtM3PySCMeta || {},
    alias: nodeypFtM3PySCMeta?.alias || [],
    redirect: nodeypFtM3PySCMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/manage/approvals/[id]/node.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexQgBCj9PTRUMeta?.name ?? "flow-project_id-manage-approvals",
    path: indexQgBCj9PTRUMeta?.path ?? "/flow/:project_id()/manage/approvals",
    meta: indexQgBCj9PTRUMeta || {},
    alias: indexQgBCj9PTRUMeta?.alias || [],
    redirect: indexQgBCj9PTRUMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/manage/approvals/index.vue").then(m => m.default || m)
  },
  {
    name: indexZCGzJlUJ7QMeta?.name ?? "flow-project_id-manage",
    path: indexZCGzJlUJ7QMeta?.path ?? "/flow/:project_id()/manage",
    meta: indexZCGzJlUJ7QMeta || {},
    alias: indexZCGzJlUJ7QMeta?.alias || [],
    redirect: indexZCGzJlUJ7QMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/manage/index.vue").then(m => m.default || m)
  },
  {
    name: actionA1fZF6NLATMeta?.name ?? "flow-project_id-manage-list-id-action",
    path: actionA1fZF6NLATMeta?.path ?? "/flow/:project_id()/manage/list/:id()/action",
    meta: actionA1fZF6NLATMeta || {},
    alias: actionA1fZF6NLATMeta?.alias || [],
    redirect: actionA1fZF6NLATMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/manage/list/[id]/action.vue").then(m => m.default || m)
  },
  {
    name: indexLzudRO74FhMeta?.name ?? "flow-project_id-manage-list",
    path: indexLzudRO74FhMeta?.path ?? "/flow/:project_id()/manage/list",
    meta: indexLzudRO74FhMeta || {},
    alias: indexLzudRO74FhMeta?.alias || [],
    redirect: indexLzudRO74FhMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/flow/pages/flow/[project_id]/manage/list/index.vue").then(m => m.default || m)
  },
  {
    name: index2KbOpD4iy8Meta?.name ?? "index",
    path: index2KbOpD4iy8Meta?.path ?? "/",
    meta: index2KbOpD4iy8Meta || {},
    alias: index2KbOpD4iy8Meta?.alias || [],
    redirect: index2KbOpD4iy8Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/apps/platform/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index0q41QU3YzTMeta?.name ?? "lifecycle-project_id-ifc",
    path: index0q41QU3YzTMeta?.path ?? "/lifecycle/:project_id()/ifc",
    meta: index0q41QU3YzTMeta || {},
    alias: index0q41QU3YzTMeta?.alias || [],
    redirect: index0q41QU3YzTMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/lifecycle/pages/lifecycle/[project_id]/ifc/index.vue").then(m => m.default || m)
  },
  {
    name: modelsxiDzBig2dzMeta?.name ?? "lifecycle-project_id-models",
    path: modelsxiDzBig2dzMeta?.path ?? "/lifecycle/:project_id()/models",
    meta: modelsxiDzBig2dzMeta || {},
    alias: modelsxiDzBig2dzMeta?.alias || [],
    redirect: modelsxiDzBig2dzMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/lifecycle/pages/lifecycle/[project_id]/models.vue").then(m => m.default || m)
  },
  {
    name: indexPruO57wbY4Meta?.name ?? "lifecycle",
    path: indexPruO57wbY4Meta?.path ?? "/lifecycle",
    meta: indexPruO57wbY4Meta || {},
    alias: indexPruO57wbY4Meta?.alias || [],
    redirect: indexPruO57wbY4Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/lifecycle/pages/lifecycle/index.vue").then(m => m.default || m)
  },
  {
    name: login_46authjsbc5oPSFPo9Meta?.name ?? "login.authjs",
    path: login_46authjsbc5oPSFPo9Meta?.path ?? "/login.authjs",
    meta: login_46authjsbc5oPSFPo9Meta || {},
    alias: login_46authjsbc5oPSFPo9Meta?.alias || [],
    redirect: login_46authjsbc5oPSFPo9Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/framework/pages/login.authjs.vue").then(m => m.default || m)
  },
  {
    name: logindWnaDfvTGzMeta?.name ?? "login",
    path: logindWnaDfvTGzMeta?.path ?? "/login",
    meta: logindWnaDfvTGzMeta || {},
    alias: logindWnaDfvTGzMeta?.alias || [],
    redirect: logindWnaDfvTGzMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/framework/pages/login.vue").then(m => m.default || m)
  },
  {
    name: MainLayoutVsTrufoZrUMeta?.name ?? "MainLayout",
    path: MainLayoutVsTrufoZrUMeta?.path ?? "/MainLayout",
    meta: MainLayoutVsTrufoZrUMeta || {},
    alias: MainLayoutVsTrufoZrUMeta?.alias || [],
    redirect: MainLayoutVsTrufoZrUMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/MainLayout.vue").then(m => m.default || m)
  },
  {
    name: indexpTD4mrcm40Meta?.name ?? "monitor-project_id-map",
    path: indexpTD4mrcm40Meta?.path ?? "/monitor/:project_id()/map",
    meta: indexpTD4mrcm40Meta || {},
    alias: indexpTD4mrcm40Meta?.alias || [],
    redirect: indexpTD4mrcm40Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/monitor/pages/monitor/[project_id]/map/index.vue").then(m => m.default || m)
  },
  {
    name: indexCsjgktm9GVMeta?.name ?? "monitor-project_id-operate",
    path: indexCsjgktm9GVMeta?.path ?? "/monitor/:project_id()/operate",
    meta: indexCsjgktm9GVMeta || {},
    alias: indexCsjgktm9GVMeta?.alias || [],
    redirect: indexCsjgktm9GVMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/monitor/pages/monitor/[project_id]/operate/index.vue").then(m => m.default || m)
  },
  {
    name: indexCJ9CUE110PMeta?.name ?? "monitor-project_id-overview",
    path: indexCJ9CUE110PMeta?.path ?? "/monitor/:project_id()/overview",
    meta: indexCJ9CUE110PMeta || {},
    alias: indexCJ9CUE110PMeta?.alias || [],
    redirect: indexCJ9CUE110PMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/monitor/pages/monitor/[project_id]/overview/index.vue").then(m => m.default || m)
  },
  {
    name: indexN8HwxLUjMrMeta?.name ?? "monitor-project_id-players",
    path: indexN8HwxLUjMrMeta?.path ?? "/monitor/:project_id()/players",
    meta: indexN8HwxLUjMrMeta || {},
    alias: indexN8HwxLUjMrMeta?.alias || [],
    redirect: indexN8HwxLUjMrMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/monitor/pages/monitor/[project_id]/players/index.vue").then(m => m.default || m)
  },
  {
    name: index0EFu5TGuGDMeta?.name ?? "monitor-project_id-receivers",
    path: index0EFu5TGuGDMeta?.path ?? "/monitor/:project_id()/receivers",
    meta: index0EFu5TGuGDMeta || {},
    alias: index0EFu5TGuGDMeta?.alias || [],
    redirect: index0EFu5TGuGDMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/monitor/pages/monitor/[project_id]/receivers/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAaLTzJkDeMeta?.name ?? "om",
    path: indexdAaLTzJkDeMeta?.path ?? "/om",
    meta: indexdAaLTzJkDeMeta || {},
    alias: indexdAaLTzJkDeMeta?.alias || [],
    redirect: indexdAaLTzJkDeMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/om/pages/om/index.vue").then(m => m.default || m)
  },
  {
    name: indexLz7wTPSjnWMeta?.name ?? "om-orgs",
    path: indexLz7wTPSjnWMeta?.path ?? "/om/orgs",
    meta: indexLz7wTPSjnWMeta || {},
    alias: indexLz7wTPSjnWMeta?.alias || [],
    redirect: indexLz7wTPSjnWMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/om/pages/om/orgs/index.vue").then(m => m.default || m)
  },
  {
    name: _91oid_93TnRutLUSrKMeta?.name ?? "organization-oid",
    path: _91oid_93TnRutLUSrKMeta?.path ?? "/organization/:oid()",
    meta: _91oid_93TnRutLUSrKMeta || {},
    alias: _91oid_93TnRutLUSrKMeta?.alias || [],
    redirect: _91oid_93TnRutLUSrKMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid].vue").then(m => m.default || m),
    children: [
  {
    name: indexkgnPTSe0EZMeta?.name ?? "organization-oid-access-auth",
    path: indexkgnPTSe0EZMeta?.path ?? "access/auth",
    meta: indexkgnPTSe0EZMeta || {},
    alias: indexkgnPTSe0EZMeta?.alias || [],
    redirect: indexkgnPTSe0EZMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/access/auth/index.vue").then(m => m.default || m)
  },
  {
    name: index9MyBbTR72oMeta?.name ?? "organization-oid-access-role",
    path: index9MyBbTR72oMeta?.path ?? "access/role",
    meta: index9MyBbTR72oMeta || {},
    alias: index9MyBbTR72oMeta?.alias || [],
    redirect: index9MyBbTR72oMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/access/role/index.vue").then(m => m.default || m)
  },
  {
    name: indexq9cZtj4UVoMeta?.name ?? "organization-oid-culture-appearance",
    path: indexq9cZtj4UVoMeta?.path ?? "culture/appearance",
    meta: indexq9cZtj4UVoMeta || {},
    alias: indexq9cZtj4UVoMeta?.alias || [],
    redirect: indexq9cZtj4UVoMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/culture/appearance/index.vue").then(m => m.default || m)
  },
  {
    name: indexskGw9NmvRmMeta?.name ?? "organization-oid-culture-dict",
    path: indexskGw9NmvRmMeta?.path ?? "culture/dict",
    meta: indexskGw9NmvRmMeta || {},
    alias: indexskGw9NmvRmMeta?.alias || [],
    redirect: indexskGw9NmvRmMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/culture/dict/index.vue").then(m => m.default || m)
  },
  {
    name: indexiXVCXSPdfPMeta?.name ?? "organization-oid-culture-field",
    path: indexiXVCXSPdfPMeta?.path ?? "culture/field",
    meta: indexiXVCXSPdfPMeta || {},
    alias: indexiXVCXSPdfPMeta?.alias || [],
    redirect: indexiXVCXSPdfPMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/culture/field/index.vue").then(m => m.default || m)
  },
  {
    name: _91employee_oid_93otR2I2vwdJMeta?.name ?? "organization-oid-employee-employee_oid",
    path: _91employee_oid_93otR2I2vwdJMeta?.path ?? "employee/:employee_oid()",
    meta: _91employee_oid_93otR2I2vwdJMeta || {},
    alias: _91employee_oid_93otR2I2vwdJMeta?.alias || [],
    redirect: _91employee_oid_93otR2I2vwdJMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/employee/[employee_oid].vue").then(m => m.default || m),
    children: [
  {
    name: apiautheide1ZmR8bMeta?.name ?? "organization-oid-employee-employee_oid-apiauth",
    path: apiautheide1ZmR8bMeta?.path ?? "apiauth",
    meta: apiautheide1ZmR8bMeta || {},
    alias: apiautheide1ZmR8bMeta?.alias || [],
    redirect: apiautheide1ZmR8bMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/employee/[employee_oid]/apiauth.vue").then(m => m.default || m)
  },
  {
    name: basiczZtSiW5Hm7Meta?.name ?? "organization-oid-employee-employee_oid-basic",
    path: basiczZtSiW5Hm7Meta?.path ?? "basic",
    meta: basiczZtSiW5Hm7Meta || {},
    alias: basiczZtSiW5Hm7Meta?.alias || [],
    redirect: basiczZtSiW5Hm7Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/employee/[employee_oid]/basic.vue").then(m => m.default || m)
  }
]
  },
  {
    name: infok4MzJgIYXzMeta?.name ?? "organization-oid-info",
    path: infok4MzJgIYXzMeta?.path ?? "info",
    meta: infok4MzJgIYXzMeta || {},
    alias: infok4MzJgIYXzMeta?.alias || [],
    redirect: infok4MzJgIYXzMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/info.vue").then(m => m.default || m),
    children: [
  {
    name: basicU3I6kBlUkAMeta?.name ?? "organization-oid-info-basic",
    path: basicU3I6kBlUkAMeta?.path ?? "basic",
    meta: basicU3I6kBlUkAMeta || {},
    alias: basicU3I6kBlUkAMeta?.alias || [],
    redirect: basicU3I6kBlUkAMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/info/basic.vue").then(m => m.default || m)
  },
  {
    name: mapRq3Hvicm4fMeta?.name ?? "organization-oid-info-map",
    path: mapRq3Hvicm4fMeta?.path ?? "map",
    meta: mapRq3Hvicm4fMeta || {},
    alias: mapRq3Hvicm4fMeta?.alias || [],
    redirect: mapRq3Hvicm4fMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/info/map.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexM30f2C98IvMeta?.name ?? "organization-oid-structure-department",
    path: indexM30f2C98IvMeta?.path ?? "structure/department",
    meta: indexM30f2C98IvMeta || {},
    alias: indexM30f2C98IvMeta?.alias || [],
    redirect: indexM30f2C98IvMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/structure/department/index.vue").then(m => m.default || m)
  },
  {
    name: indexuEC0gt69vrMeta?.name ?? "organization-oid-structure-employee",
    path: indexuEC0gt69vrMeta?.path ?? "structure/employee",
    meta: indexuEC0gt69vrMeta || {},
    alias: indexuEC0gt69vrMeta?.alias || [],
    redirect: indexuEC0gt69vrMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/structure/employee/index.vue").then(m => m.default || m)
  },
  {
    name: indexOqQcNsd8dUMeta?.name ?? "organization-oid-structure-group",
    path: indexOqQcNsd8dUMeta?.path ?? "structure/group",
    meta: indexOqQcNsd8dUMeta || {},
    alias: indexOqQcNsd8dUMeta?.alias || [],
    redirect: indexOqQcNsd8dUMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/structure/group/index.vue").then(m => m.default || m)
  },
  {
    name: indexuroDAr2n2QMeta?.name ?? "organization-oid-structure-v1-department",
    path: indexuroDAr2n2QMeta?.path ?? "structure/v1/department",
    meta: indexuroDAr2n2QMeta || {},
    alias: indexuroDAr2n2QMeta?.alias || [],
    redirect: indexuroDAr2n2QMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/structure/v1/department/index.vue").then(m => m.default || m)
  },
  {
    name: employeesOmpvO3JoeMeta?.name ?? "organization-oid-structure-v1-employee",
    path: employeesOmpvO3JoeMeta?.path ?? "structure/v1/employee",
    meta: employeesOmpvO3JoeMeta || {},
    alias: employeesOmpvO3JoeMeta?.alias || [],
    redirect: employeesOmpvO3JoeMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/structure/v1/employee.vue").then(m => m.default || m),
    children: [
  {
    name: normaljcPTHbO9pYMeta?.name ?? "organization-oid-structure-v1-employee-normal",
    path: normaljcPTHbO9pYMeta?.path ?? "normal",
    meta: normaljcPTHbO9pYMeta || {},
    alias: normaljcPTHbO9pYMeta?.alias || [],
    redirect: normaljcPTHbO9pYMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/organization/pages/organization/[oid]/structure/v1/employee/normal.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: indexu1ipsRV4FhMeta?.name ?? "organization",
    path: indexu1ipsRV4FhMeta?.path ?? "/organization",
    meta: indexu1ipsRV4FhMeta || {},
    alias: indexu1ipsRV4FhMeta?.alias || [],
    redirect: indexu1ipsRV4FhMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/organization/pages/organization/index.vue").then(m => m.default || m)
  },
  {
    name: index5q8XPPgZhbMeta?.name ?? "pm-admin-catalog",
    path: index5q8XPPgZhbMeta?.path ?? "/pm/admin/catalog",
    meta: index5q8XPPgZhbMeta || {},
    alias: index5q8XPPgZhbMeta?.alias || [],
    redirect: index5q8XPPgZhbMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/pm/pages/pm/admin/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: orgdSz2MR2uXaMeta?.name ?? "pm-admin-collaboration-org",
    path: orgdSz2MR2uXaMeta?.path ?? "/pm/admin/collaboration/org",
    meta: orgdSz2MR2uXaMeta || {},
    alias: orgdSz2MR2uXaMeta?.alias || [],
    redirect: orgdSz2MR2uXaMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/pm/pages/pm/admin/collaboration/org.vue").then(m => m.default || m)
  },
  {
    name: users2Rb5Btr3nMeta?.name ?? "pm-admin-collaboration-user",
    path: users2Rb5Btr3nMeta?.path ?? "/pm/admin/collaboration/user",
    meta: users2Rb5Btr3nMeta || {},
    alias: users2Rb5Btr3nMeta?.alias || [],
    redirect: users2Rb5Btr3nMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/pm/pages/pm/admin/collaboration/user.vue").then(m => m.default || m)
  },
  {
    name: indexmapGsCcT3pMeta?.name ?? "pm-admin",
    path: indexmapGsCcT3pMeta?.path ?? "/pm/admin",
    meta: indexmapGsCcT3pMeta || {},
    alias: indexmapGsCcT3pMeta?.alias || [],
    redirect: indexmapGsCcT3pMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/pm/pages/pm/admin/index.vue").then(m => m.default || m)
  },
  {
    name: focusARM2873Uj9Meta?.name ?? "pm-admin-project-focus",
    path: focusARM2873Uj9Meta?.path ?? "/pm/admin/project/focus",
    meta: focusARM2873Uj9Meta || {},
    alias: focusARM2873Uj9Meta?.alias || [],
    redirect: focusARM2873Uj9Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/pm/pages/pm/admin/project/focus.vue").then(m => m.default || m)
  },
  {
    name: indexssMeTdXcitMeta?.name ?? "pm-admin-project",
    path: indexssMeTdXcitMeta?.path ?? "/pm/admin/project",
    meta: indexssMeTdXcitMeta || {},
    alias: indexssMeTdXcitMeta?.alias || [],
    redirect: indexssMeTdXcitMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/pm/pages/pm/admin/project/index.vue").then(m => m.default || m)
  },
  {
    name: indexcljwPe728UMeta?.name ?? "pm-admin-project-old",
    path: indexcljwPe728UMeta?.path ?? "/pm/admin/project/old",
    meta: indexcljwPe728UMeta || {},
    alias: indexcljwPe728UMeta?.alias || [],
    redirect: indexcljwPe728UMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/pm/pages/pm/admin/project/old/index.vue").then(m => m.default || m)
  },
  {
    name: staruPU5rcXvXHMeta?.name ?? "pm-admin-project-star",
    path: staruPU5rcXvXHMeta?.path ?? "/pm/admin/project/star",
    meta: staruPU5rcXvXHMeta || {},
    alias: staruPU5rcXvXHMeta?.alias || [],
    redirect: staruPU5rcXvXHMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/pm/pages/pm/admin/project/star.vue").then(m => m.default || m)
  },
  {
    name: groupB8r5ajXWT3Meta?.name ?? "pm-admin-setting-group",
    path: groupB8r5ajXWT3Meta?.path ?? "/pm/admin/setting/group",
    meta: groupB8r5ajXWT3Meta || {},
    alias: groupB8r5ajXWT3Meta?.alias || [],
    redirect: groupB8r5ajXWT3Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/pm/pages/pm/admin/setting/group.vue").then(m => m.default || m)
  },
  {
    name: user5yMfibi5cOMeta?.name ?? "pm-admin-setting-user",
    path: user5yMfibi5cOMeta?.path ?? "/pm/admin/setting/user",
    meta: user5yMfibi5cOMeta || {},
    alias: user5yMfibi5cOMeta?.alias || [],
    redirect: user5yMfibi5cOMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/pm/pages/pm/admin/setting/user.vue").then(m => m.default || m)
  },
  {
    name: indexhlUVq5Hsq4Meta?.name ?? "pm-admin-system",
    path: indexhlUVq5Hsq4Meta?.path ?? "/pm/admin/system",
    meta: indexhlUVq5Hsq4Meta || {},
    alias: indexhlUVq5Hsq4Meta?.alias || [],
    redirect: indexhlUVq5Hsq4Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/pm/pages/pm/admin/system/index.vue").then(m => m.default || m)
  },
  {
    name: indexi1LDcPEoIvMeta?.name ?? "pm",
    path: indexi1LDcPEoIvMeta?.path ?? "/pm",
    meta: indexi1LDcPEoIvMeta || {},
    alias: indexi1LDcPEoIvMeta?.alias || [],
    redirect: indexi1LDcPEoIvMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/pm/pages/pm/index.vue").then(m => m.default || m)
  },
  {
    name: client5vwKD2PUjPMeta?.name ?? "portal-doc-client",
    path: client5vwKD2PUjPMeta?.path ?? "/portal/doc/client",
    meta: client5vwKD2PUjPMeta || {},
    alias: client5vwKD2PUjPMeta?.alias || [],
    redirect: client5vwKD2PUjPMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/portal/pages/portal/doc/client.vue").then(m => m.default || m)
  },
  {
    name: serverJsgx5RMjBvMeta?.name ?? "portal-doc-server",
    path: serverJsgx5RMjBvMeta?.path ?? "/portal/doc/server",
    meta: serverJsgx5RMjBvMeta || {},
    alias: serverJsgx5RMjBvMeta?.alias || [],
    redirect: serverJsgx5RMjBvMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/portal/pages/portal/doc/server.vue").then(m => m.default || m)
  },
  {
    name: indexfQ9wUFGTWxMeta?.name ?? "portal-example-compose-id",
    path: indexfQ9wUFGTWxMeta?.path ?? "/portal/example/compose/:id()",
    meta: indexfQ9wUFGTWxMeta || {},
    alias: indexfQ9wUFGTWxMeta?.alias || [],
    redirect: indexfQ9wUFGTWxMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/portal/pages/portal/example/compose/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqYTqh3TVx5Meta?.name ?? "portal-example-compose",
    path: indexqYTqh3TVx5Meta?.path ?? "/portal/example/compose",
    meta: indexqYTqh3TVx5Meta || {},
    alias: indexqYTqh3TVx5Meta?.alias || [],
    redirect: indexqYTqh3TVx5Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/portal/pages/portal/example/compose/index.vue").then(m => m.default || m)
  },
  {
    name: indexrDtrsaDtoHMeta?.name ?? "portal-example-exampleBrowsing-id",
    path: indexrDtrsaDtoHMeta?.path ?? "/portal/example/exampleBrowsing/:id()",
    meta: indexrDtrsaDtoHMeta || {},
    alias: indexrDtrsaDtoHMeta?.alias || [],
    redirect: indexrDtrsaDtoHMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/portal/pages/portal/example/exampleBrowsing/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexKxfRMSeSJsMeta?.name ?? "portal-example-exampleBrowsing",
    path: indexKxfRMSeSJsMeta?.path ?? "/portal/example/exampleBrowsing",
    meta: indexKxfRMSeSJsMeta || {},
    alias: indexKxfRMSeSJsMeta?.alias || [],
    redirect: indexKxfRMSeSJsMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/portal/pages/portal/example/exampleBrowsing/index.vue").then(m => m.default || m)
  },
  {
    name: groupwuVFU39obNMeta?.name ?? "portal-example-group",
    path: groupwuVFU39obNMeta?.path ?? "/portal/example/group",
    meta: groupwuVFU39obNMeta || {},
    alias: groupwuVFU39obNMeta?.alias || [],
    redirect: groupwuVFU39obNMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/portal/pages/portal/example/group.vue").then(m => m.default || m)
  },
  {
    name: clientOZHrAtdGVFMeta?.name ?? "portal-home-docs-client",
    path: clientOZHrAtdGVFMeta?.path ?? "/portal/home/docs/client",
    meta: clientOZHrAtdGVFMeta || {},
    alias: clientOZHrAtdGVFMeta?.alias || [],
    redirect: clientOZHrAtdGVFMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/portal/pages/portal/home/docs/client.vue").then(m => m.default || m)
  },
  {
    name: serverDNw50EAUEIMeta?.name ?? "portal-home-docs-server",
    path: serverDNw50EAUEIMeta?.path ?? "/portal/home/docs/server",
    meta: serverDNw50EAUEIMeta || {},
    alias: serverDNw50EAUEIMeta?.alias || [],
    redirect: serverDNw50EAUEIMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/portal/pages/portal/home/docs/server.vue").then(m => m.default || m)
  },
  {
    name: _91id_93R4dX0BMW2fMeta?.name ?? "portal-home-example-id",
    path: _91id_93R4dX0BMW2fMeta?.path ?? "/portal/home/example/:id()",
    meta: _91id_93R4dX0BMW2fMeta || {},
    alias: _91id_93R4dX0BMW2fMeta?.alias || [],
    redirect: _91id_93R4dX0BMW2fMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/portal/pages/portal/home/example/[id].vue").then(m => m.default || m)
  },
  {
    name: indexATETxnk6aQMeta?.name ?? "portal-home-example",
    path: indexATETxnk6aQMeta?.path ?? "/portal/home/example",
    meta: indexATETxnk6aQMeta || {},
    alias: indexATETxnk6aQMeta?.alias || [],
    redirect: indexATETxnk6aQMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/portal/pages/portal/home/example/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93LTS0qVxmVIMeta?.name ?? "portal-home-feature-id",
    path: _91id_93LTS0qVxmVIMeta?.path ?? "/portal/home/feature/:id()",
    meta: _91id_93LTS0qVxmVIMeta || {},
    alias: _91id_93LTS0qVxmVIMeta?.alias || [],
    redirect: _91id_93LTS0qVxmVIMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/portal/pages/portal/home/feature/[id].vue").then(m => m.default || m)
  },
  {
    name: indexdXyKZkjlluMeta?.name ?? "portal-home-feature",
    path: indexdXyKZkjlluMeta?.path ?? "/portal/home/feature",
    meta: indexdXyKZkjlluMeta || {},
    alias: indexdXyKZkjlluMeta?.alias || [],
    redirect: indexdXyKZkjlluMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/portal/pages/portal/home/feature/index.vue").then(m => m.default || m)
  },
  {
    name: indexX3CBDwT3O2Meta?.name ?? "portal-home",
    path: indexX3CBDwT3O2Meta?.path ?? "/portal/home",
    meta: indexX3CBDwT3O2Meta || {},
    alias: indexX3CBDwT3O2Meta?.alias || [],
    redirect: indexX3CBDwT3O2Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/portal/pages/portal/home/index.vue").then(m => m.default || m)
  },
  {
    name: browsey45crXNsX1Meta?.name ?? "portal-home-standard-browse",
    path: browsey45crXNsX1Meta?.path ?? "/portal/home/standard/browse",
    meta: browsey45crXNsX1Meta || {},
    alias: browsey45crXNsX1Meta?.alias || [],
    redirect: browsey45crXNsX1Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/portal/pages/portal/home/standard/browse.vue").then(m => m.default || m)
  },
  {
    name: searchMwXoJgzq6YMeta?.name ?? "portal-home-standard-search",
    path: searchMwXoJgzq6YMeta?.path ?? "/portal/home/standard/search",
    meta: searchMwXoJgzq6YMeta || {},
    alias: searchMwXoJgzq6YMeta?.alias || [],
    redirect: searchMwXoJgzq6YMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/portal/pages/portal/home/standard/search.vue").then(m => m.default || m)
  },
  {
    name: index2YzW9XJKJOMeta?.name ?? "portal-scene-arrange-id",
    path: index2YzW9XJKJOMeta?.path ?? "/portal/scene/arrange/:id()",
    meta: index2YzW9XJKJOMeta || {},
    alias: index2YzW9XJKJOMeta?.alias || [],
    redirect: index2YzW9XJKJOMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/portal/pages/portal/scene/arrange/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexoyshPwvGxLMeta?.name ?? "portal-scene-arrange",
    path: indexoyshPwvGxLMeta?.path ?? "/portal/scene/arrange",
    meta: indexoyshPwvGxLMeta || {},
    alias: indexoyshPwvGxLMeta?.alias || [],
    redirect: indexoyshPwvGxLMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/portal/pages/portal/scene/arrange/index.vue").then(m => m.default || m)
  },
  {
    name: groupLivTyV3zMaMeta?.name ?? "portal-scene-group",
    path: groupLivTyV3zMaMeta?.path ?? "/portal/scene/group",
    meta: groupLivTyV3zMaMeta || {},
    alias: groupLivTyV3zMaMeta?.alias || [],
    redirect: groupLivTyV3zMaMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/portal/pages/portal/scene/group.vue").then(m => m.default || m)
  },
  {
    name: indexYfQWmyqr7KMeta?.name ?? "portal-scene-sceneBrowsing-id",
    path: indexYfQWmyqr7KMeta?.path ?? "/portal/scene/sceneBrowsing/:id()",
    meta: indexYfQWmyqr7KMeta || {},
    alias: indexYfQWmyqr7KMeta?.alias || [],
    redirect: indexYfQWmyqr7KMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/portal/pages/portal/scene/sceneBrowsing/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index92tmoYQNZHMeta?.name ?? "portal-scene-sceneBrowsing",
    path: index92tmoYQNZHMeta?.path ?? "/portal/scene/sceneBrowsing",
    meta: index92tmoYQNZHMeta || {},
    alias: index92tmoYQNZHMeta?.alias || [],
    redirect: index92tmoYQNZHMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/portal/pages/portal/scene/sceneBrowsing/index.vue").then(m => m.default || m)
  },
  {
    name: prismaaaz1YpIJMMMeta?.name ?? "prisma",
    path: prismaaaz1YpIJMMMeta?.path ?? "/prisma",
    meta: prismaaaz1YpIJMMMeta || {},
    alias: prismaaaz1YpIJMMMeta?.alias || [],
    redirect: prismaaaz1YpIJMMMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/framework/pages/prisma.vue").then(m => m.default || m)
  },
  {
    name: indexbbAD2Th63uMeta?.name ?? "project-org-access-member",
    path: indexbbAD2Th63uMeta?.path ?? "/project-org/access/member",
    meta: indexbbAD2Th63uMeta || {},
    alias: indexbbAD2Th63uMeta?.alias || [],
    redirect: indexbbAD2Th63uMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/project-org/pages/project-org/access/member/index.vue").then(m => m.default || m)
  },
  {
    name: indexBTbMRe4ZaBMeta?.name ?? "project-org-access-policy",
    path: indexBTbMRe4ZaBMeta?.path ?? "/project-org/access/policy",
    meta: indexBTbMRe4ZaBMeta || {},
    alias: indexBTbMRe4ZaBMeta?.alias || [],
    redirect: indexBTbMRe4ZaBMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/project-org/pages/project-org/access/policy/index.vue").then(m => m.default || m)
  },
  {
    name: index9jIP0dtXXxMeta?.name ?? "project-org-access-role",
    path: index9jIP0dtXXxMeta?.path ?? "/project-org/access/role",
    meta: index9jIP0dtXXxMeta || {},
    alias: index9jIP0dtXXxMeta?.alias || [],
    redirect: index9jIP0dtXXxMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/project-org/pages/project-org/access/role/index.vue").then(m => m.default || m)
  },
  {
    name: indexMNd7nvC2SsMeta?.name ?? "project-org-culture-appearance",
    path: indexMNd7nvC2SsMeta?.path ?? "/project-org/culture/appearance",
    meta: indexMNd7nvC2SsMeta || {},
    alias: indexMNd7nvC2SsMeta?.alias || [],
    redirect: indexMNd7nvC2SsMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/project-org/pages/project-org/culture/appearance/index.vue").then(m => m.default || m)
  },
  {
    name: indexLwEMSEUKZUMeta?.name ?? "project-org-culture-menu",
    path: indexLwEMSEUKZUMeta?.path ?? "/project-org/culture/menu",
    meta: indexLwEMSEUKZUMeta || {},
    alias: indexLwEMSEUKZUMeta?.alias || [],
    redirect: indexLwEMSEUKZUMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/project-org/pages/project-org/culture/menu/index.vue").then(m => m.default || m)
  },
  {
    name: index3URQGueFa9Meta?.name ?? "project-org",
    path: index3URQGueFa9Meta?.path ?? "/project-org",
    meta: index3URQGueFa9Meta || {},
    alias: index3URQGueFa9Meta?.alias || [],
    redirect: index3URQGueFa9Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/project-org/pages/project-org/index.vue").then(m => m.default || m)
  },
  {
    name: indexV8y4YA2AzJMeta?.name ?? "project-org-integration-connect",
    path: indexV8y4YA2AzJMeta?.path ?? "/project-org/integration/connect",
    meta: indexV8y4YA2AzJMeta || {},
    alias: indexV8y4YA2AzJMeta?.alias || [],
    redirect: indexV8y4YA2AzJMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/project-org/pages/project-org/integration/connect/index.vue").then(m => m.default || m)
  },
  {
    name: indexv7Oq37knBsMeta?.name ?? "project-org-integration-iot",
    path: indexv7Oq37knBsMeta?.path ?? "/project-org/integration/iot",
    meta: indexv7Oq37knBsMeta || {},
    alias: indexv7Oq37knBsMeta?.alias || [],
    redirect: indexv7Oq37knBsMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/project-org/pages/project-org/integration/iot/index.vue").then(m => m.default || m)
  },
  {
    name: index0zbfoEVtjRMeta?.name ?? "project-project_oid-cehua-pbs-id_name",
    path: index0zbfoEVtjRMeta?.path ?? "/project/:project_oid()/cehua/pbs/:id_name()",
    meta: index0zbfoEVtjRMeta || {},
    alias: index0zbfoEVtjRMeta?.alias || [],
    redirect: index0zbfoEVtjRMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/cehua/pbs/[id_name]/index.vue").then(m => m.default || m)
  },
  {
    name: catalog_45selectmmtvB58W3sMeta?.name ?? "project-project_oid-cehua-pbs-catalog-select",
    path: catalog_45selectmmtvB58W3sMeta?.path ?? "/project/:project_oid()/cehua/pbs/catalog-select",
    meta: catalog_45selectmmtvB58W3sMeta || {},
    alias: catalog_45selectmmtvB58W3sMeta?.alias || [],
    redirect: catalog_45selectmmtvB58W3sMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/cehua/pbs/catalog-select.vue").then(m => m.default || m)
  },
  {
    name: catalog_45viewJIFaHx1qaYMeta?.name ?? "project-project_oid-cehua-pbs-catalog-view",
    path: catalog_45viewJIFaHx1qaYMeta?.path ?? "/project/:project_oid()/cehua/pbs/catalog-view",
    meta: catalog_45viewJIFaHx1qaYMeta || {},
    alias: catalog_45viewJIFaHx1qaYMeta?.alias || [],
    redirect: catalog_45viewJIFaHx1qaYMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/cehua/pbs/catalog-view.vue").then(m => m.default || m)
  },
  {
    name: index_46oldykJZR45aXmMeta?.name ?? "project-project_oid-cehua-pbs-index.old",
    path: index_46oldykJZR45aXmMeta?.path ?? "/project/:project_oid()/cehua/pbs/index.old",
    meta: index_46oldykJZR45aXmMeta || {},
    alias: index_46oldykJZR45aXmMeta?.alias || [],
    redirect: index_46oldykJZR45aXmMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/cehua/pbs/index.old.vue").then(m => m.default || m)
  },
  {
    name: indexPpu2Sdqc5SMeta?.name ?? "project-project_oid-cehua-pbs",
    path: indexPpu2Sdqc5SMeta?.path ?? "/project/:project_oid()/cehua/pbs",
    meta: indexPpu2Sdqc5SMeta || {},
    alias: indexPpu2Sdqc5SMeta?.alias || [],
    redirect: indexPpu2Sdqc5SMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/cehua/pbs/index.vue").then(m => m.default || m)
  },
  {
    name: unitNaLsrQ35tmMeta?.name ?? "project-project_oid-cehua-unit",
    path: unitNaLsrQ35tmMeta?.path ?? "/project/:project_oid()/cehua/unit",
    meta: unitNaLsrQ35tmMeta || {},
    alias: unitNaLsrQ35tmMeta?.alias || [],
    redirect: unitNaLsrQ35tmMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/cehua/unit.vue").then(m => m.default || m),
    children: [
  {
    name: normalV87xa4XuowMeta?.name ?? "project-project_oid-cehua-unit-normal",
    path: normalV87xa4XuowMeta?.path ?? "normal",
    meta: normalV87xa4XuowMeta || {},
    alias: normalV87xa4XuowMeta?.alias || [],
    redirect: normalV87xa4XuowMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/cehua/unit/normal.vue").then(m => m.default || m)
  },
  {
    name: trashguMTbz7yOXMeta?.name ?? "project-project_oid-cehua-unit-trash",
    path: trashguMTbz7yOXMeta?.path ?? "trash",
    meta: trashguMTbz7yOXMeta || {},
    alias: trashguMTbz7yOXMeta?.alias || [],
    redirect: trashguMTbz7yOXMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/cehua/unit/trash.vue").then(m => m.default || m)
  }
]
  },
  {
    name: basiciyqYm7wmyZMeta?.name ?? "project-project_oid-config-basic",
    path: basiciyqYm7wmyZMeta?.path ?? "/project/:project_oid()/config/basic",
    meta: basiciyqYm7wmyZMeta || {},
    alias: basiciyqYm7wmyZMeta?.alias || [],
    redirect: basiciyqYm7wmyZMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/config/basic.vue").then(m => m.default || m)
  },
  {
    name: indexxIj3cscKgeMeta?.name ?? "project-project_oid",
    path: indexxIj3cscKgeMeta?.path ?? "/project/:project_oid()",
    meta: indexxIj3cscKgeMeta || {},
    alias: indexxIj3cscKgeMeta?.alias || [],
    redirect: indexxIj3cscKgeMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/index.vue").then(m => m.default || m)
  },
  {
    name: basic_45oldSbuCVs5syHMeta?.name ?? "project-project_oid-info-basic-old",
    path: basic_45oldSbuCVs5syHMeta?.path ?? "/project/:project_oid()/info/basic-old",
    meta: basic_45oldSbuCVs5syHMeta || {},
    alias: basic_45oldSbuCVs5syHMeta?.alias || [],
    redirect: basic_45oldSbuCVs5syHMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/info/basic-old.vue").then(m => m.default || m)
  },
  {
    name: basic9Xj83s2XjyMeta?.name ?? undefined,
    path: basic9Xj83s2XjyMeta?.path ?? "/project/:project_oid()/info/basic",
    meta: basic9Xj83s2XjyMeta || {},
    alias: basic9Xj83s2XjyMeta?.alias || [],
    redirect: basic9Xj83s2XjyMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/info/basic.vue").then(m => m.default || m),
    children: [
  {
    name: advanceSettingRPNdovUglEMeta?.name ?? "project-project_oid-info-basic-advanceSetting",
    path: advanceSettingRPNdovUglEMeta?.path ?? "advanceSetting",
    meta: advanceSettingRPNdovUglEMeta || {},
    alias: advanceSettingRPNdovUglEMeta?.alias || [],
    redirect: advanceSettingRPNdovUglEMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/info/basic/advanceSetting.vue").then(m => m.default || m)
  },
  {
    name: extendInfoDwkmQxYIdGMeta?.name ?? "project-project_oid-info-basic-extendInfo",
    path: extendInfoDwkmQxYIdGMeta?.path ?? "extendInfo",
    meta: extendInfoDwkmQxYIdGMeta || {},
    alias: extendInfoDwkmQxYIdGMeta?.alias || [],
    redirect: extendInfoDwkmQxYIdGMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/info/basic/extendInfo.vue").then(m => m.default || m)
  },
  {
    name: index6On73mkqBoMeta?.name ?? "project-project_oid-info-basic",
    path: index6On73mkqBoMeta?.path ?? "",
    meta: index6On73mkqBoMeta || {},
    alias: index6On73mkqBoMeta?.alias || [],
    redirect: index6On73mkqBoMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/info/basic/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: coverF18UzB8mPgMeta?.name ?? "project-project_oid-info-cover",
    path: coverF18UzB8mPgMeta?.path ?? "/project/:project_oid()/info/cover",
    meta: coverF18UzB8mPgMeta || {},
    alias: coverF18UzB8mPgMeta?.alias || [],
    redirect: coverF18UzB8mPgMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/info/cover.vue").then(m => m.default || m)
  },
  {
    name: mapM9eLOpWSh8Meta?.name ?? "project-project_oid-info-map",
    path: mapM9eLOpWSh8Meta?.path ?? "/project/:project_oid()/info/map",
    meta: mapM9eLOpWSh8Meta || {},
    alias: mapM9eLOpWSh8Meta?.alias || [],
    redirect: mapM9eLOpWSh8Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/project/pages/project/[project_oid]/info/map.vue").then(m => m.default || m)
  },
  {
    name: protected0gTvo00IXxMeta?.name ?? "protected",
    path: protected0gTvo00IXxMeta?.path ?? "/protected",
    meta: protected0gTvo00IXxMeta || {},
    alias: protected0gTvo00IXxMeta?.alias || [],
    redirect: protected0gTvo00IXxMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/framework/pages/protected.vue").then(m => m.default || m)
  },
  {
    name: _91id_935MDCFabwKbMeta?.name ?? "render-first_catalog-id",
    path: _91id_935MDCFabwKbMeta?.path ?? "/render/:first_catalog()/:id()",
    meta: _91id_935MDCFabwKbMeta || {},
    alias: _91id_935MDCFabwKbMeta?.alias || [],
    redirect: _91id_935MDCFabwKbMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/render/pages/render/[first_catalog]/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93M4PMs6WHuxMeta?.name ?? "render-first_catalog-second_catalog-id",
    path: _91id_93M4PMs6WHuxMeta?.path ?? "/render/:first_catalog()/:second_catalog()/:id()",
    meta: _91id_93M4PMs6WHuxMeta || {},
    alias: _91id_93M4PMs6WHuxMeta?.alias || [],
    redirect: _91id_93M4PMs6WHuxMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/render/pages/render/[first_catalog]/[second_catalog]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexkajiMaxYSnMeta?.name ?? "render-first_catalog-second_catalog",
    path: indexkajiMaxYSnMeta?.path ?? "/render/:first_catalog()/:second_catalog()",
    meta: indexkajiMaxYSnMeta || {},
    alias: indexkajiMaxYSnMeta?.alias || [],
    redirect: indexkajiMaxYSnMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/render/pages/render/[first_catalog]/[second_catalog]/index.vue").then(m => m.default || m)
  },
  {
    name: indexeEEhI9WwruMeta?.name ?? "render-first_catalog",
    path: indexeEEhI9WwruMeta?.path ?? "/render/:first_catalog()",
    meta: indexeEEhI9WwruMeta || {},
    alias: indexeEEhI9WwruMeta?.alias || [],
    redirect: indexeEEhI9WwruMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/render/pages/render/[first_catalog]/index.vue").then(m => m.default || m)
  },
  {
    name: index5oeIRuOmyPMeta?.name ?? "render-doc",
    path: index5oeIRuOmyPMeta?.path ?? "/render/doc",
    meta: index5oeIRuOmyPMeta || {},
    alias: index5oeIRuOmyPMeta?.alias || [],
    redirect: index5oeIRuOmyPMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/render/pages/render/doc/index.vue").then(m => m.default || m)
  },
  {
    name: editQRfY3CUtWaMeta?.name ?? "render-example_old-first_catalog-id-edit",
    path: editQRfY3CUtWaMeta?.path ?? "/render/example_old/:first_catalog()/:id()/edit",
    meta: editQRfY3CUtWaMeta || {},
    alias: editQRfY3CUtWaMeta?.alias || [],
    redirect: editQRfY3CUtWaMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/render/pages/render/example_old/[first_catalog]/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: index8CA1Gjd5qiMeta?.name ?? "render-example_old-first_catalog-id",
    path: index8CA1Gjd5qiMeta?.path ?? "/render/example_old/:first_catalog()/:id()",
    meta: index8CA1Gjd5qiMeta || {},
    alias: index8CA1Gjd5qiMeta?.alias || [],
    redirect: index8CA1Gjd5qiMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/render/pages/render/example_old/[first_catalog]/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index4Pm9xey8B3Meta?.name ?? "render-example_old-first_catalog",
    path: index4Pm9xey8B3Meta?.path ?? "/render/example_old/:first_catalog()",
    meta: index4Pm9xey8B3Meta || {},
    alias: index4Pm9xey8B3Meta?.alias || [],
    redirect: index4Pm9xey8B3Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/render/pages/render/example_old/[first_catalog]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93sX2LTHXdHaMeta?.name ?? "render-example-scene-id",
    path: _91id_93sX2LTHXdHaMeta?.path ?? "/render/example/scene/:id()",
    meta: _91id_93sX2LTHXdHaMeta || {},
    alias: _91id_93sX2LTHXdHaMeta?.alias || [],
    redirect: _91id_93sX2LTHXdHaMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/render/pages/render/example/scene/[id].vue").then(m => m.default || m)
  },
  {
    name: indexMVuxh5YCthMeta?.name ?? "render-example-scene",
    path: indexMVuxh5YCthMeta?.path ?? "/render/example/scene",
    meta: indexMVuxh5YCthMeta || {},
    alias: indexMVuxh5YCthMeta?.alias || [],
    redirect: indexMVuxh5YCthMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/render/pages/render/example/scene/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93pf8bO4QjDmMeta?.name ?? "render-gallery-id",
    path: _91id_93pf8bO4QjDmMeta?.path ?? "/render/gallery/:id()",
    meta: _91id_93pf8bO4QjDmMeta || {},
    alias: _91id_93pf8bO4QjDmMeta?.alias || [],
    redirect: _91id_93pf8bO4QjDmMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/render/pages/render/gallery/[id].vue").then(m => m.default || m)
  },
  {
    name: indexvU70lKemf7Meta?.name ?? "render-gallery",
    path: indexvU70lKemf7Meta?.path ?? "/render/gallery",
    meta: indexvU70lKemf7Meta || {},
    alias: indexvU70lKemf7Meta?.alias || [],
    redirect: indexvU70lKemf7Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/render/pages/render/gallery/index.vue").then(m => m.default || m)
  },
  {
    name: indexhnQrgY0ZOmMeta?.name ?? "render-scaffold",
    path: indexhnQrgY0ZOmMeta?.path ?? "/render/scaffold",
    meta: indexhnQrgY0ZOmMeta || {},
    alias: indexhnQrgY0ZOmMeta?.alias || [],
    redirect: indexhnQrgY0ZOmMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/render/pages/render/scaffold/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93cs1OAyeXsIMeta?.name ?? "render-scenedisplay-id",
    path: _91id_93cs1OAyeXsIMeta?.path ?? "/render/scenedisplay/:id()",
    meta: _91id_93cs1OAyeXsIMeta || {},
    alias: _91id_93cs1OAyeXsIMeta?.alias || [],
    redirect: _91id_93cs1OAyeXsIMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/render/pages/render/scenedisplay/[id].vue").then(m => m.default || m)
  },
  {
    name: indexwXX5yGMH14Meta?.name ?? "render-scenedisplay",
    path: indexwXX5yGMH14Meta?.path ?? "/render/scenedisplay",
    meta: indexwXX5yGMH14Meta || {},
    alias: indexwXX5yGMH14Meta?.alias || [],
    redirect: indexwXX5yGMH14Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/render/pages/render/scenedisplay/index.vue").then(m => m.default || m)
  },
  {
    name: doc_46oldxuIBjIHVeGMeta?.name ?? "render-sdk-doc.old",
    path: doc_46oldxuIBjIHVeGMeta?.path ?? "/render/sdk/doc.old",
    meta: doc_46oldxuIBjIHVeGMeta || {},
    alias: doc_46oldxuIBjIHVeGMeta?.alias || [],
    redirect: doc_46oldxuIBjIHVeGMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/render/pages/render/sdk/doc.old.vue").then(m => m.default || m)
  },
  {
    name: docED10RE8S6DMeta?.name ?? "render-sdk-doc",
    path: docED10RE8S6DMeta?.path ?? "/render/sdk/doc",
    meta: docED10RE8S6DMeta || {},
    alias: docED10RE8S6DMeta?.alias || [],
    redirect: docED10RE8S6DMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/render/pages/render/sdk/doc.vue").then(m => m.default || m)
  },
  {
    name: doc_46oldgcZhNOMwq6Meta?.name ?? "render-server_sdk-doc.old",
    path: doc_46oldgcZhNOMwq6Meta?.path ?? "/render/server_sdk/doc.old",
    meta: doc_46oldgcZhNOMwq6Meta || {},
    alias: doc_46oldgcZhNOMwq6Meta?.alias || [],
    redirect: doc_46oldgcZhNOMwq6Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/render/pages/render/server_sdk/doc.old.vue").then(m => m.default || m)
  },
  {
    name: docyWfbX1wPGMMeta?.name ?? "render-server_sdk-doc",
    path: docyWfbX1wPGMMeta?.path ?? "/render/server_sdk/doc",
    meta: docyWfbX1wPGMMeta || {},
    alias: docyWfbX1wPGMMeta?.alias || [],
    redirect: docyWfbX1wPGMMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/render/pages/render/server_sdk/doc.vue").then(m => m.default || m)
  },
  {
    name: indexoITIr4KvZqMeta?.name ?? "schedule-project_id-analyse-critical",
    path: indexoITIr4KvZqMeta?.path ?? "/schedule/:project_id()/analyse/critical",
    meta: indexoITIr4KvZqMeta || {},
    alias: indexoITIr4KvZqMeta?.alias || [],
    redirect: indexoITIr4KvZqMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/analyse/critical/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_930xDzg54g80Meta?.name ?? "schedule-project_id-analyse-deviation-id",
    path: _91id_930xDzg54g80Meta?.path ?? "/schedule/:project_id()/analyse/deviation/:id()",
    meta: _91id_930xDzg54g80Meta || {},
    alias: _91id_930xDzg54g80Meta?.alias || [],
    redirect: _91id_930xDzg54g80Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/analyse/deviation/[id].vue").then(m => m.default || m),
    children: [
  {
    name: indexFgJCoa8zyyMeta?.name ?? "schedule-project_id-analyse-deviation-id-begin-work",
    path: indexFgJCoa8zyyMeta?.path ?? "begin-work",
    meta: indexFgJCoa8zyyMeta || {},
    alias: indexFgJCoa8zyyMeta?.alias || [],
    redirect: indexFgJCoa8zyyMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/analyse/deviation/[id]/begin-work/index.vue").then(m => m.default || m)
  },
  {
    name: indexCK1t9KqkL7Meta?.name ?? "schedule-project_id-analyse-deviation-id-bim-model",
    path: indexCK1t9KqkL7Meta?.path ?? "bim-model",
    meta: indexCK1t9KqkL7Meta || {},
    alias: indexCK1t9KqkL7Meta?.alias || [],
    redirect: indexCK1t9KqkL7Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/analyse/deviation/[id]/bim-model/index.vue").then(m => m.default || m)
  },
  {
    name: indexg8awhOOlZVMeta?.name ?? "schedule-project_id-analyse-deviation-id-duration",
    path: indexg8awhOOlZVMeta?.path ?? "duration",
    meta: indexg8awhOOlZVMeta || {},
    alias: indexg8awhOOlZVMeta?.alias || [],
    redirect: indexg8awhOOlZVMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/analyse/deviation/[id]/duration/index.vue").then(m => m.default || m)
  },
  {
    name: indexNie4xqRCbMMeta?.name ?? "schedule-project_id-analyse-deviation-id-end-work",
    path: indexNie4xqRCbMMeta?.path ?? "end-work",
    meta: indexNie4xqRCbMMeta || {},
    alias: indexNie4xqRCbMMeta?.alias || [],
    redirect: indexNie4xqRCbMMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/analyse/deviation/[id]/end-work/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index2qV4AxcMpmMeta?.name ?? "schedule-project_id-analyse-deviation",
    path: index2qV4AxcMpmMeta?.path ?? "/schedule/:project_id()/analyse/deviation",
    meta: index2qV4AxcMpmMeta || {},
    alias: index2qV4AxcMpmMeta?.alias || [],
    redirect: index2qV4AxcMpmMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/analyse/deviation/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93u0TAhClxDXMeta?.name ?? "schedule-project_id-analyse-simulate-id",
    path: _91id_93u0TAhClxDXMeta?.path ?? "/schedule/:project_id()/analyse/simulate/:id()",
    meta: _91id_93u0TAhClxDXMeta || {},
    alias: _91id_93u0TAhClxDXMeta?.alias || [],
    redirect: _91id_93u0TAhClxDXMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/analyse/simulate/[id].vue").then(m => m.default || m),
    children: [
  {
    name: indexLrGjw9fpSTMeta?.name ?? "schedule-project_id-analyse-simulate-id-actual",
    path: indexLrGjw9fpSTMeta?.path ?? "actual",
    meta: indexLrGjw9fpSTMeta || {},
    alias: indexLrGjw9fpSTMeta?.alias || [],
    redirect: indexLrGjw9fpSTMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/analyse/simulate/[id]/actual/index.vue").then(m => m.default || m)
  },
  {
    name: indexmEXNFA1zLbMeta?.name ?? "schedule-project_id-analyse-simulate-id-plan",
    path: indexmEXNFA1zLbMeta?.path ?? "plan",
    meta: indexmEXNFA1zLbMeta || {},
    alias: indexmEXNFA1zLbMeta?.alias || [],
    redirect: indexmEXNFA1zLbMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/analyse/simulate/[id]/plan/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index2sTT9c7tC4Meta?.name ?? "schedule-project_id-analyse-simulate",
    path: index2sTT9c7tC4Meta?.path ?? "/schedule/:project_id()/analyse/simulate",
    meta: index2sTT9c7tC4Meta || {},
    alias: index2sTT9c7tC4Meta?.alias || [],
    redirect: index2sTT9c7tC4Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/analyse/simulate/index.vue").then(m => m.default || m)
  },
  {
    name: indexOI43L3MNADMeta?.name ?? "schedule-project_id-optimize-cost",
    path: indexOI43L3MNADMeta?.path ?? "/schedule/:project_id()/optimize/cost",
    meta: indexOI43L3MNADMeta || {},
    alias: indexOI43L3MNADMeta?.alias || [],
    redirect: indexOI43L3MNADMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/optimize/cost/index.vue").then(m => m.default || m)
  },
  {
    name: tabstSSpUwBrgoMeta?.name ?? "schedule-project_id-optimize-cost-tabs",
    path: tabstSSpUwBrgoMeta?.path ?? "/schedule/:project_id()/optimize/cost/tabs",
    meta: tabstSSpUwBrgoMeta || {},
    alias: tabstSSpUwBrgoMeta?.alias || [],
    redirect: tabstSSpUwBrgoMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/optimize/cost/tabs.vue").then(m => m.default || m),
    children: [
  {
    name: indexYcQdMu69SHMeta?.name ?? "schedule-project_id-optimize-cost-tabs-analysis",
    path: indexYcQdMu69SHMeta?.path ?? "analysis",
    meta: indexYcQdMu69SHMeta || {},
    alias: indexYcQdMu69SHMeta?.alias || [],
    redirect: indexYcQdMu69SHMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/optimize/cost/tabs/analysis/index.vue").then(m => m.default || m)
  },
  {
    name: index0j2yKfMKcRMeta?.name ?? "schedule-project_id-optimize-cost-tabs-deviation",
    path: index0j2yKfMKcRMeta?.path ?? "deviation",
    meta: index0j2yKfMKcRMeta || {},
    alias: index0j2yKfMKcRMeta?.alias || [],
    redirect: index0j2yKfMKcRMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/optimize/cost/tabs/deviation/index.vue").then(m => m.default || m)
  },
  {
    name: indexVmksAjY0jKMeta?.name ?? "schedule-project_id-optimize-cost-tabs-information",
    path: indexVmksAjY0jKMeta?.path ?? "information",
    meta: indexVmksAjY0jKMeta || {},
    alias: indexVmksAjY0jKMeta?.alias || [],
    redirect: indexVmksAjY0jKMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/optimize/cost/tabs/information/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93ULYtRs7QYSMeta?.name ?? "schedule-project_id-optimize-renew-id",
    path: _91id_93ULYtRs7QYSMeta?.path ?? "/schedule/:project_id()/optimize/renew/:id()",
    meta: _91id_93ULYtRs7QYSMeta || {},
    alias: _91id_93ULYtRs7QYSMeta?.alias || [],
    redirect: _91id_93ULYtRs7QYSMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/optimize/renew/[id].vue").then(m => m.default || m),
    children: [
  {
    name: indexk1SmT6J8jYMeta?.name ?? "schedule-project_id-optimize-renew-id-now",
    path: indexk1SmT6J8jYMeta?.path ?? "now",
    meta: indexk1SmT6J8jYMeta || {},
    alias: indexk1SmT6J8jYMeta?.alias || [],
    redirect: indexk1SmT6J8jYMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/optimize/renew/[id]/now/index.vue").then(m => m.default || m)
  },
  {
    name: indexaNndtoS0YDMeta?.name ?? "schedule-project_id-optimize-renew-id-plan",
    path: indexaNndtoS0YDMeta?.path ?? "plan",
    meta: indexaNndtoS0YDMeta || {},
    alias: indexaNndtoS0YDMeta?.alias || [],
    redirect: indexaNndtoS0YDMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/optimize/renew/[id]/plan/index.vue").then(m => m.default || m)
  },
  {
    name: useSvgLvhNLsafJBMeta?.name ?? "schedule-project_id-optimize-renew-id-useSvg",
    path: useSvgLvhNLsafJBMeta?.path ?? "useSvg",
    meta: useSvgLvhNLsafJBMeta || {},
    alias: useSvgLvhNLsafJBMeta?.alias || [],
    redirect: useSvgLvhNLsafJBMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/optimize/renew/[id]/useSvg.ts").then(m => m.default || m)
  }
]
  },
  {
    name: indexezTzob9KELMeta?.name ?? "schedule-project_id-optimize-renew",
    path: indexezTzob9KELMeta?.path ?? "/schedule/:project_id()/optimize/renew",
    meta: indexezTzob9KELMeta || {},
    alias: indexezTzob9KELMeta?.alias || [],
    redirect: indexezTzob9KELMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/optimize/renew/index.vue").then(m => m.default || m)
  },
  {
    name: indexLDwPy3LtRTMeta?.name ?? "schedule-project_id-overview",
    path: indexLDwPy3LtRTMeta?.path ?? "/schedule/:project_id()/overview",
    meta: indexLDwPy3LtRTMeta || {},
    alias: indexLDwPy3LtRTMeta?.alias || [],
    redirect: indexLDwPy3LtRTMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/overview/index.vue").then(m => m.default || m)
  },
  {
    name: indexT57ZyX6cftMeta?.name ?? "schedule-project_id-plan",
    path: indexT57ZyX6cftMeta?.path ?? "/schedule/:project_id()/plan",
    meta: indexT57ZyX6cftMeta || {},
    alias: indexT57ZyX6cftMeta?.alias || [],
    redirect: indexT57ZyX6cftMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/index.vue").then(m => m.default || m)
  },
  {
    name: indexWhQta38WcdMeta?.name ?? "schedule-project_id-plan-main",
    path: indexWhQta38WcdMeta?.path ?? "/schedule/:project_id()/plan/main",
    meta: indexWhQta38WcdMeta || {},
    alias: indexWhQta38WcdMeta?.alias || [],
    redirect: indexWhQta38WcdMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/main/index.vue").then(m => m.default || m)
  },
  {
    name: index0h3EQt6qCbMeta?.name ?? "schedule-project_id-plan-major",
    path: index0h3EQt6qCbMeta?.path ?? "/schedule/:project_id()/plan/major",
    meta: index0h3EQt6qCbMeta || {},
    alias: index0h3EQt6qCbMeta?.alias || [],
    redirect: index0h3EQt6qCbMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/major/index.vue").then(m => m.default || m)
  },
  {
    name: tabs7JhIYRzuzaMeta?.name ?? "schedule-project_id-plan-major-tabs",
    path: tabs7JhIYRzuzaMeta?.path ?? "/schedule/:project_id()/plan/major/tabs",
    meta: tabs7JhIYRzuzaMeta || {},
    alias: tabs7JhIYRzuzaMeta?.alias || [],
    redirect: tabs7JhIYRzuzaMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/major/tabs.vue").then(m => m.default || m),
    children: [
  {
    name: indexSdfG3NKZMQMeta?.name ?? "schedule-project_id-plan-major-tabs-gantt-chart",
    path: indexSdfG3NKZMQMeta?.path ?? "gantt-chart",
    meta: indexSdfG3NKZMQMeta || {},
    alias: indexSdfG3NKZMQMeta?.alias || [],
    redirect: indexSdfG3NKZMQMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/major/tabs/gantt-chart/index.vue").then(m => m.default || m)
  },
  {
    name: indexKzoBb6BECUMeta?.name ?? "schedule-project_id-plan-major-tabs-network-diagram",
    path: indexKzoBb6BECUMeta?.path ?? "network-diagram",
    meta: indexKzoBb6BECUMeta || {},
    alias: indexKzoBb6BECUMeta?.alias || [],
    redirect: indexKzoBb6BECUMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/major/tabs/network-diagram/index.vue").then(m => m.default || m)
  },
  {
    name: useSvgB0kwXkkDf9Meta?.name ?? "schedule-project_id-plan-major-tabs-network-diagram-useSvg",
    path: useSvgB0kwXkkDf9Meta?.path ?? "network-diagram/useSvg",
    meta: useSvgB0kwXkkDf9Meta || {},
    alias: useSvgB0kwXkkDf9Meta?.alias || [],
    redirect: useSvgB0kwXkkDf9Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/major/tabs/network-diagram/useSvg.ts").then(m => m.default || m)
  },
  {
    name: index2DLrvysW2AMeta?.name ?? "schedule-project_id-plan-major-tabs-worksheet",
    path: index2DLrvysW2AMeta?.path ?? "worksheet",
    meta: index2DLrvysW2AMeta || {},
    alias: index2DLrvysW2AMeta?.alias || [],
    redirect: index2DLrvysW2AMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/major/tabs/worksheet/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93h0MloiKsQuMeta?.name ?? "schedule-project_id-plan-sub-id",
    path: _91id_93h0MloiKsQuMeta?.path ?? "/schedule/:project_id()/plan/sub/:id()",
    meta: _91id_93h0MloiKsQuMeta || {},
    alias: _91id_93h0MloiKsQuMeta?.alias || [],
    redirect: _91id_93h0MloiKsQuMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/sub/[id].vue").then(m => m.default || m),
    children: [
  {
    name: indexomtcMHg1ZJMeta?.name ?? "schedule-project_id-plan-sub-id-bim-model",
    path: indexomtcMHg1ZJMeta?.path ?? "bim-model",
    meta: indexomtcMHg1ZJMeta || {},
    alias: indexomtcMHg1ZJMeta?.alias || [],
    redirect: indexomtcMHg1ZJMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/sub/[id]/bim-model/index.vue").then(m => m.default || m)
  },
  {
    name: indexbDJEyP9O0xMeta?.name ?? "schedule-project_id-plan-sub-id-gantt-chart",
    path: indexbDJEyP9O0xMeta?.path ?? "gantt-chart",
    meta: indexbDJEyP9O0xMeta || {},
    alias: indexbDJEyP9O0xMeta?.alias || [],
    redirect: indexbDJEyP9O0xMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/sub/[id]/gantt-chart/index.vue").then(m => m.default || m)
  },
  {
    name: indexsNjs6sABmDMeta?.name ?? "schedule-project_id-plan-sub-id-network-diagram",
    path: indexsNjs6sABmDMeta?.path ?? "network-diagram",
    meta: indexsNjs6sABmDMeta || {},
    alias: indexsNjs6sABmDMeta?.alias || [],
    redirect: indexsNjs6sABmDMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/sub/[id]/network-diagram/index.vue").then(m => m.default || m)
  },
  {
    name: useSvgAUqh1fCWW8Meta?.name ?? "schedule-project_id-plan-sub-id-network-diagram-useSvg",
    path: useSvgAUqh1fCWW8Meta?.path ?? "network-diagram/useSvg",
    meta: useSvgAUqh1fCWW8Meta || {},
    alias: useSvgAUqh1fCWW8Meta?.alias || [],
    redirect: useSvgAUqh1fCWW8Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/sub/[id]/network-diagram/useSvg.ts").then(m => m.default || m)
  },
  {
    name: key_45worksPhukVEBIfUMeta?.name ?? "schedule-project_id-plan-sub-id-overview-components-key-works",
    path: key_45worksPhukVEBIfUMeta?.path ?? "overview/components/key-works",
    meta: key_45worksPhukVEBIfUMeta || {},
    alias: key_45worksPhukVEBIfUMeta?.alias || [],
    redirect: key_45worksPhukVEBIfUMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/sub/[id]/overview/components/key-works.vue").then(m => m.default || m)
  },
  {
    name: indextwzg7yjAvFMeta?.name ?? "schedule-project_id-plan-sub-id-overview",
    path: indextwzg7yjAvFMeta?.path ?? "overview",
    meta: indextwzg7yjAvFMeta || {},
    alias: indextwzg7yjAvFMeta?.alias || [],
    redirect: indextwzg7yjAvFMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/sub/[id]/overview/index.vue").then(m => m.default || m)
  },
  {
    name: indexzttgK3giNcMeta?.name ?? "schedule-project_id-plan-sub-id-worksheet",
    path: indexzttgK3giNcMeta?.path ?? "worksheet",
    meta: indexzttgK3giNcMeta || {},
    alias: indexzttgK3giNcMeta?.alias || [],
    redirect: indexzttgK3giNcMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/sub/[id]/worksheet/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexmJQ99hclFGMeta?.name ?? "schedule-project_id-plan-sub",
    path: indexmJQ99hclFGMeta?.path ?? "/schedule/:project_id()/plan/sub",
    meta: indexmJQ99hclFGMeta || {},
    alias: indexmJQ99hclFGMeta?.alias || [],
    redirect: indexmJQ99hclFGMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/plan/sub/index.vue").then(m => m.default || m)
  },
  {
    name: indexfwGAzhHzYiMeta?.name ?? "schedule-project_id-tracking-duration-id",
    path: indexfwGAzhHzYiMeta?.path ?? "/schedule/:project_id()/tracking/duration/:id()",
    meta: indexfwGAzhHzYiMeta || {},
    alias: indexfwGAzhHzYiMeta?.alias || [],
    redirect: indexfwGAzhHzYiMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/tracking/duration/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfAUIIFNP3EMeta?.name ?? "schedule-project_id-tracking-duration",
    path: indexfAUIIFNP3EMeta?.path ?? "/schedule/:project_id()/tracking/duration",
    meta: indexfAUIIFNP3EMeta || {},
    alias: indexfAUIIFNP3EMeta?.alias || [],
    redirect: indexfAUIIFNP3EMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/tracking/duration/index.vue").then(m => m.default || m)
  },
  {
    name: indexRUi4M8Z7N4Meta?.name ?? "schedule-project_id-tracking-entity-id",
    path: indexRUi4M8Z7N4Meta?.path ?? "/schedule/:project_id()/tracking/entity/:id()",
    meta: indexRUi4M8Z7N4Meta || {},
    alias: indexRUi4M8Z7N4Meta?.alias || [],
    redirect: indexRUi4M8Z7N4Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/tracking/entity/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index5iWO92aSmyMeta?.name ?? "schedule-project_id-tracking-entity",
    path: index5iWO92aSmyMeta?.path ?? "/schedule/:project_id()/tracking/entity",
    meta: index5iWO92aSmyMeta || {},
    alias: index5iWO92aSmyMeta?.alias || [],
    redirect: index5iWO92aSmyMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/tracking/entity/index.vue").then(m => m.default || m)
  },
  {
    name: indexF7l6FixP1aMeta?.name ?? "schedule-project_id-tracking-figure",
    path: indexF7l6FixP1aMeta?.path ?? "/schedule/:project_id()/tracking/figure",
    meta: indexF7l6FixP1aMeta || {},
    alias: indexF7l6FixP1aMeta?.alias || [],
    redirect: indexF7l6FixP1aMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/[project_id]/tracking/figure/index.vue").then(m => m.default || m)
  },
  {
    name: indexeh40yxGtZeMeta?.name ?? "schedule",
    path: indexeh40yxGtZeMeta?.path ?? "/schedule",
    meta: indexeh40yxGtZeMeta || {},
    alias: indexeh40yxGtZeMeta?.alias || [],
    redirect: indexeh40yxGtZeMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93g49J2XYhdhMeta?.name ?? "schedule-todos-id",
    path: _91id_93g49J2XYhdhMeta?.path ?? "/schedule/todos/:id()",
    meta: _91id_93g49J2XYhdhMeta || {},
    alias: _91id_93g49J2XYhdhMeta?.alias || [],
    redirect: _91id_93g49J2XYhdhMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/todos/[id].vue").then(m => m.default || m)
  },
  {
    name: indexfgwniqZ8SxMeta?.name ?? "schedule-todos",
    path: indexfgwniqZ8SxMeta?.path ?? "/schedule/todos",
    meta: indexfgwniqZ8SxMeta || {},
    alias: indexfgwniqZ8SxMeta?.alias || [],
    redirect: indexfgwniqZ8SxMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/schedule/pages/schedule/todos/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93uEd0t9bMtYMeta?.name ?? "standard-delivery-id",
    path: _91id_93uEd0t9bMtYMeta?.path ?? "/standard/delivery/:id()",
    meta: _91id_93uEd0t9bMtYMeta || {},
    alias: _91id_93uEd0t9bMtYMeta?.alias || [],
    redirect: _91id_93uEd0t9bMtYMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/standard/pages/standard/delivery/[id].vue").then(m => m.default || m),
    children: [
  {
    name: entitycheckXqDtYEKISLMeta?.name ?? "standard-delivery-id-entitycheck",
    path: entitycheckXqDtYEKISLMeta?.path ?? "entitycheck",
    meta: entitycheckXqDtYEKISLMeta || {},
    alias: entitycheckXqDtYEKISLMeta?.alias || [],
    redirect: entitycheckXqDtYEKISLMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/standard/pages/standard/delivery/[id]/entitycheck.vue").then(m => m.default || m)
  },
  {
    name: geometrycheckXBGD6vH5JbMeta?.name ?? "standard-delivery-id-geometrycheck",
    path: geometrycheckXBGD6vH5JbMeta?.path ?? "geometrycheck",
    meta: geometrycheckXBGD6vH5JbMeta || {},
    alias: geometrycheckXBGD6vH5JbMeta?.alias || [],
    redirect: geometrycheckXBGD6vH5JbMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/standard/pages/standard/delivery/[id]/geometrycheck.vue").then(m => m.default || m)
  },
  {
    name: materialcheckQzHICoaweUMeta?.name ?? "standard-delivery-id-materialcheck",
    path: materialcheckQzHICoaweUMeta?.path ?? "materialcheck",
    meta: materialcheckQzHICoaweUMeta || {},
    alias: materialcheckQzHICoaweUMeta?.alias || [],
    redirect: materialcheckQzHICoaweUMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/standard/pages/standard/delivery/[id]/materialcheck.vue").then(m => m.default || m)
  },
  {
    name: propertycheckOLIEMh1pfYMeta?.name ?? "standard-delivery-id-propertycheck",
    path: propertycheckOLIEMh1pfYMeta?.path ?? "propertycheck",
    meta: propertycheckOLIEMh1pfYMeta || {},
    alias: propertycheckOLIEMh1pfYMeta?.alias || [],
    redirect: propertycheckOLIEMh1pfYMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/standard/pages/standard/delivery/[id]/propertycheck.vue").then(m => m.default || m)
  },
  {
    name: quantitycheck1CNZMMPirqMeta?.name ?? "standard-delivery-id-quantitycheck",
    path: quantitycheck1CNZMMPirqMeta?.path ?? "quantitycheck",
    meta: quantitycheck1CNZMMPirqMeta || {},
    alias: quantitycheck1CNZMMPirqMeta?.alias || [],
    redirect: quantitycheck1CNZMMPirqMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/standard/pages/standard/delivery/[id]/quantitycheck.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexLiyHml1yyIMeta?.name ?? "standard-delivery",
    path: indexLiyHml1yyIMeta?.path ?? "/standard/delivery",
    meta: indexLiyHml1yyIMeta || {},
    alias: indexLiyHml1yyIMeta?.alias || [],
    redirect: indexLiyHml1yyIMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/standard/pages/standard/delivery/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93hLrLpaMKGtMeta?.name ?? "standard-dictionary-id",
    path: _91id_93hLrLpaMKGtMeta?.path ?? "/standard/dictionary/:id()",
    meta: _91id_93hLrLpaMKGtMeta || {},
    alias: _91id_93hLrLpaMKGtMeta?.alias || [],
    redirect: _91id_93hLrLpaMKGtMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/standard/pages/standard/dictionary/[id].vue").then(m => m.default || m)
  },
  {
    name: indexF5Fzu1lflgMeta?.name ?? "standard-dictionary",
    path: indexF5Fzu1lflgMeta?.path ?? "/standard/dictionary",
    meta: indexF5Fzu1lflgMeta || {},
    alias: indexF5Fzu1lflgMeta?.alias || [],
    redirect: indexF5Fzu1lflgMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/standard/pages/standard/dictionary/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93mfZDUKlEz4Meta?.name ?? "standard-schema-id",
    path: _91id_93mfZDUKlEz4Meta?.path ?? "/standard/schema/:id()",
    meta: _91id_93mfZDUKlEz4Meta || {},
    alias: _91id_93mfZDUKlEz4Meta?.alias || [],
    redirect: _91id_93mfZDUKlEz4Meta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/standard/pages/standard/schema/[id].vue").then(m => m.default || m),
    children: [
  {
    name: entitynpndutOHNwMeta?.name ?? "standard-schema-id-entity",
    path: entitynpndutOHNwMeta?.path ?? "entity",
    meta: entitynpndutOHNwMeta || {},
    alias: entitynpndutOHNwMeta?.alias || [],
    redirect: entitynpndutOHNwMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/standard/pages/standard/schema/[id]/entity.vue").then(m => m.default || m)
  },
  {
    name: enumpGUMI1aZoEMeta?.name ?? "standard-schema-id-enum",
    path: enumpGUMI1aZoEMeta?.path ?? "enum",
    meta: enumpGUMI1aZoEMeta || {},
    alias: enumpGUMI1aZoEMeta?.alias || [],
    redirect: enumpGUMI1aZoEMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/standard/pages/standard/schema/[id]/enum.vue").then(m => m.default || m)
  },
  {
    name: pset9LIkgSTwIbMeta?.name ?? "standard-schema-id-pset",
    path: pset9LIkgSTwIbMeta?.path ?? "pset",
    meta: pset9LIkgSTwIbMeta || {},
    alias: pset9LIkgSTwIbMeta?.alias || [],
    redirect: pset9LIkgSTwIbMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/standard/pages/standard/schema/[id]/pset.vue").then(m => m.default || m)
  },
  {
    name: qsetR0A0EFW1TeMeta?.name ?? "standard-schema-id-qset",
    path: qsetR0A0EFW1TeMeta?.path ?? "qset",
    meta: qsetR0A0EFW1TeMeta || {},
    alias: qsetR0A0EFW1TeMeta?.alias || [],
    redirect: qsetR0A0EFW1TeMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/standard/pages/standard/schema/[id]/qset.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexUhpOMWqAmKMeta?.name ?? "standard-schema",
    path: indexUhpOMWqAmKMeta?.path ?? "/standard/schema",
    meta: indexUhpOMWqAmKMeta || {},
    alias: indexUhpOMWqAmKMeta?.alias || [],
    redirect: indexUhpOMWqAmKMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/standard/pages/standard/schema/index.vue").then(m => m.default || m)
  },
  {
    name: trpcYbj9JdqGAiMeta?.name ?? "trpc",
    path: trpcYbj9JdqGAiMeta?.path ?? "/trpc",
    meta: trpcYbj9JdqGAiMeta || {},
    alias: trpcYbj9JdqGAiMeta?.alias || [],
    redirect: trpcYbj9JdqGAiMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/framework/pages/trpc.vue").then(m => m.default || m)
  },
  {
    name: index6o4HiqhdkMMeta?.name ?? "user-info",
    path: index6o4HiqhdkMMeta?.path ?? "/user/info",
    meta: index6o4HiqhdkMMeta || {},
    alias: index6o4HiqhdkMMeta?.alias || [],
    redirect: index6o4HiqhdkMMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/user-space/pages/user/info/index.vue").then(m => m.default || m)
  },
  {
    name: indexJFao6HtQQmMeta?.name ?? "user-security",
    path: indexJFao6HtQQmMeta?.path ?? "/user/security",
    meta: indexJFao6HtQQmMeta || {},
    alias: indexJFao6HtQQmMeta?.alias || [],
    redirect: indexJFao6HtQQmMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/user-space/pages/user/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexTjaYmQMRaiMeta?.name ?? "yp231519",
    path: indexTjaYmQMRaiMeta?.path ?? "/yp231519",
    meta: indexTjaYmQMRaiMeta || {},
    alias: indexTjaYmQMRaiMeta?.alias || [],
    redirect: indexTjaYmQMRaiMeta?.redirect,
    component: () => import("/root/workspace/webspace_cloud/modules/yp231519/pages/yp231519/index.vue").then(m => m.default || m)
  }
]