import { useRuntimeConfig } from '#imports'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  // 是否是本地文件存储模式
  return {
    provide: {
      // 获取当前默认的存储模式
      getDefaultStorageDriver: () => {
        return config.public.storageDriver;
      },
    }
  }
})
