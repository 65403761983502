<script setup lang="ts">
import { onMounted, onServerPrefetch, useFetch, useRuntimeConfig, useState } from '#imports'

const config = useRuntimeConfig()

const globalConfig = useState('global-config')

onServerPrefetch(async () => {
  // 在服务端预取到当前组织的站点配置信息
  if (!globalConfig.value || !globalConfig.value.oid) {
    const { data } = await useFetch('/api/organizations/setting')
    if (data)
      globalConfig.value = data?.value?.data || {}
  }
})

onMounted(async () => {
  // 直接在配置中设置环境变量，编译后信息丢失，改为动态更新站点title
  document.title = globalConfig?.value?.browser?.title || globalConfig?.value?.main?.brand_name || config.public.site.name
  const setIcon = () => {
    const link = document.querySelector('link[rel*=\'icon\']') || document.createElement('link') as any
    link.type = 'image/x-icon'
    link.rel = 'shortcut icon'
    link.href = globalConfig?.value?.browser?.icon || `/${config.public.appName}.ico`
    document.getElementsByTagName('head')[0].appendChild(link)
  }
  setIcon()
})
</script>

<template>
<NuxtLayout>
  <NuxtLoadingIndicator :color="globalConfig?.main?.brand_color || 'var(--td-brand-color)'" />
  <NuxtPage />
</NuxtLayout>
</template>

<style>
.geoscene-ui-corner {
  align-items: center;
  /* transform:rotateZ('angle') */
}

:root {
  --bg-color-scroll: rgba(0, 0, 0, 0.14);
  --bg-color-page-w: #f5f5f5;
  --bg-color-page: #fff;
  /* TDesign默认圆角 */
  --td-radius-medium: 0px;
  --td-radius-large: 0px;
}

/* 全局修改滚动条样式 */
::-webkit-scrollbar {
  /*滚动条整体样式*/
  /*高宽分别对应横竖滚动条的尺寸*/
  width: 10px;
  height: 10px;
  background: transparent;

}

::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 8px;
  border: 2px solid transparent;
  background-clip: content-box;
  /* background-color: var(--bg-color-scroll); */
}

div:hover::-webkit-scrollbar-thumb {
  background-color: var(--bg-color-scroll);
}

::-webkit-scrollbar-corner {
  width: 0;
}

::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  background: var(--bg-color-page);
}

/*最外层滚动条样式*/
html::-webkit-scrollbar,
body::-webkit-scrollbar,
.outer-scrollbar::-webkit-scrollbar {
  /*滚动条整体样式*/
  /*高宽分别对应横竖滚动条的尺寸*/
  width: 16px;
  height: 16px;
  background: transparent;

}

html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb,
.outer-scrollbar::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 8px;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: var(--bg-color-scroll);
}

html::-webkit-scrollbar-corner,
body::-webkit-scrollbar-corner,
.outer-scrollbar::-webkit-scrollbar-corner {
  width: 0;
}

html::-webkit-scrollbar-track,
body::-webkit-scrollbar-track,
.outer-scrollbar::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  background: var(--bg-color-page-w);
}
</style>
