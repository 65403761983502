// Notice: Icon从这里找：https://heroicons.com/
import {
  HomeIcon,
  UserGroupIcon,
  Cog8ToothIcon,
} from '@heroicons/vue/24/outline'
import { defineAppConfig } from '#imports'

// 记录下一代Nav结构
export type Nav = {
  name: string
  path: string
  meta: {
    title: string
    icon?: string | typeof HomeIcon
    // 配置识别菜单激活的匹配正则
    match?: string[] | undefined
  },
  children?: Nav[]
}

export default defineAppConfig({
  myLayer: {
    name: 'pm'
  },
  mainMenu: {
    pm: {
      expanded: ['pm-admin-project', 'pm-admin-collaboration', 'pm-admin-setting'],
      navigation: [
        {
          path: '/pm-1', name: 'pm-admin-project', meta: { title: '项目管理', icon: HomeIcon },
          children: [
            { path: '/pm/admin/project', name: 'pm-admin-project', meta: { title: '全部项目' } },
            { path: '/pm/admin/project/star', name: 'pm-admin-project-star', meta: { title: '关注项目' } },
          ],
        },
        {
          path: '/pm-2', name: 'pm-admin-collaboration', meta: { title: '项目协同', icon: UserGroupIcon },
          children: [
            { path: '/pm/admin/collaboration/org', name: 'pm-admin-collaboration-org', meta: { title: '往来组织' } },
            { path: '/pm/admin/collaboration/user', name: 'pm-admin-collaboration-user', meta: { title: '往来人员' } },
          ],
        },
        {
          path: '/pm-3', name: 'pm-admin-setting', meta: { title: '项目设置', icon: Cog8ToothIcon },
          children: [
            { path: '/pm/admin/setting/user', name: 'pm-setting-user', meta: { title: '项目成员' } },
            { path: '/pm/admin/setting/group', name: 'pm-setting-group', meta: { title: '项目分组' } },
          ],
        },
      ]
    }
  }
})

declare module '@nuxt/schema' {
  interface AppConfigInput {
    myLayer?: {
      /** Module name */
      name?: string
    },
    mainMenu?: {
      // Key为当前模块标识名
     [key: string]: {
       // 默认展开的菜单项，其值为
       expanded: string[],
       // 导航定义
       navigation?: Nav[]
     }
    },
  }
}
