// 对svg 进行缩放

import { MessagePlugin } from "tdesign-vue-next";
import XEUtils from "xe-utils";

// ref doc: https://blog.csdn.net/weixin_30851409/article/details/99649767?spm=1001.2101.3001.6661.1&utm_medium=distribute.pc_relevant_t0.none-task-blog-2%7Edefault%7ECTRLIST%7Edefault-1.pc_relevant_default&depth_1-utm_source=distribute.pc_relevant_t0.none-task-blog-2%7Edefault%7ECTRLIST%7Edefault-1.pc_relevant_default&utm_relevant_index=1
export interface ScaleOpt {
  scaleW: number
  scaleH: number
}
export function scale(el: string, opt: ScaleOpt) {
  opt = opt || {}
  const svg = document.getElementById(el)

  if (!svg) {
    throw new Error('未找到该svg');
  }

  const originWidth = svg.getAttribute('width');
  const originHeight = svg.getAttribute('height');

  svg.setAttribute(
    "transform",
    `translate(${originWidth * (opt.scaleW - 1) / 2}, ${originHeight * (opt.scaleH - 1) / 2}) scale(${opt.scaleW}, ${opt.scaleH})`
  );
}

// 转为png图片并下载
// notice svg需要加属性 xmlns="http://www.w3.org/2000/svg"， 否则不执行onload
export function saveImage(el: string, opt: ScaleOpt) {
  let svg = document.getElementById(el);
  let svghtml = svg?.innerHTML;
  // let width = svg.getAttribute('width') * opt.scaleH
  // let height = svg.getAttribute('height') * opt.scaleH

  let scaleValue = 1.7
  let width = svg.getAttribute('width') * scaleValue
  let height = svg.getAttribute('height') * scaleValue
  let innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" style="background-color:white" id="svg" width="` + width + `" height="` + height + `" transform-origin="center">` + svghtml + `</svg>`
  const base64 = `data:image/svg+xml;base64,${window.btoa(unescape(encodeURIComponent(innerHTML)))}`;

  let img = new Image();

  img.src = base64;

  img.onload = () => {
    let canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    let context = canvas.getContext('2d');
    // context.drawImage(img, 0, 0, width * opt.scaleH, height * opt.scaleH);
    context.drawImage(img, 0, 0, width * scaleValue, height * scaleValue);
    let url = canvas.toDataURL('image/png');
    let a = document.createElement('a');
    a.href = url;
    a.download = '网络图.png';
    a.click();
  }
}

let criticalNo = 0
let criticalTemp = 0
let criticalFlagTemp = ''
let objDiv: any = null
let criticalChild: any = null

//重置网络图关键路径的相关信息
export const resetCritical = () => {
  console.log('resetCritical...')
  objDiv = null
  criticalChild = null
}

//初始化数据
const initCritical = (criticalFlag: string) => {
  console.log('initCritical...')
  let criticalElem: any = document.getElementById("AOACPTask")
  if (criticalElem && criticalElem.children) {
    let criticalIndex = XEUtils.findIndexOf(criticalElem.children, item => item.id == criticalFlag)
    if (criticalIndex) {
      criticalChild = criticalElem.children[criticalIndex]

      criticalNo = 0
      criticalTemp = 0
    }
  }
  criticalFlagTemp = criticalFlag
}

// 显示关键路径.通过getBBox获取到元素在svg中的坐标值，计算出比例，然后和滚动条高度相乘，得出滚动条的偏移量
export const showCritical = (svgScaleW: any, svgScaleH: any, criticalFlag: string) => {
  if (!objDiv) {//如果初次执行，先获取对应元素
    objDiv = document.getElementById("svgDiv");
  }

  if (!criticalChild || criticalFlagTemp != criticalFlag) {
    initCritical(criticalFlag)
  }
  if (objDiv && criticalChild) {//判断svg图和关键路径是否存在
    let criticalChildElem = criticalChild.children
    if (criticalChildElem && criticalChildElem.length > 0) {
      if (criticalChildElem.length > criticalNo) {
        let criticalPath = criticalChildElem[criticalNo]
        if (criticalPath && criticalPath.getAttribute('id') && criticalTemp != criticalPath.getAttribute('id') && criticalPath.getBBox()) {
          objDiv.scrollLeft = criticalPath.getBBox().x * svgScaleW;
          objDiv.scrollTop = criticalPath.getBBox().y * svgScaleH;
          if (criticalChildElem.length > 1) {
            criticalTemp = criticalPath.getAttribute('id')
          }
        } else {
          criticalNo++
          showCritical(svgScaleW, svgScaleH, criticalFlag)
        }
      } else {
        criticalNo = 0
        showCritical(svgScaleW, svgScaleH, criticalFlag)
      }

    }
    criticalNo++
  } else {
    MessagePlugin.info('当前网络图没有关键路径')
  }
}

export interface ScrollOpt {
  svgId: string
  headerId: string
  leftId: string
  svgScaleH: number
  svgScaleW: number
}

// 滚动后，题头和左侧固定
export const scrollSvg = (opt: ScrollOpt) => {
  let objDiv = document.getElementById(opt.svgId);

  let AOAAxis = document.getElementById(opt.headerId);
  AOAAxis?.setAttribute(
    "transform",
    //@ts-ignore
    `translate(0, ${objDiv?.scrollTop / opt.svgScaleH}) `
  );

  let AOAGroupleft = document.getElementById(opt.leftId);
  AOAGroupleft?.setAttribute(
    "transform",
    //@ts-ignore
    `translate(${objDiv?.scrollLeft / opt.svgScaleW}, 100) `
  );
}

type ViewBoxScaleOpt = {
  el: HTMLElement
  mode: 'zoomIn' | 'zoomOut'
}

export const viewBoxScale = (opt: ViewBoxScaleOpt) => {
  const { el, mode } = opt
  const svgDom = el.firstChild as SVGSVGElement;
  if (!svgDom) return
  const viewBox = svgDom.getAttribute("viewBox") as string;
  let x = 0,
    y = 0,
    width = Number(svgDom.getAttribute("width")),
    height = Number(svgDom.getAttribute("height"))

  if (viewBox) {
    [x, y, width, height] = viewBox.split(/\s+/).map(parseFloat);
  }

  // zoom scale
  const scaleDelta = mode === 'zoomIn' ? 0.9 : 1.1;
  const newWidth = width * scaleDelta;
  const newHeight = height * scaleDelta;

  // new center
  const dx = (width - newWidth) / 2;
  const dy = (height - newHeight) / 2;
  const newViewBox = `${x + dx} ${y + dy} ${newWidth} ${newHeight}`;
  svgDom.setAttribute("viewBox", newViewBox);
}

type WheelOpt = {
  el: HTMLElement
}
// 通过viewBox属性进行svg的缩放
export const wheel = (opt: WheelOpt) => {
  const { el } = opt
  // TODO 这里的svg会在服务端设置width，height属性，导致其无法全部占满可视区域，因此我们读取其父级元素的宽高来设置给它
  // const svgDom = el.firstChild as SVGSVGElement;
  // svgDom.setAttribute("width", String(el.clientWidth))
  // svgDom.setAttribute("height", String(el.clientHeight))

  el.onwheel = (e: WheelEvent) => {
    e.preventDefault()
    const svgDom = el.firstChild as SVGSVGElement;
    if (!svgDom) return
    const viewBox = svgDom.getAttribute("viewBox") as string;
    let x = 0,
      y = 0,
      width = Number(svgDom.getAttribute("width")),
      height = Number(svgDom.getAttribute("height"))

    if (viewBox) {
      [x, y, width, height] = viewBox.split(/\s+/).map(parseFloat);
    }

    // zoom scale
    const scaleDelta = e.deltaY > 0 ? 0.9 : 1.1;
    const newWidth = width * scaleDelta;
    const newHeight = height * scaleDelta;

    // new center
    const dx = (width - newWidth) / 2;
    const dy = (height - newHeight) / 2;
    const newViewBox = `${x + dx} ${y + dy} ${newWidth} ${newHeight}`;
    svgDom.setAttribute("viewBox", newViewBox);
  }
}

type DragOpt = {
  el: HTMLElement
}
// 拖拽处理
export function drag(opt: DragOpt) {
  const { el } = opt
  let clientX = 0;
  let clientY = 0;
  let debounce = true;
  let isStartMoveSvg = false;
  let ratio = 1;
  let svgDom: SVGAElement;
  let viewBox: string;
  let arrPoint: number[];

  el.onmousedown = () => {
    isStartMoveSvg = true;
    const width = el.getBoundingClientRect().width;
    svgDom = el.firstChild as SVGAElement;
    if (!svgDom) return
    viewBox = svgDom.getAttribute("viewBox") as string;

    arrPoint = [0, 0, Number(svgDom.getAttribute("width")), Number(svgDom.getAttribute("height"))]
    if (viewBox) {
      arrPoint = viewBox.split(/\s+/).map(parseFloat);
    }

    ratio = arrPoint[2] / width;
    if (ratio < 1) ratio = 1;
  };

  // End moving
  el.onmouseup = () => {
    isStartMoveSvg = false;
    clientX = 0;
    clientY = 0;
  };

  // 动态设置 "viewBox" while moving
  el.onmousemove = (e: MouseEvent) => {
    if (debounce) {
      debounce = false;
      if (isStartMoveSvg) {
        if (clientX !== 0 && clientY !== 0) {
          arrPoint[0] = arrPoint[0] - (e.clientX - clientX) * ratio;
          arrPoint[1] = arrPoint[1] - (e.clientY - clientY) * ratio;
          svgDom.setAttribute("viewBox", arrPoint.join(" "));
        }
        clientX = e.clientX;
        clientY = e.clientY;
      }
      setTimeout(() => {
        debounce = true;
      }, 50);
    }
  };
}