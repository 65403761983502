
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "30f25383-e153-468c-bb71-b364a99e50dc"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/root/workspace/webspace_cloud/apps/platform/app.config.ts"
import cfg1 from "/root/workspace/webspace_cloud/modules/organization/app.config.ts"
import cfg2 from "/root/workspace/webspace_cloud/modules/portal/app.config.ts"
import cfg3 from "/root/workspace/webspace_cloud/modules/om/app.config.ts"
import cfg4 from "/root/workspace/webspace_cloud/modules/yp231519/app.config.ts"
import cfg5 from "/root/workspace/webspace_cloud/modules/catalog/app.config.ts"
import cfg6 from "/root/workspace/webspace_cloud/modules/pm/app.config.ts"
import cfg7 from "/root/workspace/webspace_cloud/modules/user-space/app.config.ts"
import cfg8 from "/root/workspace/webspace_cloud/modules/project/app.config.ts"
import cfg9 from "/root/workspace/webspace_cloud/modules/project-org/app.config.ts"
import cfg10 from "/root/workspace/webspace_cloud/modules/bim/app.config.ts"
import cfg11 from "/root/workspace/webspace_cloud/modules/render/app.config.ts"
import cfg12 from "/root/workspace/webspace_cloud/modules/schedule/app.config.ts"
import cfg13 from "/root/workspace/webspace_cloud/modules/catalog/app.config.ts"
import cfg14 from "/root/workspace/webspace_cloud/modules/bom/app.config.ts"
import cfg15 from "/root/workspace/webspace_cloud/modules/flow/app.config.ts"
import cfg16 from "/root/workspace/webspace_cloud/modules/lifecycle/app.config.ts"
import cfg17 from "/root/workspace/webspace_cloud/modules/standard/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, cfg1, cfg2, cfg3, cfg4, cfg5, cfg6, cfg7, cfg8, cfg9, cfg10, cfg11, cfg12, cfg13, cfg14, cfg15, cfg16, cfg17, inlineConfig)
