import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  CubeIcon,
  PencilIcon
} from '@heroicons/vue/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/vue/20/solid'
import { defineAppConfig } from '#imports'
export type Nav = {
  name: string,
  href: string,
  icon: unknown,
  current: boolean,
  type?: string,
  id?: string | number,
}

export default defineAppConfig({
  myLayer: {
    name: 'bom'
  },
  myMenus: {
    navigation: [
    ],
    teams: [
    ],
    userNav: [
      // { name: 'Your profile', href: '#', type: "jump" },
      { name: '登出', href: '#', type: 'click' },
    ]
  }
})

declare module '@nuxt/schema' {
  interface AppConfigInput {
    myLayer?: {
      /** Project name */
      name?: string
    },
    myMenus?: {
      navigation?: Nav[],
      team?: Nav[],
      userNav?: Nav[],
    }
  }
}
