import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  CubeIcon,
  PencilIcon
} from '@heroicons/vue/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/vue/20/solid'
import { defineAppConfig } from '#imports'
export type Nav = {
  name: string,
  href: string,
  icon: unknown,
  current: boolean,
  type?: string,
  id?: string | number,
}

export default defineAppConfig({
  myLayer: {
    name: 'Hello from Nuxt layer'
  },
  myMenus: {
    navigation: [
      // { name: 'Dashboard', href: '#', icon: HomeIcon, current: true },
      // { name: '编目查看', href: '/yp30036/catalog/list', icon: FolderIcon, current: false },
      // { name: '编目编辑', href: '/yp30036/catalog/edit', icon: PencilIcon, current: false },
      // { name: '模型管理', href: '/admin/model', icon: CubeIcon, current: false },
      // { name: 'Team', href: '#', icon: UsersIcon, current: false },
      // { name: 'Projects', href: '#', icon: FolderIcon, current: false },
      // { name: 'Calendar', href: '#', icon: CalendarIcon, current: false },
      // { name: 'Documents', href: '#', icon: DocumentDuplicateIcon, current: false },
      // { name: 'Reports', href: '#', icon: ChartPieIcon, current: false },
    ],
    teams: [
      // { id: 1, name: 'Heroicons', href: '#', initial: 'H', current: false },
      // { id: 2, name: 'Tailwind Labs', href: '#', initial: 'T', current: false },
      // { id: 3, name: 'Workcation', href: '#', initial: 'W', current: false },
    ],
    userNav: [
      // { name: 'Your profile', href: '#', type: "jump" },
      { name: '登出', href: '#', type: 'click' },
    ]
  }
})

declare module '@nuxt/schema' {
  interface AppConfigInput {
    myLayer?: {
      /** Project name */
      name?: string
    },
    myMenus?: {
      navigation?: Nav[],
      team?: Nav[],
      userNav?: Nav[],
    }
  }
}
