// Notice: Icon从这里找：https://heroicons.com/
import {
  HomeModernIcon,
  DocumentMagnifyingGlassIcon,
  ArrowsRightLeftIcon,
  CircleStackIcon,
  CheckBadgeIcon,
  WrenchScrewdriverIcon,
  Bars3BottomRightIcon,
  ScaleIcon,
  PresentationChartLineIcon,
} from '@heroicons/vue/24/outline'
import { defineAppConfig } from '#imports'

export type Nav = {
  name: string
  path: string
  meta: {
    title?: string
    icon?: string | typeof HomeModernIcon
    // 配置识别菜单激活的匹配正则
    match?: string[] | undefined
  },
  children?: Nav[]
}
export default defineAppConfig({
  myLayer: {
    name: 'bim'
  },
  mainMenu: {
    'bim': {
      expanded: ['bim-project_id-overview', 'bim-project_id-graphic', 'bim-project_id-collection', 'bim-project_id-explore', 'bim-project_id-converter', 'bim-project_id-checker', 'bim-project_id-catalog', 'bim-project_id-analyze', 'bim-project_id-process'],
      navigation: [
        { path: '/bim/:project_id/overview', name: 'bim-project_id-overview', meta: { title: '资产大屏', icon: PresentationChartLineIcon } },
        {
          path: '/bim/:project_id/collection', name: 'bim-project_id-collection', meta: { title: '数据归集', icon: CircleStackIcon },
          children: [
            { path: '/bim/:project_id/collection/model', name: 'bim-project_id-collection-model', meta: { title: '模型归集' } },
            { path: '/bim/:project_id/collection/component', name: 'bim-project_id-collection-component', meta: { title: '构件归集' } },
          ],
        },
        {
          path: '/bim/:project_id/graphic', name: 'bim-project_id-graphic', meta: { title: '数据归集', icon: CircleStackIcon },
          children: [
            { path: '/bim/:project_id/graphic/model', name: 'bim-project_id-graphic-model', meta: { title: '模型归集' } },
          ],
        },
        {
          path: '/bim/:project_id/explore', name: 'bim-project_id-explore', meta: { title: '数据检索', icon: DocumentMagnifyingGlassIcon },
          children: [
            { path: '/bim/:project_id/explore/model', name: 'bim-project_id-explore-model', meta: { title: '模型查阅' } },
            { path: '/bim/:project_id/explore/component', name: 'bim-project_id-explore-component', meta: { title: '构件查阅' } },
            { path: '/bim/:project_id/explore/image', name: 'bim-project_id-explore-image', meta: { title: '以图搜图' } },
          ],
        },
        {
          path: '/bim/:project_id/converter', name: 'bim-project_id-converter', meta: { title: '数据转换', icon: ArrowsRightLeftIcon },
          children: [
            { path: '/bim/:project_id/converter/format', name: 'bim-project_id-converter-format', meta: { title: '格式转换' } },
            { path: '/bim/:project_id/converter/coordinate', name: 'bim-project_id-converter-coordinate', meta: { title: '坐标转换' } },
          ],
        },
        {
          path: '/bim/:project_id/checker', name: 'bim-project_id-checker', meta: { title: '数据验证', icon: CheckBadgeIcon },
          children: [
            { path: '/bim/:project_id/checker/quality', name: 'bim-project_id-checker-quality-list', meta: { title: '质量检查' } },
            { path: '/bim/:project_id/checker/topology', name: 'bim-project_id-checker-topology', meta: { title: '拓扑检查' } },
          ],
        },
        {
          path: '/bim/:project_id/process', name: 'bim-project_id-process', meta: { title: '数据加工', icon: WrenchScrewdriverIcon },
          children: [
            { path: '/bim/:project_id/process/merge', name: 'bim-project_id-process-merge', meta: { title: '模型合并' } },
            { path: '/bim/:project_id/process/split', name: 'bim-project_id-process-split', meta: { title: '模型提取' } },
          ],
        },
        {
          path: '/bim/:project_id/analyze', name: 'bim-project_id-analyze', meta: { title: '数据分析', icon: ScaleIcon },
          children: [
            { path: '/bim/:project_id/analyze/graph', name: 'bim-project_id-analyze-graph', meta: { title: '知识图谱' } },
            { path: '/bim/:project_id/analyze/diff', name: 'bim-project_id-analyze-diff', meta: { title: '模型比对' } },
            { path: '/bim/:project_id/analyze/trace', name: 'bim-project_id-analyze-trace', meta: { title: '模型溯源' } },
            { path: '/bim/:project_id/analyze/collision', name: 'bim-project_id-analyze-collision', meta: { title: '碰撞检测' } },
          ],
        },
        {
          path: '/bim/:project_id/catalog', name: 'bim-project_id-catalog', meta: { title: '数据编目', icon: Bars3BottomRightIcon },
          children: [
            { path: '/bim/:project_id/catalog/model/edit', name: 'bim-project_id-catalog-model-edit', meta: { title: '模型分类' } },
            { path: '/bim/:project_id/catalog/component', name: 'bim-project_id-catalog-component', meta: { title: '构件分类' } },
          ],
        },
      ]
    }
  }
})

declare module '@nuxt/schema' {
  interface AppConfigInput {
    myLayer?: {
      /** module name */
      name?: string
    },
    siteConfig?: {
      name: string,
      enName: string,
      defaultPortal: string
    },
    mainMenu?: {
      // key为模块标识名
      [key: string]: {
        // 默认展开的菜单项，其值为
        expanded?: string[],
        // 导航定义
        navigation?: Nav[]
      }
    },
  }
}
