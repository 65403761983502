// Notice: Icon从这里找：https://heroicons.com/
import type {
  CubeIcon,
} from '@heroicons/vue/24/outline'
import { defineAppConfig } from '#imports'

export interface Nav {
  name: string
  path: string
  meta: {
    title: string
    icon?: string | typeof CubeIcon
    // 配置识别菜单激活的匹配正则
    match?: string[] | RegExp[] | undefined
    key?: string
  }
  children?: Nav[]
}

export default defineAppConfig({
  myLayer: {
    name: 'platform',
  },
  // siteConfig: {
  //   name: '湖北省BIM云数据中心',
  //   enName: 'HuBei BIM Cloud Data Center',
  //   defaultPortal: '/pm/admin/project',
  // },
  mainMenu: {},
})

declare module '@nuxt/schema' {
  interface AppConfigInput {
    myLayer?: {
      /** Project name */
      name?: string
    }
    siteConfig: {
      name?: string
      enName?: string
      defaultPortal?: string
    }
  }
}
