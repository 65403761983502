// Notice: Icon从这里找：https://heroicons.com/
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  CubeIcon,
} from '@heroicons/vue/24/outline'
import { defineAppConfig } from '#imports'


// 记录下一代Nav结构
export type Nav = {
  name: string
  path: string
  meta: {
    title: string
    icon?: string | typeof HomeIcon
    // 配置识别菜单激活的匹配正则
    match?: string[] | undefined
  },
  children?: Nav[]
}

export default defineAppConfig({
  myLayer: {
    name: 'render'
  },
  siteConfig: {
    name: 'render',
    enName: 'render',
    defaultPortal: '/',
  },
  // 新的菜单体系结构, 对应sidebar-next
  mainMenu: {
    'render': {
      expanded: ['/render/scene','/render/scene_control','/render/widget','/render/map','/render/3d','/render/osgb','/render/pc' ,'/render/bim','/render/analyze','/render/effect','/render/light_material'],
      navigation: [
        {
          path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '场景构建', icon: HomeIcon, key: '/render/scene' },
          children: [
            { path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '场景入门', key: '/render/scene/construct' } },
            { path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '场景背景', key: '/render/scene/construct_bg' } },
            { path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '场景参数', key: '/render/scene/construct_param' } },
          ],
        },
        {
          path: '/render/first_catalog/control', name: 'render-first_catalog-control', meta: { title: '场景控制', icon: HomeIcon, key: '/render/scene_control' },
          children: [
            { path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '鼠标交互控制', key: '/render/scene_control/mouse' } },
            { path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '相机及视角控制', key: '/render/scene_control/camera' } },
            { path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '视图切换和对比', key: '/render/scene_control/view' } },
          ],
        },
        {
          path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '控件', icon: HomeIcon, key: '/render/widget' },
          children: [
            { path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '基础控件', key: '/render/widget/basic' } },
            { path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '自定义控件', key: '/render/widget/custom' } },
          ],
        },
        {
          path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '瓦片地图', icon: HomeIcon, key: '/render/map' },
          children: [
            {
              path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '在线地图', key: '/render/map/provider' },
            },
            {
              path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '地图样式', key: '/render/map/style' },
            },
          ],
        },
        {
          path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '三维地形', icon: HomeIcon, key: '/render/3d' },
          children: [
            {
              path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '地形图层', key: '/render/3d/layer' },
            },
            {
              path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '地形编辑', key: '/render/3d/terrain' },
            },
          ],
        },
        {
          path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '倾斜摄影', icon: HomeIcon, key: '/render/osgb' },
          children: [
            {
              path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '倾斜图层', key: '/render/osgb/layer' },
            },
            {
              path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '倾斜单体化', key: '/render/osgb/single' },
            },
          ],
        },
        {
          path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '激光点云', icon: HomeIcon, key: '/render/pc' },
          children: [
            {
              path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '点云图层', key: '/render/pc/layer' },
            },
            {
              path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '点云网格化', key: '/render/pc/mesh' },
            },
          ],
        },
        {
          path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: 'BIM模型', icon: HomeIcon, key: '/render/bim' },
          children: [
            {
              path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: 'BIM图层', key: '/render/bim/layer' },
            },
            { path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '模型控制', key: '/render/bim/control' } },
          ],
        },
        {
          path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '综合分析', icon: HomeIcon, key: '/render/analyze' },
          children: [
            {
              path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '空间分析', key: '/render/analyze/spatial' },
            },
            { path: '/render/a:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '场景分析', key: '/render/analyze/scene' } },
          ],
        },
        {
          path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '特效', icon: HomeIcon, key: '/render/effect' },
          children: [
            {
              path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '对象特效', key: '/render/effect/object' },
            },
            { path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '天气特效', key: '/render/effect/wheather' } },
            { path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '色彩特效', key: '/render/effect/color' } },
          ],
        },
        {
          path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '光照与材质', icon: HomeIcon, key: '/render/light_material' },
          children: [
            {
              path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '光照', key: '/render/light_material/light' },
            },
            { path: '/render/:first_catalog/:second_catalog', name: 'render-first_catalog-second_catalog', meta: { title: '材质', key: '/render/light_material/material' } },
          ],
        },
      ]
    }
  }
})

declare module '@nuxt/schema' {
  interface AppConfigInput {
    myLayer?: {
      /** Project name */
      name?: string
    },
    siteConfig?: {
      name: string,
      enName: string,
      defaultPortal: string
    },
    mainMenu?: {
      // key为模块标识名
      [key: string]: {
        // 默认展开的菜单项，其值为
        expanded?: string[],
        // 导航定义
        navigation?: Nav[]
      }
    },
  }
}
