export interface Credentials {
  accessKeyId: string,
  secretAccessKey: string,
  sessionToken: string,
  endpoint: string,
  bucket: string,
}

export interface S3Client {}

// 天翼云OSS接口实现类， 遵循S3协议
export default class TianyiOSS {
  credentials: Credentials
  bucket: string
  s3Client: S3Client
  upload: null

  init (credentials: Credentials) {
    this.credentials = credentials
    this.bucket = this.credentials.bucket

    this.s3Client = new (window as any).AWS.S3({
      credentials: this.credentials,
      endpoint: this.credentials.endpoint
    })
  }

  // 更新ak,sk和token
  updateCredentials (ak:string, sk:string, token: string) {
    this.credentials.accessKeyId = ak
    this.credentials.secretAccessKey = sk
    this.credentials.sessionToken = token
  }

  listObjects (cb: Function) {
    const params = {
      Bucket: this.bucket
    }
    this.s3Client.listObjects(params, function (err, data) {
      if (err) {
        cb(err, null)
      } else {
        cb(data, null)
      }
    })
  }

  listObjectVersions (cb: Function) {
    const params = {
      Bucket: this.bucket
    }
    this.s3Client.listObjectVersions(params, function (err, data) {
      if (err) {
        cb(null, err)
      } else {
        cb(data, null)
      }
    })
  }

  getObject (key: string, cb: Function) {
    const params = {
      Bucket: this.bucket,
      Key: key
    }
    this.s3Client.getObject(params, function (err, data) {
      if (err) {
        cb(null, err)
      } else {
        // console.log('Success: ', data.Body.toString())
        cb(data, null)
      }
    })
  }

  headObject (key, cb: Function) {
    const params = {
      Bucket: this.bucket,
      Key: key
    }
    this.s3Client.headObject(params, function (err, data) {
      if (err) {
        cb(null, err)
      } else {
        // console.log('Success: ', data.ContentType)
        cb(data, null)
      }
    })
  }

  deleteObject (key: string, cb: Function) {
    const params = {
      Bucket: this.bucket,
      Key: key
    }
    this.s3Client.deleteObject(params, function (err, data) {
      if (err) {
        cb(null, err)
      } else {
        cb(data, null)
      }
    })
  }

  deleteObjects (keys: string[], cb: Function) {
    const params = {
      Bucket: this.bucket,
      Delete: {
        Objects: keys.map((k: string) => {
          return {
            Key: k
          }
        })
      }
    }

    this.s3Client.deleteObjects(params, function (err, data) {
      if (err) {
        cb(null, err)
      } else {
        cb(data, null)
      }
    })
  }

  copyObject (key: string, cb: Function) {
    const source = this.bucket + `/${key}`
    const params = {
      Bucket: this.bucket,
      Key: key,
      CopySource: source
    }
    this.s3Client.copyObject(params, function (err, data) {
      if (err) {
        cb(null, err)
      } else {
        cb(data, null)
      }
    })
  }

  putObject (file, key: string, cb: Function) {
    const params = {
      Bucket: this.bucket,
      Key: key,
      Body: file,
      ACL: 'private' // 初始化acl权限，默认为private，"private"|"public-read"|"public-read-write"
    }
    this.s3Client.putObject(params, function (err, data) {
      if (err) {
        cb(null, err)
      } else {
        cb(data, null)
      }
    })
  }

  multiPartUpload (file, key, cb, progress) {
    this.upload = new AWS.S3.ManagedUpload({
      service: this.s3Client,
      partSize: 5 * 1024 * 1024, // 10M一片，可以根据需要自己定义，每个文件不能超过10000分片
      params: {
        Bucket: this.bucket,
        Key: key,
        Body: file,
        ACL: 'private' // 初始化acl权限，默认为private，"private"|"public-read"|"public-read-write"
        // ContentType: "application/json", // 设置contentType, 默认是application/octet-stream
      }
    })

    this.upload.on('httpUploadProgress', res => progress && progress(res))
    this.upload.send((err, data) => {
      if (err) {
        cb(null, err)
      } else {
        cb(data, null)
      }
    })
  }

  abort () {
    this.upload.abort()
  }

  getObjectAcl (key: string, cb: Function) {
    const params = {
      Bucket: this.bucket,
      Key: key
    }
    this.s3Client.getObjectAcl(params, function (err, data) {
      if (err) {
        cb(null, err)
      } else {
        for (let i = 0; i < data.Grants.length; ++i) {
          console.log(data.Grants[i])
        }
        cb(data, null)
      }
    })
  }

  putObjectAcl1 (key: string, cb: Function) {
    const params = {
      Bucket: this.bucket,
      Key: key,
      ACL: 'private'
    }
    this.s3Client.putObjectAcl(params, function (err, data) {
      if (err) {
        cb(null, err)
      } else {
        cb(data, null)
      }
    })
  }

  putObjectAcl2 (key: string, cb: Function) {
    const newGrant = {
      Grantee: {
        Type: 'Group',
        URI: 'http://acs.amazonaws.com/groups/global/AllUsers'
      },
      Permission: 'WRITE'
    }
    const paramsGetAcl = {
      Bucket: this.bucket,
      Key: key
    }
    this.s3Client.getObjectAcl(paramsGetAcl, (errGetAcl, dataGetAcl) => {
      if (errGetAcl) {
        cb(null, errGetAcl)
        return
      }
      dataGetAcl.Grants.push(newGrant)
      const paramsPutAcl = {
        Bucket: this.bucket,
        Key: key,
        AccessControlPolicy: {
          Grants: dataGetAcl.Grants,
          Owner: dataGetAcl.Owner
        }
      }
      this.s3Client.putObjectAcl(paramsPutAcl, (errPutAcl, dataPutAcl) => {
        if (errPutAcl) {
          cb(null, errPutAcl)
        } else {
          console.log('Success', dataPutAcl)
          cb(dataPutAcl, null)
        }
      })
    })
  }

  putBucketLifecycleConfiguration (cb: Function) {
    const params = {
      Bucket: this.bucket,
      LifecycleConfiguration: {
        Rules: [
          {
            Expiration: {
              Days: 3650
            },
            Filter: { // required
              Prefix: ''
            },
            ID: 'TestOnly',
            Status: 'Enabled', // required
            Transitions: [
              {
                Days: 365,
                StorageClass: 'GLACIER'
              }
            ],
            NoncurrentVersionExpiration: {
              NoncurrentDays: 123
            },
            AbortIncompleteMultipartUpload: {
              DaysAfterInitiation: 123
            }
          }
        ]
      }
    }
    this.s3Client.putBucketLifecycleConfiguration(params, function (err, data) {
      if (err) {
        cb(null, err)
      } else {
        cb(data, null)
      }
    })
  }

  getBucketLifecycleConfiguration (cb: Function) {
    const params = {
      Bucket: this.bucket
    }
    this.s3Client.getBucketLifecycleConfiguration(params, function (err, data) {
      if (err) {
        cb(null, err)
      } else {
        // console.log('Success', data)
        cb(data, null)
      }
    })
  }

  deleteBucketLifecycleConfiguration (cb: Function) {
    const params = {
      Bucket: this.bucket
    }
    this.s3Client.deleteBucketLifecycle(params, function (err, data) {
      if (err) {
        cb(null, err)
      } else {
        cb(data, null)
      }
    })
  }

  putBucketPolicy (cb: Function) {
    const policy = {
      Version: '2012-10-17',
      Statement: [{
        Sid: 'AddPerm',
        Effect: 'Allow',
        Principal: '*',
        Action: [
          's3:GetObject'
        ],
        Resource: [
          'arn:aws:s3:::' + this.bucket + '/*'
        ]
      }]
    }
    const params = {
      Bucket: this.bucket,
      Policy: JSON.stringify(policy)
    }
    this.s3Client.putBucketPolicy(params, function (err, data) {
      if (err) {
        cb(null, err)
      } else {
        cb(data, null)
      }
    })
  }

  getBucketPolicy (cb: Function) {
    const params = {
      Bucket: this.bucket
    }
    this.s3Client.getBucketPolicy(params, function (err, data) {
      if (err) {
        cb(null, err)
      } else {
        cb(data, null)
      }
    })
  }

  // 会跨域
  deleteBucketPolicy (cb: Function) {
    const params = {
      Bucket: this.bucket
    }
    this.s3Client.deleteBucketPolicy(params, function (err, data) {
      if (err) {
        cb(null, err)
      } else {
        cb(data, null)
      }
    })
  }

  // 会跨域
  putBucketCors (cb: Function) {
    const params = {
      Bucket: this.bucket,
      CORSConfiguration: {
        CORSRules: [{
          AllowedHeaders: ['*'],
          AllowedMethods: ['POST', 'GET', 'PUT'],
          AllowedOrigins: ['https://localhost:8080'],
          ExposeHeaders: ['ETag'],
          MaxAgeSeconds: 3600
        }]
      }
    }
    this.s3Client.putBucketCors(params, function (err, data) {
      if (err) {
        cb(null, err)
      } else {
        cb(data, null)
      }
    })
  }

  // 会跨域
  getBucketCors (cb: Function) {
    const params = {
      Bucket: this.bucket
    }
    this.s3Client.getBucketCors(params, function (err, data) {
      if (err) {
        cb(null, err)
      } else {
        cb(data, null)
      }
    })
  }

  // 会跨域
  deleteBucketCors (cb: Function) {
    const params = {
      Bucket: this.bucket
    }
    this.s3Client.deleteBucketCors(params, function (err, data) {
      if (err) {
        cb(null, err)
      } else {
        cb(data, null)
      }
    })
  }

  putBucketVersioning (cb: Function) {
    const params = {
      Bucket: this.bucket,
      VersioningConfiguration: {
        Status: 'Enabled'
      }
    }
    this.s3Client.putBucketVersioning(params, function (err, data) {
      if (err) {
        cb(null, err)
      } else {
        cb(data, null)
      }
    })
  }

  getBucketVersioning (cb: Function) {
    const params = {
      Bucket: this.bucket
    }
    this.s3Client.getBucketVersioning(params, function (err, data) {
      if (err) {
        cb(null, err)
      } else {
        cb(data, null)
      }
    })
  }

  putObjectTagging (key: string, cb: Function) {
    const params = {
      Bucket: this.bucket,
      Key: key,
      Tagging: {
        TagSet: [
          {
            Key: 'key1',
            Value: 'value1'
          }
        ]
      }
    }
    this.s3Client.putObjectTagging(params, function (err, data) {
      if (err) {
        cb(null, err)
      } else {
        cb(data, null)
      }
    })
  }

  getObjectTagging (key: string, cb: Function) {
    const params = {
      Bucket: this.bucket,
      Key: key
    }
    this.s3Client.getObjectTagging(params, function (err, data) {
      if (err) {
        cb(null, err)
      } else {
        cb(data.TagSet[0], null)
      }
    })
  }

  deleteObjectTagging (key: string, cb: Function) {
    const params = {
      Bucket: this.bucket,
      Key: key
    }
    this.s3Client.deleteObjectTagging(params, function (err, data) {
      if (err) {
        cb(null, err)
      } else {
        cb(data, null)
      }
    })
  }
}
