// Notice: Icon从这里找：https://heroicons.com/
import {
  HomeModernIcon,
} from '@heroicons/vue/24/outline'
import { defineAppConfig } from '#imports'

export type Nav = {
  name: string
  path: string
  meta: {
    title?: string
    icon?: string | typeof HomeModernIcon
    // 配置识别菜单激活的匹配正则
    match?: string[] | undefined
  },
  children?: Nav[]
}

export default defineAppConfig({
  myLayer: {
    name: 'standard'
  },
  mainMenu: {
    'standard': {
      expanded: [],
      navigation: [
        { path: '/standard/schema', name: 'standard-schema', meta: { title: '存储模式', icon: HomeModernIcon } },
        { path: '/standard/dictionary', name: 'standard-dictionary', meta: { title: '语义字典', icon: HomeModernIcon } },
        { path: '/standard/schema', delivery: 'standard-delivery', meta: { title: '交付过程', icon: HomeModernIcon } },
      ]
    }
  }
})

declare module '@nuxt/schema' {
  interface AppConfigInput {
    myLayer?: {
      /** module name */
      name?: string
    },
    siteConfig?: {
      name: string,
      enName: string,
      defaultPortal: string
    },
    mainMenu?: {
      // key为模块标识名
      [key: string]: {
        // 默认展开的菜单项，其值为
        expanded?: string[],
        // 导航定义
        navigation?: Nav[]
      }
    },
  }
}
