import { useFetch } from '#imports'
import { IResource, IRequest } from "./types";
import { MessagePlugin } from 'tdesign-vue-next'


/**
 * 基础的资源类
 * 实现CRUD基础操作
 */
export default class Resource<T> implements IResource<T> {
  create(req: Partial<IRequest<T>>): Promise<T> {
    req.method = 'POST';
    return this.request(req);
  }

  /**
   * 查询获取多个资源
   * @param req {Partial<IRequest<T>>} 请求参数
   * @returns {  read(req: Partial<IRequest<T>>): Promise<T[]> }
   */
  read(req: Partial<IRequest<T>>): Promise<T[]> {
    req.method = 'GET';
    return this.request(req);
  }

  /**
   * 查询获取单一资源
   * @param req {Partial<IRequest<T>>} 请求参数
   * @returns {  read(req: Partial<IRequest<T>>): Promise<T> }
   */
  show(req: Partial<IRequest<T>>): Promise<T> {
    req.method = 'GET';
    return this.request(req);
  }

  /**
  * 更新资源
  * @param req {Partial<IRequest<T>>} 请求参数
  * @returns {  read(req: Partial<IRequest<T>>): Promise<T> }
  */
  update(req: Partial<IRequest<T>>): Promise<T> {
    req.method = 'PATCH';
    return this.request(req);
  }

  /**
  * 删除资源
  * @param req {Partial<IRequest<T>>} 请求参数
  * @returns {  read(req: Partial<IRequest<T>>): Promise<T> }
  */
  delete(req: Partial<IRequest<T>>): Promise<T> {
    req.method = 'DELETE';
    return this.request(req);
  }

  /**
  * 导出资源
  * @param req {Partial<IRequest<T>>} 请求参数
  * @returns {  read(req: Partial<IRequest<T>>): Promise<T> }
  */
  export(req: Partial<IRequest<T>>): Promise<T> {
    req.method = 'GET';
    return this.request(req);
  }

  /**
  * 上传资源
  * @param req {Partial<IRequest<T>>} 请求参数
  * @returns {  read(req: Partial<IRequest<T>>): Promise<T> }
  */
  upload(req: Partial<IRequest<T>>): Promise<T> {
    req.method = 'POST';
    //@todo set request header for files upload
    return this.request(req);
  }

  /**
   * 基础资源请求
   * @param req {Partial<IRequest<T>>} 请求参数
   * @returns {  read(req: Partial<IRequest<T>>): Promise<T> }
   */
  request(req: Partial<IRequest<T>>): Promise<unknown> {
    return new Promise<unknown>(async (resolve, reject) => {
      try {
        let body;
        // 如果是get请求，而且存在body参数，那么将body转为url查询参数
        if (req.method == 'GET' && req.body) {
          const query = makeSearchQuery(req.body);
          req.url += `?${query}`;
        } else {
          body = JSON.stringify(req.body);
        }
        const { data, error } = await useFetch(req.url as string, { method: req.method || 'GET', headers: Object.assign({}, { "Content-Type": "application/json" }, req.headers), body });

         if (error?.value) {
          if (error?.value?.data) {
            MessagePlugin.error(error?.value?.data?.message, 3000)
            if (error?.value?.data?.code === 60001 || error?.value?.data?.code === 60003) {
              navigateTo('/')
            }
            return
          } else {
            reject(new Error("请求失败"))
          }
        }

        if (!data) {
          reject(new Error("请求失败"));
        }

        if (data._rawValue.code === 401 || data._rawValue.code === 403) {
          // TODO: 这里先这样主动删除，后续得动态化处理
          localStorage.removeItem('userToken')
          localStorage.removeItem('X-Openerp-Session-Id')
          location.href = '/'
          return
        } else if (data._rawValue.code != 200 && data._rawValue.code != 0) {
          reject(new Error(data._rawValue.message));
        }

        resolve(data._rawValue.data);

      } catch (e: any) {
        reject(e)
      }
    });
  }
}

// 创建url查询参数
const makeSearchQuery = (query: { [key: string]: any }): string => {
  const urlSearchParams = new URLSearchParams;
  for (const key in query) {
    if (typeof query[key] === 'string') {
      urlSearchParams.append(key, query[key]);
    } else {
      urlSearchParams.append(key, JSON.stringify(query[key]));
    }
  }

  return urlSearchParams.toString();
}
