// Notice: Icon从这里找：https://heroicons.com/
import {
  HomeModernIcon,
  ShieldCheckIcon,
  SparklesIcon,
  UserPlusIcon,
} from '@heroicons/vue/24/outline'
import { defineAppConfig } from '#imports'

export type Nav = {
  name: string
  path: string
  meta: {
    title?: string
    icon?: string | typeof HomeModernIcon
    // 配置识别菜单激活的匹配正则
    match?: string[] | undefined
  },
  children?: Nav[]
}

export default defineAppConfig({
  myLayer: {
    name: 'organization'
  },
  mainMenu: {
    'organization': {
      expanded: ['/organization/overview', '/organization/structure', '/organization/access', '/organization/culture'],
      navigation: [
        { path: '/organization/overview', name: 'organization-overview', meta: { title: '组织信息', icon: HomeModernIcon } },
        {
          path: '/organization/structure', name: 'organization-structure', meta: { title: '组织架构', icon: UserPlusIcon },
          children: [
            { path: '/organization/structure/department', name: 'organization-structure-department', meta: { title: '部门管理' } },
            { path: '/organization/structure/employee', name: 'organization-structure-employee', meta: { title: '人员管理' } },
            { path: '/organization/structure/group', name: 'organization-structure-group', meta: { title: '群组管理' } },
          ],
        },
        {
          path: '/organization/access', name: 'organization-access', meta: { title: '组织访问', icon: ShieldCheckIcon },
          children: [
            { path: '/organization/access/role', name: 'organization-access-department', meta: { title: '角色管理' } },
            { path: '/organization/access/auth', name: 'organization-access-auth', meta: { title: '权限管理' } },
          ],
        },
        {
          path: '/organization/culture', name: 'organization-culture', meta: { title: '组织文化', icon: SparklesIcon },
          children: [
            { path: '/organization/culture/appearance', name: 'organization-culture-appearance', meta: { title: '外观设置' } },
            { path: '/organization/culture/field', name: 'organization-culture-field', meta: { title: '扩展字段' } },
            { path: '/organization/culture/dict', name: 'organization-culture-dict', meta: { title: '数据字典' } },
          ],
        },
      ]
    }
  }
})

declare module '@nuxt/schema' {
  interface AppConfigInput {
    myLayer?: {
      /** module name */
      name?: string
    },
    siteConfig?: {
      name: string,
      enName: string,
      defaultPortal: string
    },
    mainMenu?: {
      // key为模块标识名
      [key: string]: {
        // 默认展开的菜单项，其值为
        expanded?: string[],
        // 导航定义
        navigation?: Nav[]
      }
    },
  }
}
