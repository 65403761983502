import type { RouterOptions } from '@nuxt/schema'
import { useSubdomain } from '../composables/useSubdomain'

// https://router.vuejs.org/api/interfaces/routeroptions.html
export default <RouterOptions>{
  routes: (_routes) => {
    const { ssrContext } = useNuxtApp()
    const subdomain = useSubdomain()

    if (ssrContext?.event.context.subdomain) {
      subdomain.value = ssrContext?.event.context.subdomain
    }

    // if (subdomain.value) {
    //   //   const userRoute = _routes.filter(i => i.path.includes('/organization/:oid'))
    //   const routeMapped = _routes.map(i => {
    //     return {
    //       ...i,
    //       path: i.path.startsWith('/organization/:oid') ? i.path.replace('/organization/:oid', '/') : i.path
    //     }
    //   })

    //   console.log('map', routeMapped.filter(route => route.path.includes('culture')))

    //   return routeMapped
    // }
  },
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) { return savedPosition }
    if (to.hash) {
      const el = document.querySelector(to.hash) as HTMLElement
      return { left: 0, top: (el?.offsetTop ?? 0) - 30, behavior: 'smooth' }
    }

    if (to.fullPath === from.fullPath) { return }
    return { left: 0, top: 0, behavior: 'smooth' }
  }
}
