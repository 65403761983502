// Notice: Icon从这里找：https://heroicons.com/
import {
  HomeIcon,
} from '@heroicons/vue/24/outline'
import { defineAppConfig } from '#imports'

// 记录下一代Nav结构
export type Nav = {
  name: string
  path: string
  meta: {
    title: string
    icon?: string | typeof HomeIcon
    // 配置识别菜单激活的匹配正则
    match?: string[] | undefined
  },
  children?: Nav[]
}

export default defineAppConfig({
  myLayer: {
    name: 'portal'
  }
})

declare module '@nuxt/schema' {
  interface AppConfigInput {
    myLayer?: {
      /** Module name */
      name?: string
    },
    mainMenu?: {
      // Key为当前模块标识名
      [key: string]: {
        // 默认展开的菜单项，其值为
        expanded: string[],
        // 导航定义
        navigation?: Nav[]
      }
    },
  }
}
