import revive_payload_client_wO4Z5HydAz from "/root/workspace/webspace_cloud/node_modules/.pnpm/nuxt@3.11.2_ioredis@5.4.1_vite@5.2.8/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Oqu9HlyVCX from "/root/workspace/webspace_cloud/node_modules/.pnpm/nuxt@3.11.2_ioredis@5.4.1_vite@5.2.8/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_kXuNgr35DT from "/root/workspace/webspace_cloud/node_modules/.pnpm/nuxt@3.11.2_ioredis@5.4.1_vite@5.2.8/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_FEzwYNYZvU from "/root/workspace/webspace_cloud/node_modules/.pnpm/nuxt@3.11.2_ioredis@5.4.1_vite@5.2.8/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_ATA8QEXtOF from "/root/workspace/webspace_cloud/node_modules/.pnpm/nuxt@3.11.2_ioredis@5.4.1_vite@5.2.8/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/root/workspace/webspace_cloud/apps/platform/.nuxt/components.plugin.mjs";
import prefetch_client_LZGjVDXmG5 from "/root/workspace/webspace_cloud/node_modules/.pnpm/nuxt@3.11.2_ioredis@5.4.1_vite@5.2.8/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import strapi_plugin_1U1XhtIwHp from "/root/workspace/webspace_cloud/node_modules/.pnpm/@nuxtjs+strapi@1.9.3/node_modules/@nuxtjs/strapi/dist/runtime/strapi.plugin.mjs";
import plugin_UDUCLXtr0K from "/root/workspace/webspace_cloud/node_modules/.pnpm/@sidebase+nuxt-auth@0.9.0_next-auth@4.21.1/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import openapi_QLZSk86cjl from "/root/workspace/webspace_cloud/modules/openapi/src/runtime/plugins/openapi.ts";
import hydrateClient_1XAiyPa66r from "/root/workspace/webspace_cloud/modules/openapi/src/runtime/plugins/hydrateClient.ts";
import storage_zDY7z1aeb7 from "/root/workspace/webspace_cloud/modules/storage/src/runtime/plugins/storage.ts";
import oss_client_TYHnREZ8GK from "/root/workspace/webspace_cloud/modules/storage/src/runtime/plugins/oss.client.ts";
import _1_trpc_yjAgYlNPIC from "/root/workspace/webspace_cloud/modules/class-components/src/runtime/plugins/1.trpc.ts";
import chunk_reload_client_SodoiUApuk from "/root/workspace/webspace_cloud/node_modules/.pnpm/nuxt@3.11.2_ioredis@5.4.1_vite@5.2.8/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _1_trpc_dRwrX6c7OC from "/root/workspace/webspace_cloud/framework/plugins/1.trpc.ts";
export default [
  revive_payload_client_wO4Z5HydAz,
  unhead_Oqu9HlyVCX,
  router_kXuNgr35DT,
  payload_client_FEzwYNYZvU,
  check_outdated_build_client_ATA8QEXtOF,
  components_plugin_KR1HBZs4kY,
  prefetch_client_LZGjVDXmG5,
  strapi_plugin_1U1XhtIwHp,
  plugin_UDUCLXtr0K,
  openapi_QLZSk86cjl,
  hydrateClient_1XAiyPa66r,
  storage_zDY7z1aeb7,
  oss_client_TYHnREZ8GK,
  _1_trpc_yjAgYlNPIC,
  chunk_reload_client_SodoiUApuk,
  _1_trpc_dRwrX6c7OC
]