// Notice: Icon从这里找：https://heroicons.com/
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  CubeIcon,
} from '@heroicons/vue/24/outline'
import { defineAppConfig } from '#imports'

export type Nav = {
  name: string
  path: string
  meta: {
    title: string
    icon?: string | typeof HomeIcon
    // 配置识别菜单激活的匹配正则
    match?: string[] | undefined
  },
  children?: Nav[]
}

export default defineAppConfig({
  myLayer: {
    name: 'user-space'
  },
  // 新的菜单体系结构, 对应sidebar-next
  mainMenu: {
    expanded: ['/admin'],
    navigation: [
      { path: '/admin', name: 'admin', meta: { title: 'TODO', icon: HomeIcon } ,
        children: [
          { path: '/admin/todos', name: 'admin-todos', meta: { title: 'TODO List' } },
        ],
      },
    ]
  }
})

declare module '@nuxt/schema' {
  interface AppConfigInput {
    myLayer?: {
      /** Project name */
      name?: string
    },
    siteConfig?: {
      name: string,
      enName: string,
      defaultPortal: string
    },
    mainMenu?: {
      // 默认展开的菜单项，其值为
      expanded: string[],
      // 导航定义
      navigation?: Nav[]
    },
  }
}
