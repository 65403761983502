// Notice: Icon从这里找：https://heroicons.com/
import {
  HomeModernIcon,
  ShieldCheckIcon,
  SparklesIcon,
  CubeTransparentIcon,
} from '@heroicons/vue/24/outline'
import { defineAppConfig } from '#imports'

export type Nav = {
  name: string
  path: string
  meta: {
    title?: string
    icon?: string | typeof HomeModernIcon
    // 配置识别菜单激活的匹配正则
    match?: string[] | undefined
  },
  children?: Nav[]
}

export default defineAppConfig({
  myLayer: {
    name: 'project-org'
  },
  mainMenu: {
    'project-org': {
      expanded: ['/project-org/access', '/project-org/integration', '/project-org/culture'],
      navigation: [
        {
          path: '/project-org/access', name: 'project-org-access', meta: { title: '访问管理', icon: ShieldCheckIcon },
          children: [
            { path: '/project-org/access/member', name: 'project-org-access-member', meta: { title: '用户管理' } },
            { path: '/project-org/access/role', name: 'project-org-access-role', meta: { title: '角色管理' } },
            { path: '/project-org/access/policy', name: 'project-org-access-policy', meta: { title: '策略管理' } },
          ],
        },
        {
          path: '/project-org/integration', name: 'project-org-integration', meta: { title: '集成管理', icon: CubeTransparentIcon },
          children: [
            { path: '/project-org/integration/connect', name: 'project-org-integration-connect', meta: { title: '平台互联' } },
            { path: '/project-org/integration/iot', name: 'project-org-integration-iot', meta: { title: '设备互联' } },
          ],
        },
        {
          path: '/project-org/culture', name: 'project-org-culture', meta: { title: '项目文化', icon: SparklesIcon },
          children: [
            { path: '/project-org/culture/appearance', name: 'project-org-culture-appearance', meta: { title: '界面设置' } },
            { path: '/project-org/culture/menu', name: 'project-org-culture-menu', meta: { title: '菜单设置' } },
          ],
        },
      ]
    }
  }
})

declare module '@nuxt/schema' {
  interface AppConfigInput {
    myLayer?: {
      /** module name */
      name?: string
    },
    siteConfig?: {
      name: string,
      enName: string,
      defaultPortal: string
    },
    mainMenu?: {
      // key为模块标识名
      [key: string]: {
        // 默认展开的菜单项，其值为
        expanded?: string[],
        // 导航定义
        navigation?: Nav[]
      }
    },
  }
}
